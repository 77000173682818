import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import Inputs from "../../../forms/Inputs";
import { InputHandler } from "../../../../services/EventHandlers";
import { useAxios } from "../../../../contexts/AxiosContext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useToast } from "../../../../contexts/ToastContext";

interface Props {
  visible: boolean;
  onHide?: any;
  type: string;
  refreshTables: any;
  setLoadingState: any;
}

const RoleModal: React.FC<Props> = ({
  visible = false,
  onHide = () => {},
  type,
  refreshTables,
  setLoadingState
}) => {
  const axiosService = useAxios();
  const {showToast} = useToast();
  const role = {
    name: "",
  };

  const [data, setData] = useState(role);
  const [pages, setPages] = useState([]);
  const [accessData, setAccessData] = useState<{ [key: string]: boolean }>({});

  const handleOnHide = () => {
    setData(role);
    setAccessData({});
    onHide();
  };

  const getPages = () => {
    axiosService.api
      .get("/api/roles/available-page")
      .then((response: any) => {
        setPages(response.data);
        const initialAccessData: { [key: string]: boolean } = {};
        response.data.forEach((page: any) => {
          initialAccessData[page.id] = false; // Initialize access to false for all pages
        });
        setAccessData(initialAccessData);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPages();
  }, []);

  const handleAccessChange = (pageId: string, value: boolean) => {
    setAccessData((prev) => ({ ...prev, [pageId]: value }));
  };

  const handleSubmit = async () => {
    setLoadingState(true);
    const postData = {
      role_name: data.name, // Assuming 'name' is used as role ID, adjust if needed
      permissions: Object.entries(accessData).map(([pageId, hasAccess]) => ({
        page_id: pageId,
        hasAccess,
      })),
    };

    axiosService.api.post('/api/roles/new', postData).then((response: any) => {
      showToast({
        severity: response.data.status ? 'success' : 'error',
        summary: response.data.status ? 'Success!' : 'Failed',
        detail: response.data.message
      });
      refreshTables();
      handleOnHide();
      setLoadingState(false);

    }).catch((error: any) => {
      console.log(error)
      showToast({
        severity: 'error',
        summary: 'Failed',
        detail: error.response.data.message
      });
      setLoadingState(false);
    });
  };

  const footer = () => {
    return (
      <Button onClick={handleSubmit} label="Save Role" className="bg-purple rounded-lg border-none"/>
    )
  }

  return (
    <>
      <Dialog
        className="lg:w-1/3 w-[95%]"
        header={type == "add" ? "Add New Role" : "Update Role"}
        draggable={false}
        resizable={false}
        visible={visible}
        onHide={handleOnHide}
        footer={footer}
      >
        <form className="mb-5" onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-1 gap-2">
            <div>
              <Inputs
                label={"Role Name"}
                placeholder="e.g Staff"
                type="text"
                value={data.name}
                onChange={(e: any) => InputHandler(setData, "name")(e)}
              />
            </div>
            <div className="grid lg:grid-cols-2 gap-2">
              {pages.map((page: any, i: any) => (
                <div
                  key={i}
                  className="border rounded-lg flex items-center p-4 justify-between"
                >
                  <p className="text-label select-none">
                    {page.parent ? page.parent.name + " | " + page.name : page.name}
                  </p>
                  <InputSwitch
                    checked={accessData[page.id] || false}
                    onChange={(e) => handleAccessChange(page.id, e.value)}
                  />
                </div>
              ))}
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default RoleModal;