import React, { useEffect, useState } from "react";
import { Logo } from "../../components/Icons/ImgPath";


const PrivacyPolicy: React.FC<any> = ({setLoadingState}) => {
  const [htmlContent, setHtmlContent] = useState<string>("");

  useEffect(() => {
    fetch("/docs/privacy_policy.html")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      
      .then((data) => {
        setLoadingState(false);
        setHtmlContent(data)
      })
      .catch((error) => console.error("Error fetching HTML content:", error));
  }, [setLoadingState]);

  return (
    <>
      <section dangerouslySetInnerHTML={{ __html: htmlContent }}/>
    </>
  );
};

export default PrivacyPolicy;
