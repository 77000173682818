import { useEffect, useState } from "react";
import Inputs from "../../components/forms/Inputs";
import { InputHandler } from "../../services/EventHandlers";
import { useAxios } from "../../contexts/AxiosContext";
import { Button } from "primereact/button";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import IconMap from "../../components/Icons/IconMaps";
import { Logo } from "../../components/Icons/ImgPath";
import { useToast } from "../../contexts/ToastContext";

const SuperAdminLogin:React.FC<any> = ({ setLoadingState }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const axiosService = useAxios();
  const [loginCredentials, setLoginCredentials] = useState<any>({
    email: '',
    password: ''
  });

  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      // attemptLogin();
    }
  };

  const attemptLogin = () => {
    setLoadingState(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    for (const key in loginCredentials) {
      if (loginCredentials.hasOwnProperty(key)) {
        if (loginCredentials[key].length === 0) {
          showToast({
            severity: 'error',
            summary: 'Missing Fields!',
            detail: `${key} is empty`
          });
          setLoadingState(false);
          return;
        }
  
        if (key === 'email' && !emailRegex.test(loginCredentials[key])) {
          showToast({
            severity: 'error',
            summary: 'Invalid Email!',
            detail: 'Email format is incorrect'
          });
          setLoadingState(false);
          return;
        }
      }
    }
    axiosService.api.post('/api/superadmin/authenticate', loginCredentials)
    .then((response:any) => {
      const bearerToken = "Bearer " + response.data.authorisation.token;
      axiosService.api.defaults.headers.common['Authorization'] = bearerToken;
      Cookies.set('bearer_token', bearerToken, { expires: 7 });
      navigate('/admin-KNdApfxpXK-main');
        
      showToast({
        severity: 'success',
        summary: 'Success!',
        detail: 'Successfully logged in!'
      });

    }).catch((error:any) => {
      setLoadingState(false);
      const errorMessage = error.response.data.message;
      if(errorMessage.includes('Attempt to read property')){
        showToast({
          severity: 'error',
          summary: 'Login Failed!',
          detail: 'Account not registered'
        });

      }else{
        showToast({
          severity: 'error',
          summary: 'Login Failed!',
          detail: errorMessage
        });
      }
      
      // console.log("error::", error.response.data.message);
    });
  };

  useEffect(() => {
    setLoadingState(false);
  }, []);

  return (
    <>
      <div className="flex flex-col h-[100vh] justify-center items-center bg-[#dadafc]">
        <img alt="Logo" src={Logo} className="mb-5" width={300}/>
        <div className="flex flex-col w-[30%] py-7 px-4 md:px-7 z-1 bg-[#fff] border-1 border-[#fff] rounded-md">
          <div className="header mb-4">
            <h3 className="text-xl font-bold mb-2 flex">{IconMap('BsIncognito','mr-2',undefined,32)}Super Admin Login</h3>
          </div>
          <div className="flex flex-col">
            <form className="mb-5">
              <Inputs label={'Email'} type={'email'} value={loginCredentials.email} onChange={InputHandler(setLoginCredentials, 'email')} required invalid  withValidation  />
              <Inputs label={'Password'} type={'password'} value={loginCredentials.password} onChange={InputHandler(setLoginCredentials, 'password')} onKeyDown={handleKeyPress} className="md:col-span-2" required invalid  withValidation  />
            </form>
            <Button label="Login to Account" onClick={attemptLogin} className="w-full bg-[#6366f1] border-[#6366f1] rounded-[6px] bg-purple border-purple"/>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default SuperAdminLogin;
