import { useEffect, useState } from "react";
import Inputs from "../../components/forms/Inputs";
import { InputHandler } from "../../services/EventHandlers";
import { Button } from "primereact/button";
import { useToast } from "../../contexts/ToastContext";
import { useAxios } from "../../contexts/AxiosContext";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Icons/ImgPath";

const PasswordReset:React.FC<any> = ({setLoadingState}) => {
  const navigate = useNavigate();
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [resetDetails, setResetDetails] = useState<any>({
    password: '',
    password_confirmation: '',
    token: '',
    email: ''
  });
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const resetPassword = () => {

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });
    
    if(resetDetails.password != resetDetails.password_confirmation){
      showToast({
        severity: 'error',
        summary: 'Password Mismatch!',
        detail: 'The password does not match with the confirmation.',
      });
      return;
    }

    setFieldsDisabled(true);

    axiosService.api.post('/api/password/reset', resetDetails).then((resetData:any) => {
      console.log('resetData', resetData);
      showToast({
        severity: 'success',
        summary: 'Redirecting you to Home!',
        detail: resetData.data.message,
      });

      const bearerToken = "Bearer " + resetData.data?.credentials?.authorisation.token;
      axiosService.api.defaults.headers.common['Authorization'] = bearerToken;
      Cookies.set('bearer_token', bearerToken, { expires: 7 });

      setTimeout(() => {
        navigate('/main');
      }, 3000);
    }).catch((e:any) => {
      console.log(e);
      showToast({
        severity: 'error',
        summary: 'Invalid Token',
        detail: e.response?.data.message || e.response?.data.error,
      });

      setTimeout(() => {
        navigate('/forgot-password');
      }, 3000);
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const email = params.get("email");

    setResetDetails((prevDetails:any) => ({
      ...prevDetails,
      token: token || "",
      email: email || "",
    }));

    setLoadingState(false);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-5 justify-center items-center min-h-screen w-screen text-sm p-4 bg-[#F0EFFF]">
        <img alt="Logo" src={Logo} className="mb-5" width={300}/>
        <div className="w-full xl:w-1/3 lg:w-1/2 rounded-[56px] min-h-[400px] p-1" style={{background: 'linear-gradient(180deg, rgb(42, 34, 152) 10%, rgba(33, 150, 243, 0) 30%)'}}>
          <div className="rounded-[53px] bg-white border-5 border-transparent w-full h-full lg:p-[5rem] py-[5rem] px-[2rem]">
            <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
              <p className="text-3xl text-center font-bold">Reset Your Password</p>
            </div>
            <div className="grid lg:grid-cols-1 gap-4">
              <div className="flex flex-col gap-1">
                <Inputs disabled={fieldsDisabled} label={'Password'} type={'password'} value={resetDetails.password} onChange={InputHandler(setResetDetails, 'password')} />
              </div>
              <div className="flex flex-col gap-1">
                <Inputs disabled={fieldsDisabled} label={'Confirm Password'} type={'password'} value={resetDetails.password_confirmation} onChange={InputHandler(setResetDetails, 'password_confirmation')} />
              </div>
              <div>
                <Button disabled={fieldsDisabled} label="Reset Password" onClick={resetPassword} className="w-full bg-purple rounded-lg border-purple"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PasswordReset;