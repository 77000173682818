import React, { useState } from 'react';
import Inputs from '../../components/forms/Inputs';
import { Button } from 'primereact/button';
import { InputHandler } from '../../services/EventHandlers';
import { useToast } from '../../contexts/ToastContext';

const ReadMail: React.FC<any> = ({ axiosService, setLoadingState, setView, data, currentMailbox }) => {
  const { showToast } = useToast();
  const convertNewlinesToHtml = (text:any) => {
    return text.replace(/(\r\n|\n|\r)/g, '<br>');
  };

  const mail = {
    to: [data.type == "sent" ? data.to : data.from],
    subject: `Re: ${data.subject}`,
    message: '',
    mailbox_id: currentMailbox
  }
  const [mailData, setMailData] = useState<any>(mail);

  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  const sendEmail = () => {
    if(!mailData.mailbox_id){
      showToast({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'Please select an active mailbox.',
      });  
      return ;
    }
    setSendButtonLoading(true);
    axiosService.api.post('/api/mail/send', mailData).then((response:any) => {
      console.log(response.data);
      setSendButtonLoading(false);

      if(response.data.status){
        showToast({
          severity: 'success',
          summary: 'Success!',
          detail: response.data.message,
        });
      }
      setMailData(mail); // set to default
    }).catch((error:any) => {
      console.log('Error:', error);
      setSendButtonLoading(false);
    });
  };

  return(
  <>
    <div className=' p-4 gap-3'>
      <div className='flex flex-row items-center justify-between'>
        <div className='flex flex-row items-center'>

          <button onClick={() => setView('Inbox')}  className='surface-border flex items-center justify-center rounded-md text-900 w-[2.5rem] h-[2.5rem] mr-3 py-[0.5rem] border border-[#dee2e6] bg-transparent text-[#607d8b]'>
            <span className=' pi pi-chevron-left'></span>
          </button>
          <div className='flex flex-col'>
            <span className='block text-[#212121] font-bold text-md'>{data.from}</span>
            <span className='block text-[#212121] text-sm'>To: <span>{data.to}</span></span>
          </div>
        </div>
        <div>
          {/* <span> April 28 2024</span> */}
        </div>
      </div>
      <div className='pt-4 mt-4 border-t border-[#dee2e6]'>
        <h4 className='mb-2 font-bold'>{data.subject}</h4>
        <div dangerouslySetInnerHTML={{ __html: convertNewlinesToHtml(data.message) }}></div>
      </div>
      <form className='surface-section grid mt-4 grid-nogutter formgrid md:surface-border md:border-1 border-round'>
        <Inputs label={undefined} type={'editor'} value={mailData.message} onChange={InputHandler(setMailData, 'message')} style={{ height: '320px' }}/>
      </form>
      <div className="col-12 flex column-gap-3 justify-end">
        <Button loading={sendButtonLoading} iconPos='right' onClick={sendEmail} label="Send Message" icon="pi pi-send" />
      </div>
    </div>
  </>
  )
}

export default ReadMail;