import { Button } from "primereact/button"
import { Chips } from "primereact/chips"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { useEffect, useState } from "react"
import { useAxios } from "../../../contexts/AxiosContext"
import { InputHandler } from "../../../services/EventHandlers"
import { Tooltip } from "primereact/tooltip"
import { ProgressSpinner } from 'primereact/progressspinner';
import IconMap from "../../Icons/IconMaps"
import Inputs from "../../forms/Inputs"
import { Accordion, AccordionTab } from "primereact/accordion"
import { Dropdown } from "primereact/dropdown"
import { Divider } from "primereact/divider"
import { RadioButton } from "primereact/radiobutton"
import { FloatLabel } from "primereact/floatlabel"
import { AgentProps } from "../../../interface/datatypes"
// interface Country {
//   name: string;
//   value: string;
//   flag: string;
// }
// interface Props {
//   visible: boolean
//   onHide?: any
//   data?: any
//   onSubmit?: any
//   onCallback?: any
//   setData?: AgentProps
//   readOnly?: any
// }

const OnboardingSummaryModal: React.FC<any> = ({
  isEdit=false, visible = false, onHide, data, values, values1, miscLinks,setMiscLinks, setValues1, values2, onSubmit, 
  onCallback, setData, readOnly, isGenerated, isLoading, isLoadingProfile, isLoadingPitch,
  isSubmitting, header="Onboarding Summary", buttonText="Submit Onboarding", generativeAI,setLoadingState
}) => {
  // const { showToast } = useToast();
  const axiosService = useAxios();
  // const [promptExample, setPromptExample] = useState("");
  const [aiDesc, setAiDesc] = useState({
    type: '',
    tone: ''
  });
  const [selectedCountries, setSelectedCountries] = useState<any[] | null>(null);
  const [countryOptions, setCountryOptions] = useState([data.target_countries]);
  const [selectedPersonalityId, setSelectedPersonalityId] = useState(null);
  const [aiPersonalities, setAIPersonalities] = useState<any>([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [languages] = useState([
    {name: "English", value: "English"},
    {name: "Spanish", value: "Spanish"},
    {name: "French", value: "French"},
    {name: "German", value: "German"},
    {name: "Chinese (Simplified)", value: "Chinese (Simplified)"},
    {name: "Chinese (Traditional)", value: "Chinese (Traditional)"},
    {name: "Japanese", value: "Japanese"},
    {name: "Korean", value: "Korean"},
    {name: "Russian", value: "Russian"},
    {name: "Portuguese", value: "Portuguese"},
    {name: "Italian", value: "Italian"},
    {name: "Dutch", value: "Dutch"},
    {name: "Arabic", value: "Arabic"},
    {name: "Hindi", value: "Hindi"},
    {name: "Bengali", value: "Bengali"},
    {name: "Punjabi", value: "Punjabi"},
    {name: "Turkish", value: "Turkish"},
    {name: "Vietnamese", value: "Vietnamese"},
    {name: "Thai", value: "Thai"},
  ]);

  const SearchScopeOptions = [
    { label: "Broad", value: "Broad" },
    { label: "Narrow", value: "Narrow" },  
  ];

  const handleSearchScopeChange = (event: any) => {
    setData((prevValues:AgentProps) => (
      {
        ...prevValues,
        search_scope: event.value
      }
    ));  
  };  

  const initAgents = async () => {
    try {
      await axiosService.api.get('/api/agents/all')
      .then((response:any)=>{
        const agents = response.data; 
        const formattedAgents = agents.map((agent:any) => ({
          label: agent.name,
          value: agent.id,
        }));
        setAgentOptions(formattedAgents);

      })
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };
  const initCountries = () => {
    axiosService.api.get(`/api/countries/get-countries`)
    .then((response: any) => {
      const countriesData = response.data;
      const countriesFormatted = countriesData.map((country: any) => ({
        name: country.name,
        value: country.id,
        flag: country.flag
      }));
      setCountryOptions(countriesFormatted);
    })
    .catch((error: any) => {
      console.error("Error fetching countries:", error);
    });
  };

  const getAIPersonalities = () => {
    axiosService.api.get('/api/ai-personality/get-all')
      .then((response:any) => {
        setAIPersonalities(response.data);
        
        if(response.data.length != 0){
          setData((prevValues:AgentProps) => ({
            ...prevValues,
            ai_personality_id: 3//response.data[0].id
          }));

          setAiDesc({
            type: response.data[0].type,
            tone: response.data[0].tone
          });
        }
      });
  };

  const countriesTemplate = (option: any) => {
    return (
      <div className="flex items-center">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
          className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
          style={{ width: '18px' }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const countryTemplate = (option: any, props: any) => {
    if (option && option.name) {
      return (
        <div className="flex items-center">
          <img
            alt={option.name}
            src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
            className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
            style={{ width: '18px' }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const footerContent = () => {
    return <div >
      <Button 
        className="bg-purple rounded-lg border-purple ring-0" 
        onClick={onSubmit} 
        autoFocus 
        // disabled={!isEdit ? (generativeAI && !isGenerated) || isSubmitting : isSubmitting}
        disabled={!isEdit ? ((generativeAI && !isGenerated) || isSubmitting) || isLoading : isSubmitting}
      >
      {buttonText}{isSubmitting? <><ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" /></>:<></>}
      </Button>
    </div>
  }

  useEffect(() => {
    initCountries();
    initAgents();
  }, [visible]);

  const checkValue = (value:any, defaultValue = '') => (value !== undefined && value !== null) ? value : defaultValue;

  useEffect(() => {
    const validLinks = miscLinks.filter((link:any) => link.description && link.link);

    setData((prev:AgentProps) => ({
      ...prev,
      miscellaneous_links: validLinks,
    }));
  }, [miscLinks, setData]);


  // useEffect(() => {
  //   console.log('isEdit ::: ', isEdit)
  // });

  useEffect(() => {
    // console.log('selectedCountries', selectedCountries);
    setData((prevValues:AgentProps) => (
      {
        ...prevValues,
        target_countries: selectedCountries
      }
    ));
  }, [selectedCountries]);

  const handlePhoneChange = (newValue: any) => {
    setData((prevValues:AgentProps) => ({
      ...prevValues,
      phone: newValue
    }));
  };

  const addMiscLink = () => {
    const newLink = { description: "", link: "" };
    setMiscLinks([...miscLinks, newLink]);
  };

  const removeMiscLink = (index: number) => {
    // Filter out the link from miscLinks state
    const newMiscLinks = (miscLinks || []).filter((_:any, i:any) => i !== index);
    setMiscLinks(newMiscLinks);
  
    // Filter out the link from miscellaneous_links in data state
    setData((prevValues: AgentProps) => ({
      ...prevValues,
      miscellaneous_links: (prevValues.miscellaneous_links || []).filter((_:any, i:any) => i !== index),
    }));
  };

  const handleInputMiscLinks = (index:any, field:any) => (e:any) => {
    const newMiscLinks = miscLinks.slice();
    newMiscLinks[index][field] = e.target.value;
    setMiscLinks(newMiscLinks);
  };

  const changePersonality = (value:any) => {

    setLoadingState(true);
    
    const personality = aiPersonalities.find((item:any) => (item.id == value));

    setData((prevValues:AgentProps) => ({
      ...prevValues,
      ai_personality_id: value
    }));

    setAiDesc({
      type: personality.type,
      tone: personality.tone
    });

    setSelectedPersonalityId(value); //this is to track if ai personality was changed
  };

  const findCountry = (name: any) => {
    const country = countryOptions.find((country: any) => country.name == name);
    return country ? [country.value] : [];
  };

  const [credits, setCredits] = useState();
  const [agentPersonality, setAgentPersonality] = useState();

  useEffect(() => {
    setCredits(data.credits);
  }, [data])

  const handlDefaultAgent = async (agentValue:any)=>{
    setLoadingState(true);

    const [userDataResponse, agentResponse] = await Promise.all([
      axiosService.api.get('/api/user/current'),
      axiosService.api.get('/api/agents/get/' + agentValue.value),
    ]);

    const userData = userDataResponse.data || {};
    const agentData = agentResponse.data || {};
    
    setData((prevState:AgentProps) => ({
      ...prevState,
      website: checkValue(agentData.website) || '',
      company_name: checkValue(agentData.company_name) || '',
      agent_name: checkValue(agentData.name) || '',
      description: checkValue(agentData.description) || '',
      target_description: checkValue(agentData.target_description) || '',
      target_keywords: checkValue(agentData.target_keywords) || [],
      target_language: checkValue(agentData.target_language) || '',
      target_countries: checkValue(findCountry(agentData.target_countries[0])) || [],
      offers: checkValue(agentData.offers) || '',
      email: checkValue(agentData.email) || checkValue(userData.email) || '',
      phone: checkValue(agentData.phone) || checkValue(userData.phone) || '',
      full_name: checkValue(agentData.name) || checkValue(userData.name) || '',
      calendly_link: checkValue(agentData.calendly_link) || '',
      role: checkValue(agentData.role) || '',
      linkedin_url: checkValue(agentData.linkedin_url) || '',
      credits: checkValue(credits || agentData.credits) || 0,
      ai_personality_id: checkValue(agentPersonality || agentData.ai_personality_id) || 0,
      miscellaneous_links: checkValue(agentData.miscellaneous_links) || [],
      negative_keywords: checkValue(agentData.negative_keywords) || [],
      existing_agent:agentValue.value
    }));
    setLoadingState(false);
  }
  
  useEffect(() => {
    if (selectedPersonalityId !== null) {
      setLoadingState(false);
      setAgentPersonality(selectedPersonalityId);
    }
  }, [data, aiDesc]);

  useEffect(() => {
    getAIPersonalities();
    // console.log('data ::: ', data)
  }, []);

  return (
    <>
      <Dialog className="w-[95%] lg:w-1/2" footer={footerContent} header={header} draggable={false} resizable={false} visible={visible} onHide={() => onHide()}>
        <div>
          <Accordion activeIndex={0}>
            <AccordionTab header="Set Campaign Credits">
              <div>
                <div>
                  <sub>Note: You could allocate credits for this agent, so you wouldn't be using up all your credits on a single agent. By default it uses your remaining available credit.</sub>
                </div>
                <div className="mt-3">
                  <Inputs value={data.credits} label={"Credit Amount"} onChange={(e:any) => InputHandler(setData, 'credits')(e)} type={"number"} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Agent Details">
              <div className="grid grid-cols-4 gap-4">
                <div className="flex flex-col lg:col-span-4">
                  <label className="text-label flex flex-row items-center">Agent Personality</label>
                </div>
                {
                  aiPersonalities.map((item:any, index: number) => {
                    return (
                      <div className="flex flex-col lg:col-span-1" key={index}>
                        
                        <label className="w-full text-gray-700 text-sm font-bold ml-2">{item.name != 'Ava' ? "Coming Soon" : ""}</label>
                        <div className="flex items-center">
                          <RadioButton 
                            inputId={item.id}
                            name={item.id} 
                            value={item.id} 
                            onChange={(e:any) => (changePersonality(e.target.value))} 
                            checked={data.ai_personality_id === item.id} 
                            disabled={item.name != 'Ava'}
                          />
                          <label className={`flex items-center text-sm font-bold ml-2 mb-2 ${item.name != 'Ava' ? 'text-gray-300' : 'text-gray-700'}`}>
                            {item.name}
                          </label>
                        </div>
                      </div>
                    );
                  })
                }
                <div className="flex flex-col lg:col-span-4">
                  <div className="flex flex-col-2 lg:col-span-2">
                    Type: {aiDesc.type}
                  </div>
                  <div className="flex flex-col-2 lg:col-span-2">
                    Tone: {aiDesc.tone}
                  </div>
                </div>
                {/* <div className="flex flex-col lg:col-span-4">
                  <label className={`flex items-center text-gray-700 text-sm font-bold ml-2 mb-2`}>GPT Example here:</label>
                  <p>
                    {promptExample}
                  </p>
                </div> */}
                <div className="flex flex-col lg:col-span-4">
                  <Divider />
                </div>
                {
                  !isEdit && (
                    <>
                      <div className="flex flex-col lg:col-span-2">
                        <label className="text-label flex flex-row items-center">
                          Existing Agents {IconMap('FaQuestionCircle', 'existing-agents ms-1 items-center text-[#585858]', undefined, 16)}
                          <Tooltip target=".existing-agents" content="Please select an existing agent you want to copy the business profile from" />
                        </label>
                        <Dropdown 
                          value={data.existing_agent} 
                          onChange={(e: any) => handlDefaultAgent(e)} 
                          options={agentOptions}
                          placeholder="Select Agents" 
                          className="w-full md:w-20rem" 
                        />
                      </div>
                      <div className="lg:col-span-3"></div>
                    </>
                  )
                }
                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">Agent Name {IconMap('FaQuestionCircle','business-agent ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-agent" content={"You can update the agent's name"}/></label>
                  <InputText className="w-full" value={data.agent_name} onChange={(e:any) => InputHandler(setData, 'agent_name')(e)} />
                </div>
                
                <div className="lg:col-span-3"></div>

                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">Website {IconMap('FaQuestionCircle','business-site ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-site" content={"You can update your company's website link"}/> </label>
                  <InputText value={data.website} placeholder={'example.com'} onChange={(e:any) => InputHandler(setData, 'website')(e)} />
                </div>
                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">Company Name {IconMap('FaQuestionCircle','business-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-name" content={"The company name you represent as part of the signature/footer of the outgoing emails"}/> </label>
                  <InputText value={data.company_name} placeholder={'Example Company Name'} onChange={(e:any) => InputHandler(setData, 'company_name')(e)} />
                </div>

                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">Your Full Name {IconMap('FaQuestionCircle','business-fullname ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-fullname" content={"The name you want to use for signing off the e-mail you will send to your leads"}/> </label>
                  <InputText value={data.full_name} placeholder={'Full Name'} onChange={(e:any) => InputHandler(setData, 'full_name')(e)} />
                </div>
                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">Email Address {IconMap('FaQuestionCircle','business-emailaddress ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-emailaddress" content={"The Email address as part of the signature/footer of the outgoing emails"}/> </label>
                  <InputText value={data.email} placeholder={'example@email.com'} onChange={(e:any) => InputHandler(setData, 'email')(e)} />
                </div>
                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">Meeting Calendar Link (Calendly, Hubspot, etc.) {IconMap('FaQuestionCircle','business-calendlylink ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-calendlylink" content={"A meeting link using Calendly to add as part of the e-mail generation"}/> </label>
                  <InputText value={data.calendly_link} placeholder={'https://calendly.com/username'} onChange={(e:any) => InputHandler(setData, 'calendly_link')(e)} />
                </div>
                <div className="flex flex-col lg:col-span-2">
                  {/* <label className="text-label flex flex-row items-center">Phone Number  {IconMap('FaQuestionCircle','business-phone ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-phone" content={"Your Phone Number your leads can contact you on"}/> </label> */}
                  {/* <InputText value={data.name} onChange={(e:any) => InputHandler(setData, 'name')(e)} /> */}
                  <Inputs label={<>Phone Number (Optional){IconMap('FaQuestionCircle','business-phone ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-phone" content={"Your Phone Number your leads can contact you on"}/></>} labelClass={'text-label flex flex-row items-center'} type={'phone'} value={data.phone} 
                    onChange={(e:any)=>handlePhoneChange(e)} invalid={true} withValidation={false} />
                  
                </div>
                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">Role {IconMap('FaQuestionCircle','business-role ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-role" content={"Your role in the organization reaching out to the leads"}/> </label>
                  <InputText value={data.role} placeholder={'Your Role'} onChange={(e:any) => InputHandler(setData, 'role')(e)} />
                </div>
                <div className="flex flex-col lg:col-span-2">
                  <label className="text-label flex flex-row items-center">LinkedIn URL {IconMap('FaQuestionCircle','business-linkedin ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-linkedin" content={"Your LinkedIn Profile to be added as part of the outgoing email and/or signature"}/> </label>
                  <InputText value={data.linkedin_url} placeholder={'https://www.linkedin.com/in/username'} onChange={(e:any) => InputHandler(setData, 'linkedin_url')(e)} />
                </div>

              
                <div className="flex flex-col lg:col-span-4">
                  <label className="text-label flex flex-row items-center">Business Description  {IconMap('FaQuestionCircle','business-desc ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-desc" content={"You can update what's your business for?"}/></label>
                  <InputTextarea value={data.description} onChange={(e:any) => InputHandler(setData, 'description')(e)} />
                </div>
                <div className="flex flex-col lg:col-span-4">
                  <label className="text-label flex flex-row items-center">Description of your target client, market and product  {IconMap('FaQuestionCircle','business-target ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-target" content={"You can update your target client, market and product "}/></label>
                  <InputTextarea value={data.target_description} onChange={(e:any) => InputHandler(setData, 'target_description')(e)} />
                </div>
                <div className={`flex flex-col lg:col-span-2`}>
                  <label className={`flex items-center text-[16px] font-[500]`}>Target Country  {IconMap('FaQuestionCircle','client-countries ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-countries" content={"You can update the country you are targeting"} /></label>
                  <Dropdown value={data.target_countries && data.target_countries[0]}
                    options={countryOptions} 
                    onChange={(e: any) => 
                      setSelectedCountries([e.value])
                    } 
                    placeholder="Select Countries"
                    optionLabel="name"
                    itemTemplate={countriesTemplate}
                    valueTemplate={countryTemplate}
                    filter
                  />

                  {/* <MultiSelect autoOptionFocus={false} focusOnHover={false} selectOnFocus={false} value={data.target_countries} options={countryOptions} onChange={(e: MultiSelectChangeEvent) => setSelectedCountries(e.value)} optionLabel="name" 
                    filter placeholder="Select Countries" itemTemplate={countryTemplate} panelFooterTemplate={panelFooterTemplate} className="w-full md:w-20rem" display="chip" /> */}
                </div>
                <div className="flex flex-col lg:col-span-2">
                  <label className={`flex items-center text-[16px] font-[500]`}>Target Language   {IconMap('FaQuestionCircle','client-language ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-language" content={"You can update languages you are targeting"} /></label>
                  <Dropdown value={data.target_language} onChange={(e:any) => InputHandler(setData, 'target_language')(e)} options={languages} optionLabel="name"
                    placeholder="Select Language" className="w-full md:w-20rem" 
                  />
                </div>
                <div className="flex flex-col lg:col-span-4">
                  <label className="text-label flex flex-row items-center">Offer / CTA for potential customers  {IconMap('FaQuestionCircle','client-customers ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-customers" content={"You can update the description of products, services, or promotions you want to offer to potential customers"} /></label>
                  <InputText value={data.offers} onChange={(e:any) => InputHandler(setData, 'offers')(e)} />
                </div>
                  
                <div className="flex flex-col lg:col-span-2">
                  <label className="flex items-center text-[16px] font-[500] mb-2 flex gap-2">
                    <span className="flex items-center">
                      Choose the scope of search: broad or narrow
                      {IconMap('FaQuestionCircle','client-targets-gzhaiiqddu ms-1 items-center text-[#585858]',undefined,16)}
                      <Tooltip target=".client-targets-gzhaiiqddu" content={"Enter The Scope or The Approach of Search"}/>
                    </span>           
                  </label>
                  <Dropdown value={ data.search_scope}
                    options={SearchScopeOptions} 
                    onChange={handleSearchScopeChange}
                    placeholder="Select The Scope"
                  />        
                </div>

                <div className="flex flex-col lg:col-span-4">
                  <label className="text-label flex flex-row items-center mb-1">Target Keywords {IconMap('FaQuestionCircle','client-keywords ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-keywords" content={"You can update the keywords, press enter to add each keyword"} />
                    <span>
                      <Button onClick={() => {
                        setData((prevValues: any) => ({
                          ...prevValues,
                          target_keywords: [],
                        }));
                      }} className="bg-purple text-white rounded-full px-[10px] py-[1px] ml-1" icon="pi pi-times-circle" label="Clear" />
                    </span>
                  </label>
                  <Chips value={data.target_keywords}
                    className="w-full"
                    pt={{container: {className: 'w-full overflow-scroll gap-[5px]'}}} 
                    onChange={(e: any) => (
                      setData((prevValues: AgentProps) => ({
                        ...prevValues,
                        target_keywords: e.target.value
                      }))
                    )} 
                    allowDuplicate={false}
                  ></Chips>
                </div>
                <div className="flex flex-col lg:col-span-4">
                  <label className="text-label flex flex-row items-center mb-2">Negative Keywords {IconMap('FaQuestionCircle','client-keywords ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-keywords" content={"Keywords to exclude on lead search."} />
                    <span>
                        <Button onClick={() => {
                          setData((prevValues: AgentProps) => ({
                            ...prevValues,
                            negative_keywords: [],
                          }));
                        }} className="bg-purple text-white rounded-full px-[10px] py-[1px] ml-1" icon="pi pi-times-circle" label="Clear" />
                      </span>
                  </label>
                  <Chips value={data.negative_keywords}
                    className="w-full"
                    pt={{container: {className: 'w-full overflow-scroll gap-[5px]'}}} 
                    onChange={(e: any) => {
                      setData((prevValues: AgentProps) => ({
                        ...prevValues,
                        negative_keywords: e.target.value
                      }));
                    }} 
                    allowDuplicate={false}
                  ></Chips>
                </div>
                <div className="flex flex-col lg:col-span-4">
                  <label className="text-label flex flex-row items-center">
                    Miscellaneous Links (Optional)
                    {IconMap('FaQuestionCircle', 'client-keywords ms-1 items-center text-[#585858]', undefined, 16)}
                    <Tooltip target=".client-keywords" content={"Add and manage additional links here."} />
                  </label>
                  <div className="flex flex-col lg:col-span-4 pr-2">
                    {(miscLinks||[]).map((link:any, index:any) => (
                      <div key={index} className="w-full flex flex-row justify-center border-gray border rounded-lg pr-4 mt-3 relative">
                        <div className={`absolute flex flex-col h-full top-0 right-[-18px] justify-center items-center `}>
                          {(miscLinks||[]).length > 1 && (
                            <Button className="bg-[#fff] flex z-1" icon="pi pi-minus" rounded outlined severity="danger" aria-label="minus" onClick={() => removeMiscLink(index)}/>
                          )}
                          {index == (miscLinks||[]).length - 1 && (
                            <Button className={`bg-[#fff] z-1 ${(index == (miscLinks||[]).length - 1) && (miscLinks||[]).length > 1 ? 'mt-1': ''}`} icon="pi pi-plus" rounded outlined severity="secondary" aria-label="plus" onClick={addMiscLink}/>
                          )}
                        </div>
                        <div className="flex grid grid-cols-1 md:grid-cols-2 gap-3 my-2 w-[98%]">
                          <div className="mt-2 w-full">
                            <FloatLabel>
                              <InputText id={`link-desc-${index}`} value={link.description} onChange={handleInputMiscLinks(index, 'description')} className="w-full text-[16px]" placeholder="Link Description" />
                              <label htmlFor={`link-desc-${index}`}>Link Description</label>
                            </FloatLabel>
                          </div>
                          <Inputs label={undefined} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={link.link} className="w-full text-[16px]" placeholder={'example.com'} onChange={handleInputMiscLinks(index, 'link')} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {generativeAI ? 
                  <>
                    <div className="flex flex-col lg:col-span-4">
                      <h4 className="">Please see below your A.I. Suggested sales profile</h4>
                      <div className={` h-[150px] bg-white border-gray border rounded-lg ${data ? 'flex items-center justify-center' : 'flex flex-col items-center justify-center'}`}>
                        {isLoadingProfile ? (
                          <div className="text-[16px] text-[#3490dc] flex justify-center">
                            Generating information <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" />
                          </div>
                        ) : (
                          isGenerated ? (
                            <InputTextarea  style={{ resize: 'none' }} className="w-full h-full rounded-lg border-none" readOnly value={data.suggested_sales_profile} />
                          ) : ( (
                            isEdit? 
                              <InputTextarea  style={{ resize: 'none' }} className="w-full h-full rounded-lg border-none" value={data.suggested_sales_profile} onChange={(e:any) => InputHandler(setData, 'suggested_sales_profile')(e)} />
                            : 
                            <p className="text-[16px] text-[#8D93A1]">Pending information</p>
                          )
                          )
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col lg:col-span-4">
                      <h4 className="">Please see below our suggested pitch and first time contact</h4>
                      <div className={` h-[150px] bg-white border-gray border rounded-lg ${data ? 'flex items-center justify-center' : 'flex flex-col items-center justify-center'}`}>
                        {isLoadingPitch ? (
                          <div className="text-[16px] text-[#3490dc] flex justify-center">
                            Generating information <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" />
                          </div>
                        ) : (
                          isGenerated ? (
                            <InputTextarea  style={{ resize: 'none' }} className="w-full h-full rounded-lg border-none" readOnly value={data.suggested_pitch} />
                          ) : (
                            isEdit? 
                              <InputTextarea  style={{ resize: 'none' }} className="w-full h-full rounded-lg border-none" value={data.suggested_pitch} onChange={(e:any) => InputHandler(setData, 'suggested_pitch')(e)} />
                            : 
                            <p className="text-[16px] text-[#8D93A1]">Pending information</p>
                          )
                        )}
                      </div>
                    </div>
                    <div className="absolute bottom-6 text-center flex flex-col lg:col-span-2">
                      <Button onClick={generativeAI} className="bg-purple rounded-lg border-purple ring-0">
                        {isLoading ? (
                          <>
                            <ProgressSpinner className="w-[25px] h-[25px] mr-1" strokeWidth="6" /> Generating AI Content{' '}
                            <span className="ml-[5px]">{IconMap('GiMagickTrick', '')}</span>
                          </>
                        ) : isGenerated || isEdit ? (
                          <>
                            Re-generate AI Content <span className="ml-[5px]">{IconMap('GiMagickTrick', '')}</span>
                          </>
                        ) : (
                          <>
                            Generate AI Content <span className="ml-[5px]">{IconMap('GiMagickTrick', '')}</span>
                          </>
                        )}
                      </Button>
                    </div>
                  </>
                  :
                  <></>
                }
                
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </Dialog>
    </>
  );
}

export default OnboardingSummaryModal;