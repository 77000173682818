import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../contexts/AxiosContext';
import { useToast } from '../../contexts/ToastContext';

const SubscriptionCallback: React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();
  const {showToast} = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [serial] = useState<any>(queryParams.get('serial'));
  const [type] = useState<any>(queryParams.get('type'));
  const [couponSerial] = useState<any>(queryParams.get('couponSerial'));
  const [withCoupon] = useState<any>(queryParams.get('withCoupon'));

  // const targetOrigin = window.location.origin;

  useEffect(() => {
    console.log("withCoupon::", withCoupon);
    if (serial && type) {
      setLoadingState(false);
        axiosService.api.get(`/api/subscription/${type}/${serial}/${couponSerial}/${withCoupon}`)
        .then((response: any) => {
          console.log("response::", response);
          setLoadingState(false);
          if(response.data.status){
            showToast({
              severity: (type === 'cancelled') ? 'warn' : 'success',
              summary: (type === 'cancelled') ? 'Cancelled!' : 'Success!',
              detail: response.data.message
            });

            setTimeout(() => {
              navigate(response.data.link);
            }, 1000);
          }
        })
        .catch((error:any) => {
          navigate('/subscription');
        });
    }
  }, [serial, type, couponSerial]);

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default SubscriptionCallback;
