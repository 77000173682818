import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { useState } from "react"
import { useToast } from "../../../contexts/ToastContext"
import SendMailModal from "../mailbox/SendMailModal"
import IconMap from "../../Icons/IconMaps"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useAxios } from "../../../contexts/AxiosContext"
import { Tooltip } from "primereact/tooltip"
interface Props {
  visible: boolean
  onHide?: any
  data?: any
  onSubmit?: any
  callback?: any
  templateData?: any
}

const LeadMailSystemModal: React.FC<Props> = ({ 
  visible = false, onHide, data, callback = null, onSubmit, templateData
}) => { 
  const axiosService = useAxios();
  const [mailSystemData, setMailSystemData] = useState<any>('');
  const {showToast} = useToast();
  const handleHide = () => {
    onHide();
    setMailSystemData('');
    setIsGeneratingEmail(false);
    setSelectedMailTemplate('');
  }

  const [selectedMailTemplate, setSelectedMailTemplate] = useState<any>();

  const handleMailToClient = (type: string) => {
    let subject = "";
    let body = "";
    let to = data.email || '';
    // console.log(data); return;
    
    if(to === ''){
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'This contact doesn\'t have an email address.'
      });
    }

    if (type === "first") {
      subject = templateData.first_email_subject;
      body = templateData.first_email_template;
    } else if (type === "followup") {
      subject = templateData.follow_up_email_subject;
      body = templateData.follow_up_email_template;
    } else {
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'Please select a template type!'
      });
      return;
    }

    const mailtoLink = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const sendmail = setTimeout(() => {
      window.open(mailtoLink, '_blank');
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const [mailModalData, setMailModalData] = useState<any>();
  const [mailModalShow, setMailModalShow] = useState(false); 
  const handleSendMail = (type: string) => {
    let subject = "";
    let body = "";
    const to = data.email || '';
    const updatedTo = [
      to, 
      ...data.contacts.filter((contact: any) => (contact.unlocked == 1 && contact.email != data.email)).map((contact: any) => contact.email)
    ];
    
    if(to === ''){
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'This lead doesn\'t have a Main Contact email address.'
      });
    }
    
    if (type === "first") {
      subject = templateData.first_email_subject;
      body = templateData.first_email_template;
    }else if (type === "followup") {
      subject = templateData.follow_up_email_subject;
      body = templateData.follow_up_email_template;
    } else {
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'Please select a template type!'
      });
      return;
    }

    setMailModalData({
      subject: subject,
      body: body,
      to: updatedTo,
      lead_id: templateData.id,
      type: type
    });
    const sendmail = setTimeout(() => {
      setMailModalShow(true);
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const handleCallback = () => {
    setMailModalShow(false);
    if(callback) {
      callback();
    }
  }

  const [isGeneratingEmail, setIsGeneratingEmail] = useState(false);
  const newEmailTemplate = () => {
    if(mailSystemData == ""){
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'Please select a template type!'
      });
      return;
    }
    withReactContent(Swal).fire({
      title: "Confirmation",
      text: `Generating new email template for ${data.name} will cost you 1 credit.`,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
    }).then( v=>{
      if(v.isConfirmed){
        setIsGeneratingEmail(true);
        console.log(data);
        const updatedData = {
          lead_id: data.lead_id,
          contact_id: data.id,
          template_type: mailSystemData
        }
        console.log(updatedData);
        axiosService.api.post('/api/contact/generate-email', updatedData)
        .then((response:any) => {
          if(response.data.status){
            const emailTemplate = response.data.template;
            const to = data.email || '';
            const updatedTo = [
              to, 
              // ...data.contacts.filter((contact: any) => (contact.unlocked == 1 && contact.email != data.email)).map((contact: any) => contact.email)
            ];

            setMailModalData({
              subject: emailTemplate.subject,
              body: emailTemplate.template,
              to: updatedTo,
              lead_id: emailTemplate.lead_id,
              type: emailTemplate.type
            });
            const sendmail = setTimeout(() => {
              setMailModalShow(true);
              clearTimeout(sendmail);
            }, (to === '' ? 500 : 0));
            
            setIsGeneratingEmail(false);
            handleCallback();
          } else {
            showToast({
              severity: "error",
              summary: 'Failed!',
              detail: response.data.message,
            });
            handleHide();
            setIsGeneratingEmail(false);
          }
        })
        .catch((error:any) => {
          console.log('Error fetching data:', error);
          setIsGeneratingEmail(false);
        });
      } else {
        setIsGeneratingEmail(false);
      }
    });
  }

  const mailToClient = (to: any, subject: string, body: string) => {
    const mailtoLink = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    const sendmail = setTimeout(() => {
      window.open(mailtoLink, '_blank');
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const mailUseMailbox = (template: any) => {
    setMailModalData({
      subject: template.subject,
      body: template.template,
      to: [data.email],
      lead_id: template.lead_id,
      type: template.type,
    });
    const sendmail = setTimeout(() => {
      setMailModalShow(true);
      clearTimeout(sendmail);
    }, (data.email === '' ? 500 : 0));
  }

  const additionalTemplates = [
    { subject: 'Default First Template', value: 'default', contact_id: data && data.id, template_type: 'first' },
    { subject: 'Default Follow-up Template', value: 'default', contact_id: data && data.id, template_type: 'followup' }
  ];
  
  const filteredTemplates = data && data.email_templates
    .filter((e:any) => e.template_type == mailSystemData && e.contact_id == data.id)
    .concat(additionalTemplates.filter(at => at.template_type == mailSystemData));
  

  return (
    <>
      <Dialog className="lg:w-1/4 w-[95%]" header="Mail System Selection" draggable={false} resizable={false} visible={visible} onHide={handleHide}>
        <div className="flex flex-col gap-5 ">
          <div className="flex flex-col justify-between gap-1">
            <label className='flex items-center text-gray-700 text-sm font-bold mb-2'>Template Type {IconMap('FaQuestionCircle','lead-template-type ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".lead-template-type" content={"Select the type of email template you want to use (First Email or Follow-up Email)."}/></label>
            <Dropdown value={mailSystemData} onChange={(e:any) => { setMailSystemData(e.value); setMailSystemData(e.value); setSelectedMailTemplate('') }} 
              options={[
                {value: 'first', name: 'First Email Template'},
                {value: 'followup', name: 'Follow-up Email Template'},
              ]} optionLabel="name" 
              placeholder="Select Template" className="w-full md:w-14rem"
              key={mailSystemData} />
          </div>
          {
            data && data.email_templates && (<>
              <div className="flex flex-col justify-between gap-1">
                <label className='flex items-center text-gray-700 text-sm font-bold'>Generated Templates{IconMap('FaQuestionCircle','lead-generated-template ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".lead-generated-template" content={"Select a previously generated email template for quick use."}/></label>
                <Dropdown value={selectedMailTemplate} onChange={(e:any) => { setSelectedMailTemplate(e.value); setSelectedMailTemplate(e.value); }} 
                  options={filteredTemplates} optionLabel="subject" 
                  placeholder="Select Template" className="w-full md:w-14rem" emptyMessage={'No generated emails yet.'}
                />
              </div>
            </>)
          }
          <div className="flex flex-col gap-2 justify-end">
            <Button type="button" label="Use in Mailbox" className="bg-purple text-white rounded-lg" icon="pi pi-envelope"
              tooltip="Send the selected or default email template using the application's mailbox feature." 
              tooltipOptions={{ position: 'top' }} 
              onClick={() => {
                console.log(selectedMailTemplate);
                if(!selectedMailTemplate || selectedMailTemplate == "default") { handleSendMail(mailSystemData) }
                else { mailUseMailbox(selectedMailTemplate) }
              }} 
            />
            <Button type="button" label="Use in Mail Client" icon="pi pi-envelope transition-all" className="border-purple bg-transparent text-purple hover:bg-purple hover:text-white transition-all rounded-lg"
              tooltip="Send the selected or default email template using your computer's default mail client." 
              tooltipOptions={{ position: 'top' }} 
              onClick={() => {
                if(!selectedMailTemplate || selectedMailTemplate == "default"){ handleMailToClient(mailSystemData); }
                else { mailToClient(data.email, selectedMailTemplate.subject, selectedMailTemplate.template); }
              }} 
            />
            <Button type="button" 
              tooltipOptions={{ position: 'top' }} 
              label="Generate New Email Template" 
              className="bg-lightgreen border-green-900 text-green-900 rounded-lg" icon="pi pi-envelope" 
              tooltip="Generate a new email template for this contact based on the selected type." 
              onClick={newEmailTemplate}
              loading={isGeneratingEmail}
            />
          </div>
        </div>
        
      </Dialog>
      <SendMailModal visible={mailModalShow} data={mailModalData} onHide={() => setMailModalShow(false)} callback={handleCallback}/>
    </>
  );
}

export default LeadMailSystemModal