import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useAxios } from "../../../contexts/AxiosContext"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import Inputs from "../../forms/Inputs"
import { InputHandler } from "../../../services/EventHandlers"

interface Props {
  visible: boolean
  onHide?: any
  type?: string
  data?: any
  setData?: any
  onSubmit?: any
  onCallback?: any
  resendLink?: any
  isResending?: any,
  userDetails?: any,
}

const ChangePasswordModal: React.FC<Props> = ({
  visible = false, onHide, type, data, setData, onSubmit, onCallback, resendLink, isResending, userDetails
}) => {
  const axiosService = useAxios();
  const navigate = useNavigate();
  const [passwordDetails, setPasswordDetails] = useState({
    password: '',
    c_password: '',
    user_id: (userDetails != null) ? userDetails.id : 0
  });

  return (
  <>
    <Dialog className="lg:w-1/3 w-[95%] text-center" header="" closable={false} draggable={false} resizable={false} visible={visible} onHide={() => onHide()}>
      <div className="text-center flex flex-col gap-3 justify-center">
        <div className="flex justify-center">
          <img src="/images/logo-small.png" alt="Logo Small Colored" width={60}/>
        </div>
        <p className="text-3xl font-bold">Please create a new password <br /> for your account</p>
        {/* <p className="text-md px-5">Please click the verification link sent to your email to verify your email address and access our services.</p>
        <h5>Verification Link was sent to this email:</h5> */}
        <div className="mt-6">
          {/* Input fields and buttons */}
          <form className="mb-5">
            {/* Password field */}
              <Inputs label={'Password'} type={'password'} value={passwordDetails.password} onChange={(e:any) => InputHandler(setPasswordDetails, 'password')(e)} className="md:col-span-2" required invalid withValidation />
              <Inputs label={'Confirm Password'} type={'password'} value={passwordDetails.c_password} onChange={(e:any) => InputHandler(setPasswordDetails, 'c_password')(e)} className="md:col-span-2" required invalid  withValidation/>
          </form>
        </div>
        <div className="flex flex-row gap-3 justify-center">
          <div>
            <Button
              onClick={() => onSubmit(passwordDetails)}
              label="Create new password"
              iconPos="right"
              // icon={"pi " + (isResending ? "pi-spin pi-spinner" : "pi-send")}
              className="bg-purple rounded-lg border-purple"
              disabled={isResending ? true : false}
            />
          </div>
        </div>
      </div>
    </Dialog>
  </>
  );
}

export default ChangePasswordModal;