import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import IconMap from "../Icons/IconMaps";
import { Sidebar } from "primereact/sidebar";
import { NoResultFound } from "../Icons/ImgPath";
import { FilterMatchMode } from "primereact/api";
import { useAxios } from "../../contexts/AxiosContext";
import SkeletonTable from "./SkeletonTable";
import { Tooltip } from "primereact/tooltip";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useToast } from "../../contexts/ToastContext";
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CSVLink } from "react-csv";
import { TieredMenu } from "primereact/tieredmenu";

interface BulkOption {
  value: string;
  name: string;
  onSubmit: Function;
  icon?: any
}

interface ExportHeader {
  label: string;
  key: string;
}

// interface ExportMenu {
//   label: string;
//   icon: string;
//   onClick: Function;
// }

interface LazyTableProps {
  checkbox?: boolean;
  actions?: boolean;
  header?:any;
  actionsClicked?: any,
  action_types?: {
    view?: boolean;
    edit?: boolean;
    delete?: boolean;
    settings?: boolean;
    sign_in?: boolean;
    play?: boolean;
    delete2?: boolean;
  };
  customActions?: any;
  columns: any;
  expandableRow?: boolean;
  onRowExpand?: any;
  onRowCollapse?: any;
  rowExpansionTemplate?: any;
  paginator?: any;
  hasOptions?: boolean;
  api: string;
  apiAll?: string;
  convertData?: boolean;
  convertDataFunction?: any;
  update?: boolean;
  refreshTable?: boolean;
  setRefreshTable?: any;
  bulkOptions?: BulkOption[];
  filters?: any;
  filterValues?:any;
  setLoadingState?:any;
  warnMessage?: any;
  hasExport?: any;
  exportOptions?: any;
  exportHeaders?: ExportHeader[];
  exportClick?: any;
  tableName?: string;
}

const LazyTable: React.FC<LazyTableProps> = ({ 
  checkbox = false, columns,
    actions = false, action_types = {
      view: true,
      edit: false,
      delete: false,
      settings: false,
      sign_in: false,
      play: false,
      delete2: false,
    }, customActions = null, actionsClicked,
    expandableRow = false, onRowExpand, onRowCollapse, rowExpansionTemplate,
    paginator = true,
    hasOptions = false,
    header,
    api = null, apiAll = null,
    convertData, convertDataFunction, refreshTable = false, setRefreshTable = () => {},
    bulkOptions,
    filters=<></>,
    filterValues,
    setLoadingState,
    warnMessage = null,
    hasExport = false, exportOptions = {
      csv: false,
      husbpot: false,
      zoho: false,
      salesforce: false,
    }, exportHeaders, exportClick = null,
    tableName
  }) => {
  const exportMenu = useRef<any>(null);
  const { showToast, clearToast } = useToast();
  const axiosService = useAxios();
  const [tableData, setTableData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [expandedRows, setExpandedRows] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const itemsPerPage = [ { value: '10', name: '10'}, { value: '50', name: '50'}, { value: '100', name: '100'}, ]
  const [pageItem, setPageItem] = useState<any>(itemsPerPage[0]);

  const [bulkItem, setBulkItem] = useState<any>();

  const csvLinkRef = useRef<any>(null);
  const [exportCount, setExportCount] = useState<number>(0);
  const dt = useRef<any>(null);

  const [exportCSVHeaders] = useState(exportHeaders);
  const [exportCSVData, setExportCSVData] = useState<any>([]);

  const exportCSV = () => {
    if(exportCount == 0){
      showToast({ severity: 'info', summary: 'Please wait!', detail: 'Export to CSV is in progress.', sticky: true, loading: true, });
      let lazyCopy = JSON.parse(JSON.stringify(lazyState));
      lazyCopy.custom_filters = dataPreparation(filterValues);  
      axiosService.api.get(apiAll + "?with_data=true&filter=" + JSON.stringify(lazyCopy))
      .then((response: any) => {
        setExportCSVData(response.data);
        clearToast();
        setExportCount(0);
        setTimeout(() => {
          csvLinkRef.current.link.click();
          showToast({ severity: 'success', summary: 'Success!', detail: 'Export CSV finished.', });
        },500);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showToast({ severity: 'error', summary: 'Unable to export!', detail: 'Export CSV failed, an error occured' });
      
        setExportCount(0);
      });
    } else {
      showToast({ severity: 'warn', summary: 'Please wait!', detail: 'Another export is currently in progress.', });
    }
    setExportCount(exportCount + 1);
  };

  const handleExportClick = (type: any) => {
    if(exportCount == 0){
      showToast({ severity: 'info', summary: 'Please wait!', detail: `Export to ${type} is in progress.`, sticky: true, loading: true, });
      let lazyCopy = JSON.parse(JSON.stringify(lazyState));
      lazyCopy.custom_filters = dataPreparation(filterValues);  
      axiosService.api.get(apiAll + "?with_data=true&filter=" + JSON.stringify(lazyCopy))
      .then((response: any) => {
        axiosService.api.post('/api/leads/export-leads/hubspot', response.data)
        .then((response:any)=>{
          clearToast()
          setExportCount(0);
          setTimeout(() => {
            showToast({ severity: 'success', summary: 'Success!', detail: 'Export CSV finished.', });
          },500);
        }).catch((error:any)=>{
          clearToast()
          console.error('Error fetching data:', error);
          showToast({ severity: 'error', summary: 'Unable to export!', detail: 'Export CSV failed, an error occured' });
          setExportCount(0);
        })
      })
      .catch((error: any) => {
        clearToast()
        console.error('Error fetching data:', error);
        showToast({ severity: 'error', summary: 'Unable to export!', detail: 'Export CSV failed, an error occured' });
        setExportCount(0);
      });
    } else {
      showToast({ severity: 'warn', summary: 'Please wait!', detail: 'Another export is currently in progress.', });
    }
    setExportCount(exportCount + 1);
  }

  const [lazyState, setlazyState] = useState<any>({
    first: 0,
    rows: pageItem.value,
    page: 0,
    sortField: '',
    sortOrder: '',
    filters: {
      global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    },
    custom_filters: filterValues
  });
  const dataPreparation = (filterValues:any) => {
    if(filterValues != undefined && filterValues != null &&  filterValues.date_range != undefined && filterValues.date_range != null && filterValues.date_range[0] != null && filterValues.date_range[0] != null && filterValues.date_range[0] != undefined && filterValues.date_range[1] != undefined){
      const filterCopy = JSON.parse(JSON.stringify(filterValues));
      const startDate = `${(filterValues.date_range[0].getMonth() + 1).toString().padStart(2, "0")}/${filterValues.date_range[0].getDate().toString().padStart(2, "0")}/${filterValues.date_range[0].getFullYear()} 00:00:00`;
      const endDate = `${(filterValues.date_range[1].getMonth() + 1).toString().padStart(2, "0")}/${filterValues.date_range[1].getDate().toString().padStart(2, "0")}/${filterValues.date_range[1].getFullYear()} 23:59:59`;
      filterCopy.date_range = [startDate, endDate];
      return filterCopy;
    }else {
      return filterValues;
    }
  };

  const renderActions = (data: any) => {
    return (
      <div className="flex justify-center">
        { action_types.view && <Button className="text-purple ring-0" icon="pi pi-eye" rounded text aria-label="View" onClick={(e) => actionsClicked && actionsClicked(data.id, 'view') }/> }
        { action_types.edit && <Button className="text-blue-500 ring-0" icon="pi pi-pencil" rounded text aria-label="Edit" onClick={(e) => actionsClicked && actionsClicked(data.id, 'edit') }/> }
        { action_types.settings && <Button className="text-blue-500 ring-0" icon="pi pi-cog" rounded text aria-label="Settings" onClick={(e) => actionsClicked && actionsClicked(data.id, 'settings') }/> }
        { (action_types.play) && <Button className="text-blue-500 ring-0" icon="pi pi-play" rounded text aria-label="Play" onClick={(e) => actionsClicked && actionsClicked(data.id, 'play') }/> }
        { action_types.delete2 && <Button className="text-red-500 ring-0" icon="pi pi-trash" rounded text aria-label="Trash" onClick={(e) => actionsClicked && actionsClicked(data.id, 'delete2') }/> }
        { action_types.delete && <Button className="text-red-500 ring-0" icon="pi pi-trash" rounded text aria-label="Trash" onClick={(e) => actionsClicked && actionsClicked(data.id, 'trash') }/> }
        { action_types.sign_in && <Button className="text-purple ring-0" icon="pi pi-sign-in" rounded text aria-label="Trash" onClick={(e) => actionsClicked && actionsClicked(data.id, 'login') }/> }
      </div>
    );
  }

  const renderTemplate = (value: any, template: any, rowData: any, field: any) => { return ( template(value, rowData, field) ) } 

  const [filterVisible, setFilterVisible] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    if(value == ''){
      setlazyState((prevState: any) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          global: {
            ...prevState.filters.global,
            value: value
          }
        }
      }));
    }
  };

  const onGlobalFilterEnter = (e: any) => {
    const value = e.target.value;
    if (e.key === 'Enter') {
      setlazyState((prevState: any) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          global: {
            ...prevState.filters.global,
            value: value
          }
        }
      }));
    }
  }

  const getData = () => {
    let lazyCopy = JSON.parse(JSON.stringify(lazyState));
    lazyCopy.custom_filters = dataPreparation(filterValues);

    
    const lazyCopyString = JSON.stringify(lazyCopy);
    const encodedLazyCopy = encodeURIComponent(lazyCopyString);

    // console.log('lazyCopy :: ', encodedLazyCopy)

    // setBulkItem(undefined);
    // setSelectedData([]);
    // setSelectAll(false);
    axiosService.api.get(api + "?filter=" + encodedLazyCopy)
      .then((response: any) => {
        setTotalRecords(response.data?.total);
        if (convertData) {
          if(response.data.data){
            convertDataFunction(response.data.data, setTableData);
          } else {
            setTableData(response.data.data);
          }
        } else {
          setTableData(response.data.data);
        }
        
        if(setLoadingState){
          setLoadingState(false);
        }

        setLoading(false);
        setRefreshTable(false);

        if(response.data.status == false){
          showToast({
            severity: 'error',
            summary: 'Mailbox Error!',
            detail: response.data.message,
          });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        setTableData([]);
        setTotalRecords(0);
        setRefreshTable(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    getData();
  }, [lazyState]);

  useEffect(() => {
    if(refreshTable){
      getData();
    }
  }, [refreshTable])

  const [exportModel, setExportModel] = useState<any>([]);
  
  useEffect(() => {
    if (hasExport && exportOptions.csv) {
      setExportModel((prevModel: any) => [
        ...prevModel,
        {
          label: 'Export CSV',
          icon: 'pi pi-file',
          template: (item: any) => {
            return (
              <div className="px-4 py-4 flex gap-2 items-center cursor-pointer" onClick={() => exportCSV()}>
                <i className={item.icon}></i>
                <span>{item.label}</span>
              </div>
            );
          },
        },
      ]);
    }
    
    if (hasExport && exportOptions.hubspot) {
      setExportModel((prevModel: any) => [
        ...prevModel,
        {
          label: 'Export to HubSpot',
          icon: IconMap('FaHubspot'),
          template: (item: any) => {
            return (
              <div className="px-4 py-4 flex gap-2 items-center cursor-pointer" onClick={() => handleExportClick('hubspot')}>
                {item.icon}
                <span>{item.label}</span>
              </div>
            );
          },
        },
      ]);
    }
  }, []);

  const onPage = (event: any) => {
    event.custom_filters = dataPreparation(filterValues);
    setlazyState(event);
  };

  const onSort = (event: any) => {
    event.custom_filters = dataPreparation(filterValues);
    setlazyState(event);
  };

  const onFilter = (event: any) => {
    event['first'] = 0;
    event.custom_filters = dataPreparation(filterValues);
    setlazyState(event);
  };

  const onPageItem = (e: any) => {
    setPageItem(e);
    setlazyState((prevState: any) => ({
      ...prevState,
      rows: e.value,
      page: 0,
      first: 0,
      custom_filters: dataPreparation(filterValues)
    }));
  }

  const onBulkSelectItem = (e: any) => {
    setBulkItem(e);
  }

  const handleBulkAccept = () => {
    setBulkItem(undefined);
    setSelectedData([]);
    setSelectAll(false);
  }

  const confirmBulkSubmit = (bulkOption: any, data: any) => {
    confirmDialog({
      tagKey: 'lazyTableKey',
      message: `Are you sure you want to "${bulkOption.name}" the selected data?`,
      header: 'Bulk Selection Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'bg-purple rounded-lg',
      acceptLabel: 'Proceed',
      accept: () => {bulkOption.onSubmit(data); handleBulkAccept();}
    });
  };

  const handleBulkSubmit = () => {
    if(bulkItem){
      const bulkOption: any = bulkOptions && bulkOptions.filter((e: any) => e.value === bulkItem.value)[0];
      confirmBulkSubmit(bulkOption, selectedData);
    }
  }

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedData(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event: any) => {
    setBlocked(true);
    const selectAll = event.checked;
    if (selectAll) {
      setSelectAll(true);
      if(apiAll){
        let lazyCopy = JSON.parse(JSON.stringify(lazyState));
        lazyCopy.custom_filters = dataPreparation(filterValues);  
        axiosService.api.get(apiAll + "?filter=" + JSON.stringify(lazyCopy))
        .then((response: any) => {
          setSelectedData(response.data);
          setBlocked(false);
          if(warnMessage){
            showToast({
              severity: 'warn',
              summary: 'Select All Warning!',
              detail: warnMessage
            })
          }
        })
        .catch((error: any) => {
          setBlocked(false);
          console.error('Error fetching data:', error);
        });
      }
    } else {
      setSelectAll(false);
      setBlocked(false);
      setSelectedData([]);
    }
  };

  const applyFilters = () => {
    setFilterVisible(false);
    setlazyState((prevValues:any) => ({
      ...prevValues,
      custom_filters: filterValues
    }));
  };

  const optionTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="flex gap-4 items-center">
          {option.icon && option.icon}
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const optionsTemplate = (option: any) => {
    return (
      <div className="flex gap-4 items-center">
        {option.icon && option.icon}
        <div>{option.name}</div>
      </div>
    );
  };

  const blockTemplate = () => {
    return (
      <div className="mt-[5rem] flex">
        <span className="text-[16px] text-black font-[600] flex items-center">
          <ProgressSpinner style={{width: '36px', height: '36px', marginRight:'1rem'}} strokeWidth="8" animationDuration="2s" /> 
          Please wait ...</span>
      </div>
    );
  };

  const columnExpandRow = (rowId: any) => {
    let copyExpanded = JSON.parse(JSON.stringify(expandedRows));
    const check = expandedRows[rowId];
    let status = true;

    if(check != undefined && check != null){
      status = !check;
    }

    if(status){
      setExpandedRows((prevValues:any) => (
        {
          ...prevValues,
          [rowId]: status
        }
      ));
    }else{
      delete(copyExpanded[rowId])
      setExpandedRows(copyExpanded);
    }
  };

  const isExpanded = (rowId:any) => {
    const check = expandedRows[rowId];

    return (check == undefined || check == null || check == false) ? 'MdKeyboardDoubleArrowRight' : 'MdKeyboardDoubleArrowDown';
  }

  const getNestedValue = (obj:any, path:any) => {
    return path.split('.').reduce((acc:any, part:any) => acc && acc[part], obj);
  };

  return (
  <BlockUI blocked={blocked} className="flex items-start important-bg-white backdrop-blur-sm z-[0]" 
    // pt={{mask:{className:'bg-[rgba(0,0,0,0)]'}}}
    template={blockTemplate}>
    {/* <div className="relative h-full">
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="panel bg-black rounded-lg p-5 z-[10]"></div>
      </div>
    </div> */}
    {
      hasOptions && !loading &&
      <div className="mb-4 flex flex-col md:flex-row gap-2 justify-between items-center">
        <div className="rounded-lg border border-gray w-full md:w-1/4 flex items-center">
          <i className="pi pi-search p-2 text-label"></i>
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} onKeyUp={onGlobalFilterEnter} placeholder="Search items..." className="border-none bg-transparent ring-0 w-full" />
          {IconMap('FaQuestionCircle','search ms-1 items-center text-[#585858] mx-2',undefined,16)}<Tooltip target=".search" content={"Input search then hit Enter"}/>
        </div>
        {
          selectedData && (bulkOptions && bulkOptions?.length > 0) && selectedData.length > 0 && (
            <div className="w-full lg:w-2/4 flex items-center gap-4">
              <div className="rounded-lg border border-gray w-full">
                <Dropdown placeholder="Select Action" valueTemplate={optionTemplate} itemTemplate={optionsTemplate}  value={bulkItem?.value} onChange={onBulkSelectItem} options={bulkOptions} optionLabel="name" className="border-none ring-0 w-full bg-transparent" />
              </div>
              <Button onClick={handleBulkSubmit} disabled={!bulkItem} label="Submit" className="bg-purple rounded-lg border-purple w-[130px]" />
            </div>
          )
        }
        
        <div className="flex w-full md:w-1/2 justify-end gap-2 flex-wrap">
          <div className="flex justify-center gap-2">
            <div className="flex items-center gap-2">
              <label className="text-label">Items per page</label>
              <div className="rounded-lg border border-gray">
                <Dropdown value={pageItem.value} onChange={onPageItem} options={itemsPerPage} optionLabel="name" className="border-none ring-0 w-full bg-transparent" />
              </div>
            </div>
            <div className="flex items-center p-2 rounded-lg border border-gray">
              <div>
                {IconMap('FaColumns', 'text-purple')}
              </div>
            </div>
          </div>
          <div>
            <Button className="bg-transparent text-black rounded-lg border-gray w-full md:auto" iconPos="right" label="Filter" icon="pi pi-filter" onClick={() => setFilterVisible(true)} />
            <Sidebar visible={filterVisible} position="right" onHide={() => setFilterVisible(false)}>
              <h2>Advanced Filters</h2>
              {filters}
              <div className="columns-2 mt-5">
                <div>
                  <Button className="bg-purple text-white rounded-lg border-purple w-full md:auto" label="Apply" onClick={applyFilters} />
                </div>
                <div>
                  <Button className="bg-transparent text-black rounded-lg border-gray w-full md:auto" label="Reset" onClick={undefined} />
                </div>
              </div>
            </Sidebar>
          </div>
          {
            hasExport && exportOptions.csv && (
              <CSVLink ref={csvLinkRef} filename="Exported Data" className="hidden bg-purple rounded-lg border-none ring-0 text-white p-2 px-4" data={exportCSVData} headers={exportCSVHeaders}>Export CSV</CSVLink>
            )
          }
          
          {
            exportModel.length > 0 && <>
              <TieredMenu model={exportModel} popup ref={exportMenu} breakpoint="767px" />
              <Button loading={exportCount > 0} disabled={exportCount > 0} label="Export" iconPos="right" icon="pi pi-upload" className="bg-purple rounded-lg border-none ring-0" onClick={(e: any) => exportMenu.current.toggle(e)} />
            </>
          }
          
          
        </div>
      </div>
    }
    {
      loading && <SkeletonTable />
    }
    {
      !loading && 
      <DataTable ref={dt} value={tableData} paginator={paginator} rows={(paginator ? pageItem.value : undefined)}
        lazy first={lazyState.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
        onFilter={onFilter} filters={lazyState.filters} loading={loading}
        selectionMode={!checkbox ? null : 'checkbox'} rowHover={true} selection={selectedData} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}
        dataKey="id"
        onRowToggle={(e:any) => setExpandedRows(e.data)} expandedRows={expandedRows} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}
        emptyMessage={(<>
          <div className="flex flex-col items-center gap-4 text-center justify-center">
            <img src={NoResultFound} alt="No Results Found"/> 
            <p className="text-3xl">No Results Found</p>
          </div>
        </>)}
        loadingIcon={<i className="pi pi-spin pi-spinner text-purple" style={{ fontSize: '3rem' }}></i>}
        pt={{root: {className: 'rounded-lg'}, loadingOverlay: {className: 'bg-purple bg-opacity-10 rounded-lg'}}}
      >
        {checkbox && <Column headerClassName="bg-purple-light rounded-tl-lg rounded-bl-lg border-none" selectionMode="multiple" headerStyle={{ width: '3rem' }} />}
        {
          columns.map((col:any, i:any) => {
            const column1 = (<Column 
              key={i} 
              headerClassName={`${col.headerClassName? col.headerClassName:''} bg-purple-light ${(i===0 && !checkbox ? 'rounded-tl-lg rounded-bl-lg' : '' )} ${(i == (columns.length-1) && !actions)? 'rounded-tr-lg rounded-br-lg' : ''} ${ expandableRow && col.has_expander ? '' : 'border-white border'} font-normal text-black`}
              field={col.field} 
              header={col.header}
              body={(data) => col.hasTemplate ? renderTemplate(getNestedValue(data, col.field), col.template, data, col.field) : getNestedValue(data, col.field)}
            />)
            const column2 = (<Column 
              key={`${i}-expander`} 
              headerClassName={`bg-purple-light ${ expandableRow && col.has_expander ? '' : 'border-white border'} font-normal text-black`}
              expander={true} 
              style={{ width: '2rem' }} 
            />)
            if (col.has_expander && expandableRow) {
              // return [column1, column2];
              return [
                <Column 
                  key={i} 
                  headerClassName={`${col.headerClassName? col.headerClassName:''} bg-purple-light ${(i===0 && !checkbox ? 'rounded-tl-lg rounded-bl-lg' : '' )} ${(i == (columns.length-1) && !actions)? 'rounded-tr-lg rounded-br-lg' : ''} ${ expandableRow && col.has_expander ? '' : 'border-white border'} font-normal text-black`}
                  field={col.field} 
                  header={col.header}
                  body={(data) => {
                    return (
                      <>
                        <div onClick={() => columnExpandRow(data.id)} className="grid grid-cols-6 gap-5 h-[100%] p-[20px] cursor-pointer">
                          <div className="col-span-5 flex items-center">
                            <p>{col.hasTemplate ? renderTemplate(data[col.field], col.template, data, col.field) : data[col.field]}</p>
                          </div>
                          <div className="col-span-1 flex items-center justify-center">
                            <div className="rounded-full border border-purple-600 p-2 expand-row-icon-custom">
                              {IconMap(isExpanded(data.id),'text-purple' )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }}
                />
              ]
            }
            return column1;
          })
        }
        {actions && !customActions && <Column headerClassName="bg-purple-light rounded-tr-lg rounded-br-lg border-none font-normal text-black" header={'Actions'} headerStyle={{ width: '3rem' }} body={renderActions}/>}
        {actions && customActions && <Column headerClassName="bg-purple-light rounded-tr-lg rounded-br-lg border-none font-normal text-black" header={'Actions'} headerStyle={{ width: '3rem' }} body={customActions}/>}
      </DataTable>
    }
    <ConfirmDialog tagKey="lazyTableKey" />
  </BlockUI>
  );
}

export default LazyTable;
