import React, { createContext, useContext, ReactNode } from 'react';
import Cookies from 'js-cookie';
import AxiosService from '../services/AxiosService';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

interface AxiosContextType {
  axiosService: AxiosService;
}

const AxiosContext = createContext<AxiosContextType | null>(null);

export const useAxios = (): AxiosService => {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error('useAxios must be used within an AxiosProvider');
  }
  return context.axiosService;
};

interface AxiosProviderProps {
  children: ReactNode;
  axiosService:any;
}

export const AxiosProvider: React.FC<AxiosProviderProps> = ({ children, axiosService }) => {
  // const axiosService = new AxiosService();
  const token = Cookies.get('bearer_token');
  const navigate = useNavigate();
  const location = useLocation();

  if (token) {
    axiosService.setHeaders(token);
  }

  const accept = () => {
    sessionStorage.clear();
    Cookies.remove('bearer_token');
    navigate('/');
  };

  // Add an Axios response interceptor to catch errors
  axiosService.api.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.' &&
        location.pathname !== '/'
      ) {
        // confirmDialog({
        //   // group: 'headless',
        //   tagKey: 'axiosLogout',
        //   icon: 'pi pi-exclamation-triangle',
        //   defaultFocus: 'accept',
        //   message: 'You have been logout',
        //   header: 'Session Timeout',
        //   acceptClassName: 'bg-purple rounded-lg',
        //   closable: false,
        //   closeOnEscape: false,
        //   rejectLabel: '',
        //   acceptLabel: 'Proceed',
        //   accept,
        // });
      }

      return Promise.reject(error);
    }
  );

  axiosService.api.interceptors.request.use((config: any) => {
    if (config.url && config.url.includes('/onboarding')) {
      config.timeout = 0;
    }
    return config;
  });

  return (
    <AxiosContext.Provider value={{ axiosService }}>
      {children}
      <ConfirmDialog
        // group="headless"
        tagKey='axiosLogout'
        className='w-[95%] lg:w-1/6'
        content={({ headerRef, contentRef, footerRef, hide, message }: any) => (
          <div className="flex flex-col items-center p-5 w-full bg-white rounded-lg">
            <div className="rounded-full bg-purple text-white flex justify-center items-center h-[6rem] w-[6rem] -mt-[4rem]">
              <i className="pi pi-clock text-5xl"></i>
            </div>
            <span className="font-bold text-2xl block mb-2 mt-4" ref={headerRef}>
              {message.header}
            </span>
            <p className="mb-0" ref={contentRef}>
              {message.message}
            </p>
            <div className="flex items-center gap-2 mt-4" ref={footerRef}>
              <Button
                label="Login"
                onClick={(event) => {
                  hide(event);
                  accept();
                }}
                className="w-8rem rounded-lg bg-purple"
              ></Button>
            </div>
          </div>
        )}
      />
    </AxiosContext.Provider>
  );
};
