import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAxios } from '../../../contexts/AxiosContext';

interface State {
  run: boolean;
  steps: Step[];
}

const GuideTour: React.FC<any> = ({ visible = false, onHide, type, data, setData, onSubmit, onCallback }) => {
  const navigate = useNavigate();
  const axiosService = useAxios();
  const [state, setState] = useState<State>({
    run: false,
    steps: [ 
      {
        content: <h2>This is an early access built of SectorSift, bugs are expected. Thank you for your participation and feedback.</h2>,
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: 'center',
        target: 'body',
      },
      {
        content: 'Here you can find your Campaigns overview',
        placement: 'bottom',
        styles: {
          options: {
            width: 300,
          },
        },
        target: '.campaign-nav',
      },
      {
        content: 'Click this button to add a new agent to start a campaign',
        placement: 'bottom',
        styles: {
          options: {
            width: 300,
          },
        },
        target: '.add-campaign',
      },
      // {
      //   content: 'For bug reporting please use this feedback button, it might take 30 seconds to load.',
      //   placement: 'top',
      //   styles: {
      //     options: {
      //       width: 300,
      //     },
      //   },
      //   target: '.ybug-launcher--bottom-right ',
      // },
      
    ],
  });

  const startTour = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      run: true,
    }));
    onHide();
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const currentStep = state.steps[index];
  
    if (finishedStatuses.includes(status)) {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));

      axiosService.api.post('/api/account/first-login')
      .then((response:any) => {
        // navigateToPage('dashboard')
      })
      .catch((error:any) => {
        // Handle error response here
      });

    }
  
    if ( action === 'next' && currentStep.target === '.campaign-nav') {
      navigateToPage('campaigns');
    }
  };
  
  const navigateToPage = (page: string) => {
    navigate(`/main?page=${page}`);
  };

  return (
    <>
     <Dialog className="lg:w-1/3 w-[95%] text-center" header="" closable={false} draggable={false} resizable={false} visible={visible} onHide={() => onHide()}>
        <div>
          <p className="text-3xl font-bold">Welcome to SectorSift!</p>
          <Button
            onClick={startTour}
            label="Start Tour"
            className="bg-purple rounded-lg border-purple mt-2"
          />
        </div>
      </Dialog>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={state.run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={state.steps}
        styles={{
          options: {
            zIndex: 10000,
          },
          buttonBack: {
            color: '#2a2298',
          },
          buttonNext:{
            backgroundColor:'#2a2298'
          }
        }}
        hideCloseButton={true}
      />
    </>
  );
};

export default GuideTour;
