import { Chips } from "primereact/chips";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useEffect, useState } from "react";
import IconMap from "../../../components/Icons/IconMaps";
import { Tooltip } from "primereact/tooltip";
import Inputs from "../../../components/forms/Inputs";
import { useAxios } from "../../../contexts/AxiosContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputHandler } from "../../../services/EventHandlers";
import { Dialog } from "primereact/dialog";
import { useToast } from "../../../contexts/ToastContext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { TabPanel, TabView } from "primereact/tabview";
import JsonView from "@uiw/react-json-view";

const SuperAdminSandbox:React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [sandboxName, setSandboxName] = useState((new Date().toString()));
  const [keptPrompts, setKeptPrompts] = useState<any[]>([]);
  const [viewVisible, setViewVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<any[] | null>();
  const [defaultPrompt, setDefaultPrompt] = useState<number>(1);
  const [rawInfo, setRawInfo] = useState<any>({
    suggested_sales_profile: null,
    suggested_pitch: null,
    suggested_keywords: null
  });
  const staticData = {
    website: "https://seditioasia.com",
    company_name: "Seditio Asia",
    description: "We are a software development company that focuses on providing IT solutions for all types of businesses across the world.",
    target_description: "We focus on clients who want to improve their current business processes, and those who wants to modernize their ongoing systems.",
    target_keywords: ['IT Solutions', 'Web Applications', 'Mobile Applications', 'International'],
    target_countries: selectedCountries,
    offers: "We offer 3 months of support for every project being handed down to clients.",
    suggested_sales_profile: "",
    suggested_pitch: "",
    suggested_keywords:[],
    fullname: "Antonie Geerts",
    email: 'antonie@seditio.ie',
    calendly_link: '',
    linkedin_url: '',
    phone: '+639999999999',
    miscellaneous_links: ['www.robomo.com', 'www.seditioltd.com']
  };
  const checkedTemplate = {
    website: true,
    company_name: true,
    description: true,
    target_description: true,
    target_keywords: true,
    target_countries: true,
    offers: true,
    // tools: true,
    fullname: true,
    email: true,
    calendly_link: true,
    linkedin_url: true,
    phone: true,
    miscellaneous_links: true,
  };
  const [includeKeys, setIncludeKeys] = useState<any>({
    suggested_sales_profile: checkedTemplate,
    suggested_pitch: {...checkedTemplate, suggested_sales_profile: true},
    suggested_keywords: {...checkedTemplate, suggested_sales_profile: true, suggested_pitch: true},
  });
  const [sampleDetails, setSampleDetails] = useState<any>(staticData);
  const [countryOptions, setCountryOptions] = useState([]);
  // const promptTemplate = {
  //   prompt: ''
  // };
  // const [sample, setSample] = useState({
  //   description: ''
  // });
  const [prompts, setPrompts] = useState<any>({
    suggested_sales_profile: [
      {value: "Can you suggest a business profile based on the my given data"},
      {value: 'Please output only the business profile only without saying "this is the business profile"'},
      {value: "Output is in paragraph form without any JSON."}
    ],
    suggested_pitch: [
      {value: "Can you suggest a business pitch based on the my given data"},
      {value: 'Please output only the business pitch only without saying "this is the pitch"'}
    ],
    suggested_keywords: [
      {value: "respond in json"},
      {value: 'json format is ["keyword1", "keyword2", "keyword3", "keyword4", "keyword5"]'},
      {value: "can you suggest keywords for google search to find future clients based on the my given data"},
      {value: "can you give me a set of 10 relevant keywords for each of these base keywords"}
    ]
  });

  const initCountries = () => {
    axiosService.api.get(`/api/countries/get-countries`)
    .then((response: any) => {
      const countriesData = response.data;
      const countriesFormatted = countriesData.map((country: any) => ({
        name: country.name,
        value: country.id,
        flag: country.flag
      }));
      setCountryOptions(countriesFormatted);
    }).catch((error:any) => {
      setLoadingState(false);
      const errorMessage = error.response.data.message;
      showToast({
        severity: 'error',
        summary: 'Error!',
        detail: errorMessage
      });
    });
  };

  const countryTemplate = (option: any) => {
    return (
      <div className="flex items-center">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
          className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
          style={{ width: '18px' }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    const length = selectedCountries ? selectedCountries.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? 's' : ''} selected.
      </div>
    );
  };

  const addPrompt = (key:any) => {
    setPrompts((prevValues:any) => ({
      ...prevValues,
      [key]: [...prevValues[key], {value: ''}]
    }));
  };

  const removePrompt = (removeIndex:number, key:string) => {
    let promptsCopy = JSON.parse(JSON.stringify(prompts));
    let keyCopy = promptsCopy[key];
    keyCopy = keyCopy.filter((item:any, index:any) => (index != removeIndex))
    promptsCopy[key] = keyCopy;
    setPrompts(promptsCopy);
  };

  const handlePromptChange = (index:number, key:string, e:any) => {
    let promptsCopy = JSON.parse(JSON.stringify(prompts));
    let keyCopy = promptsCopy[key][index];
    keyCopy.value = e.target.value;
    promptsCopy[key][index] = keyCopy;
    setPrompts(promptsCopy);
  };

  const handleCheckboxChange = (key:any, rootKey:any) => (e:any) => {
    const copy = JSON.parse(JSON.stringify(includeKeys));
    copy[rootKey][key] = !e.target.value;
    setIncludeKeys(copy);
  };

  const generateSuggestions = () => {
    setLoadingState(true);

    let sampleCopy = JSON.parse(JSON.stringify(sampleDetails));
    sampleCopy.target_countries = selectedCountries;

    let suggestionDetails = {
      details: sampleCopy,
      prompts: {
        suggested_sales_profile: prompts.suggested_sales_profile.map((k:any) => (k.value)),
        suggested_pitch: prompts.suggested_pitch.map((k:any) => (k.value)),
        suggested_keywords: prompts.suggested_keywords.map((k:any) => (k.value))
      },
      selected_keys: includeKeys
    };

    axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_sales_profile', suggestionDetails)
      .then((response:any) => {
        suggestionDetails.details.suggested_sales_profile = response.data.suggestion;

        setRawInfo((prevValues:any) => ({
          ...prevValues,
          suggested_sales_profile: response.data
        }));

        setSampleDetails((prevValues:any) => (
          {
            ...prevValues,
            suggested_sales_profile: response.data.suggestion
          }
        ));

        axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_pitch', suggestionDetails)
          .then((pitchResponse:any) => {
            suggestionDetails.details.suggested_pitch = pitchResponse.data.suggestion;

            setRawInfo((prevValues:any) => ({
              ...prevValues,
              suggested_pitch: pitchResponse.data
            }));

            setSampleDetails((prevValues:any) => (
              {
                ...prevValues,
                suggested_pitch: pitchResponse.data.suggestion
              }
            ));
            
            axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_keywords', suggestionDetails)
              .then((keywordsResponse:any) => {
                suggestionDetails.details.suggested_keywords = keywordsResponse.data.suggestion;

                setRawInfo((prevValues:any) => ({
                  ...prevValues,
                  suggested_keywords: keywordsResponse.data
                }));

                
                setSampleDetails((prevValues:any) => (
                  {
                    ...prevValues,
                    suggested_keywords: keywordsResponse.data.suggestion
                  }
                ));

                setVisible(true);
                setLoadingState(false);
              });
          });
      });
  };

  const keepPrompts = () => {
    const keepData = {
      prompts: prompts,
      include_keys: includeKeys,
      name: sandboxName
    };

    axiosService.api.post('/api/superadmin/sandbox', keepData)
      .then((response:any) => {
        showToast({
          severity: 'success',
          summary: 'Saved!',
          detail: response.data.message
        });

        loadKeptPrompts();
      }).catch((error:any) => {
        setLoadingState(false);
        const errorMessage = error.response.data.message;
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: errorMessage
        });
      });
  };

  const loadKeptPrompts = () => {
    axiosService.api.get('/api/superadmin/sandboxes')
      .then((response:any) => {
        setKeptPrompts(response.data);
        const defaultPrompt = response.data.find((k:any) => (k.default == 1));

        if(defaultPrompt){
          setDefaultPrompt(defaultPrompt.id);
        }
      }).catch((error:any) => {
        setLoadingState(false);
        const errorMessage = error.response.data.message;
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: errorMessage
        });
      });
  };

  const initPrompts = (id:number) => {
    const searchedPrompt = keptPrompts.find((item:any) => (item.id == id));
    setPrompts({
      suggested_keywords: searchedPrompt.suggested_keywords,
      suggested_pitch: searchedPrompt.suggested_pitch,
      suggested_sales_profile: searchedPrompt.suggested_sales_profile,
    });

    let includedKeys = searchedPrompt.include_keys;

    Object.keys(includedKeys).forEach(key => {
      if(includedKeys[key].miscellaneous_links == undefined){
        includedKeys[key].miscellaneous_links = true;
      }
    });
    
    setIncludeKeys(includedKeys);

    setViewVisible(false);
  };

  const updateDefaultPrompt = (id:number) => {
    setDefaultPrompt(id);

    axiosService.api.get(`/api/superadmin/sandbox/default/${id}`)
      .then((response:any) => {
        showToast({
          severity: 'success',
          summary: 'Updated!',
          detail: response.data.message
        });
      }).catch((error:any) => {
        setLoadingState(false);
        const errorMessage = error.response.data.message;
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: errorMessage
        });
      });
  }

  const deletePrompt = (id:number) => {
    console.log("delete::", id);
    axiosService.api.delete(`/api/superadmin/sandbox/${id}`)
      .then((deleteResponse:any) => {
        showToast({
          severity: 'success',
          summary: 'Removed!',
          detail: deleteResponse.data.message
        });

        loadKeptPrompts();
      }).catch((error:any) => {
        setLoadingState(false);
        const errorMessage = error.response.data.message;
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: errorMessage
        });
      });
  };

  const confirmDeletePrompt = (id:number) => {
    confirmDialog({
      message: 'Do you want to unkeep this prompt?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deletePrompt(id)
    });
  };

  useEffect(() => {
    console.log("rawInfo::", rawInfo);
  }, [rawInfo]);

  useEffect(() => {
    setLoadingState(false);
    initCountries();
    loadKeptPrompts();
  }, []);
  return (
    <>
      <ConfirmDialog/>
      <Dialog header="Kept Prompts" visible={viewVisible} onHide={() => setViewVisible(false)}
            style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
        <div>
          {
            keptPrompts.map((item:any) => {
              return (
                <>
                  <div className="flex flex-row">
                    <div className="m-3 cursor-pointer" onClick={() => initPrompts(item.id)}>
                      {(item.name) ? item.name : item.created_at}
                    </div>
                    {/* <div>
                      trash
                    </div> */}
                    <div className="p-3">
                    <Inputs 
                        dataId={item.id} 
                        value={defaultPrompt} 
                        onChange={(e:any) => (updateDefaultPrompt(e.value))} 
                        label={item.id} 
                        // checked={item.id == defaultPrompt}
                        labelClass="hidden"
                        type={"radio"} 
                      />
                    </div>
                    <div className="p-3">
                      <div onClick={() => (confirmDeletePrompt(item.id))}>
                        {IconMap('FaTrashAlt', 'text-red-500')}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          }
        </div>
      </Dialog>
      <Dialog header="AI Suggestions" visible={visible} onHide={() => setVisible(false)}
            style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
        <div>
          <div>
            <Inputs value={sandboxName} label={"Sandbox Name"} type={"text"} onChange={(e:any) => setSandboxName(e.target.value)} />
          </div>
          <div>
            <div>
              <Accordion>
                <AccordionTab header="Suggested Sales/Business Profile">
                  <TabView>
                    <TabPanel header="Suggestion">
                      <div className="bg-black bg-opacity-40 p-5">
                        {sampleDetails.suggested_sales_profile}
                      </div>
                    </TabPanel>
                    <TabPanel header="Raw Prompt">
                      <div className="p-5">
                        {rawInfo.suggested_sales_profile != null ? rawInfo.suggested_sales_profile.prompt : ""}
                      </div>
                    </TabPanel>
                    <TabPanel header="Keys and Variables">
                      <div className="p-5">
                        <JsonView value={(rawInfo.suggested_sales_profile != null) ? rawInfo.suggested_sales_profile.validValues : {}} />
                      </div>
                    </TabPanel>
                    <TabPanel header="Raw JSON">
                      <div className="p-5">
                        <JsonView value={(rawInfo.suggested_sales_profile != null) ? rawInfo.suggested_sales_profile : {}} />
                      </div>
                    </TabPanel>
                  </TabView>
                </AccordionTab>
                <AccordionTab header="Suggested Pitch">
                  <TabView>
                    <TabPanel header="Suggestion">
                      <div className="bg-black bg-opacity-40 p-5">
                        {sampleDetails.suggested_pitch}
                      </div>
                    </TabPanel>
                    <TabPanel header="Raw Prompt">
                      <div className="p-5">
                        {rawInfo.suggested_pitch != null ? rawInfo.suggested_pitch.prompt : ""}
                      </div>
                    </TabPanel>
                    <TabPanel header="Keys and Variables">
                      <div className="p-5">
                        <JsonView value={(rawInfo.suggested_pitch != null) ? rawInfo.suggested_pitch.validValues : {}} />
                      </div>
                    </TabPanel>
                    <TabPanel header="Raw JSON">
                      <div className="p-5">
                        <JsonView value={(rawInfo.suggested_pitch != null) ? rawInfo.suggested_pitch : {}} />
                      </div>
                    </TabPanel>
                  </TabView>
                </AccordionTab>
                <AccordionTab header="Suggested Keywords">
                  <TabView>
                    <TabPanel header="Suggestion">
                      <div className="bg-black bg-opacity-40 p-5">
                        {(sampleDetails.suggested_keywords != null && sampleDetails.suggested_keywords != undefined) ? sampleDetails.suggested_keywords.join(', ') : ""}
                      </div>
                    </TabPanel>
                    <TabPanel header="Raw Prompt">
                      <div className="p-5">
                        {rawInfo.suggested_keywords != null ? rawInfo.suggested_keywords.prompt : ""}
                      </div>
                    </TabPanel>
                    <TabPanel header="Keys and Variables">
                      <div className="p-5">
                        <JsonView value={(rawInfo.suggested_keywords != null) ? rawInfo.suggested_keywords.validValues : {}} />
                      </div>
                    </TabPanel>
                    <TabPanel header="Raw JSON">
                      <div className="p-5">
                        <JsonView value={(rawInfo.suggested_keywords != null) ? rawInfo.suggested_keywords : {}} />
                      </div>
                    </TabPanel>
                  </TabView>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
          <div className="mt-5">
            <Button onClick={keepPrompts} label="Keep Prompts" className="bg-purple rounded-lg border-purple ring-0" />
          </div>
        </div>
      </Dialog>
      <div className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">GPT Sandbox</h1>
          <Button onClick={() => setViewVisible(true)} label="View Kept Prompts" className="bg-purple rounded-lg border-purple ring-0" />
        </div>
        <div className="panel bg-white rounded-lg p-5">
          <div className="prompt-container">
            <Accordion>
              <AccordionTab header="Sample Details">
                <div className="grid lg:grid-cols-4 gap-5">
                  <div className="lg:col-span-3"></div>

                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">Website {IconMap('FaQuestionCircle','business-site ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-site" content={"You can update your company's website link"}/> </label>
                    <InputText 
                      value={sampleDetails.website} 
                      placeholder={'example.com'} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'website')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">Company Name {IconMap('FaQuestionCircle','business-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-name" content={"The company name you represent as part of the signature/footer of the outgoing emails"}/> </label>
                    <InputText 
                      value={sampleDetails.company_name} 
                      placeholder={'Example Company Name'} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'company_name')(e))} 
                    />
                  </div>

                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">Your Full Name {IconMap('FaQuestionCircle','business-fullname ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-fullname" content={"The name you want to use for signing off the e-mail you will send to your leads"}/> </label>
                    <InputText 
                      value={sampleDetails.fullname} 
                      placeholder={'Full Name'} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'fullname')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">Email Address {IconMap('FaQuestionCircle','business-emailaddress ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-emailaddress" content={"The Email address as part of the signature/footer of the outgoing emails"}/> </label>
                    <InputText 
                      value={sampleDetails.email} 
                      placeholder={'example@email.com'} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'email')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">Meeting Calendar Link (Calendly, Hubspot, etc.) {IconMap('FaQuestionCircle','business-calendlylink ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-calendlylink" content={"A meeting link using Calendly to add as part of the e-mail generation"}/> </label>
                    <InputText 
                      value={sampleDetails.calendly_link} 
                      placeholder={'https://calendly.com/username'} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'calendly_link')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <Inputs label={<>Phone Number (Optional){IconMap('FaQuestionCircle','business-phone ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-phone" content={"Your Phone Number your leads can contact you on"}/></>} labelClass={'text-label flex flex-row items-center'} type={'phone'} 
                      value={sampleDetails.phone} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'phone')(e))} 
                      withValidation={false} />
                    
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">Role {IconMap('FaQuestionCircle','business-role ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-role" content={"Your role in the organization reaching out to the leads"}/> </label>
                    <InputText 
                      value={sampleDetails.role} 
                      placeholder={'Your Role'} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'role')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">LinkedIn URL {IconMap('FaQuestionCircle','business-linkedin ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-linkedin" content={"Your LinkedIn Profile to be added as part of the outgoing email and/or signature"}/> </label>
                    <InputText 
                      value={sampleDetails.linkedin_url} 
                      placeholder={'https://www.linkedin.com/in/username'} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'linkedin_url')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-4">
                    <label className="text-label flex flex-row items-center">Business Description  {IconMap('FaQuestionCircle','business-desc ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-desc" content={"You can update what's your business for?"}/></label>
                    <InputTextarea 
                      value={sampleDetails.description} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'description')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-4">
                    <label className="text-label flex flex-row items-center">Description of your target client, market and product  {IconMap('FaQuestionCircle','business-target ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-target" content={"You can update your target client, market and product "}/></label>
                    <InputTextarea 
                      value={sampleDetails.target_description} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'target_description')(e))} 
                    />
                  </div>
                  <div className={`flex flex-col lg:col-span-2`}>
                    <label className={`flex items-center text-[16px] font-[500]`}>Target Countries   {IconMap('FaQuestionCircle','client-countries ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-countries" content={"You can update countries you are targeting"} /></label>
                    <MultiSelect 
                      autoOptionFocus={false} 
                      focusOnHover={false} 
                      selectOnFocus={false} 
                      value={selectedCountries} 
                      options={countryOptions} 
                      onChange={(e: MultiSelectChangeEvent) => setSelectedCountries(e.value)}
                      optionLabel="name" 
                      filter placeholder="Select Countries" 
                      itemTemplate={countryTemplate} 
                      panelFooterTemplate={panelFooterTemplate} 
                      className="w-full md:w-20rem" 
                      display="chip" 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <label className="text-label flex flex-row items-center">Offer / CTA for potential customers  {IconMap('FaQuestionCircle','client-customers ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-customers" content={"You can update the description of products, services, or promotions you want to offer to potential customers"} /></label>
                    <InputText 
                      value={sampleDetails.offers} 
                      onChange={(e:any) => (InputHandler(setSampleDetails, 'offers')(e))} 
                    />
                  </div>
                  <div className="flex flex-col lg:col-span-4">
                    <label className="text-label flex flex-row items-center">Target Keywords {IconMap('FaQuestionCircle','client-keywords ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-keywords" content={"You can update the keywords, press enter to add each keyword"} /></label>
                    <Chips value={sampleDetails.target_keywords}
                      className="w-full"
                      pt={{container: {className: 'w-full overflow-scroll gap-[5px]'}}} 
                      onChange={(e: any) => (
                        setSampleDetails((prevValues: any) => ({
                          ...prevValues,
                          target_keywords: e.target.value
                        }))
                      )} 
                      allowDuplicate={false}
                    />
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab header="Text Prompts">
                <div className="flex flex-col mb-3">
                  <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-[100%] md:w-[40%]">
                      <div>
                        <label htmlFor="">Sales/Business Profile Prompt</label>
                      </div>
                      <div>
                        {prompts.suggested_sales_profile.map((item: any, index: number) => {
                          return (
                            <div key={index} className="flex flex-row w-full">
                              <div className="mr-3">
                                {index + 1}:
                              </div>
                              <div className="w-full">
                                <Inputs 
                                  onChange={(e:any) => (handlePromptChange(index, 'suggested_sales_profile', e))}
                                  value={item.value} 
                                  label={undefined} 
                                  type={"text"} />
                              </div>
                              <div>
                                <Button className="text-red-500 ring-0" icon="pi pi-trash" rounded text aria-label="Trash" onClick={() => (removePrompt(index, 'suggested_sales_profile'))}/>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-2 md:max-h-64 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
                      {
                        Object.entries(includeKeys.suggested_sales_profile).map(([key, value], index) => {
                          return (
                            <div key={index} className="m-2">
                              <Inputs 
                                value={value} 
                                checked={value} 
                                label={<span className="break-all">{key}</span>} 
                                type={"checkbox"} 
                                onChange={handleCheckboxChange(key, 'suggested_sales_profile')} 
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button  
                      onClick={() => (addPrompt('suggested_sales_profile'))}
                      className="bg-purple rounded-lg border-purple ring-0"  
                      label="Add New Prompt"  
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-3" >
                  <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-[100%] md:w-[50%]">
                      <div>
                        <label htmlFor="">Pitch and First Time Contact Prompt</label>
                      </div>
                      <div>
                        {prompts.suggested_pitch.map((item: any, index: number) => {
                          return (
                            <div key={index} className="flex flex-row w-full">
                              <div className="mr-3">
                                {index + 1}:
                              </div>
                              <div className="w-full">
                                <Inputs 
                                  onChange={(e:any) => (handlePromptChange(index, 'suggested_pitch', e))}
                                  value={item.value} 
                                  label={undefined} 
                                  type={"text"} />
                              </div>
                              <div>
                                <Button className="text-red-500 ring-0" icon="pi pi-trash" rounded text aria-label="Trash" onClick={() => (removePrompt(index, 'suggested_pitch'))}/>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-2 md:max-h-64 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
                      {
                        Object.entries(includeKeys.suggested_pitch).map(([key, value], index) => {
                          return (
                            <div key={index} className="m-2">
                              <Inputs 
                                value={value} 
                                checked={value} 
                                label={<span className="break-all">{key}</span>} 
                                type={"checkbox"} 
                                onChange={handleCheckboxChange(key, 'suggested_pitch')} 
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <Button  
                      onClick={() => (addPrompt('suggested_pitch'))}
                      className="bg-purple rounded-lg border-purple ring-0"  
                      label="Add New Prompt" 
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-3">
                  <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-[100%] md:w-[50%]">
                      <div>
                        <label htmlFor="">Suggested Keywords Prompt</label>
                      </div>
                      <div>
                        {prompts.suggested_keywords.map((item: any, index: number) => {
                          return (
                            <div key={index} className="flex flex-row w-full">
                              <div className="mr-3">
                                {index + 1}:
                              </div>
                              <div className="w-full">
                                <Inputs 
                                  onChange={(e:any) => (handlePromptChange(index, 'suggested_keywords', e))}
                                  value={item.value} 
                                  label={undefined} 
                                  type={"text"} />
                              </div>
                              <div>
                                <Button className="text-red-500 ring-0" icon="pi pi-trash" rounded text aria-label="Trash" onClick={() => (removePrompt(index, 'suggested_keywords'))}/>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-2 md:max-h-64 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                      {Object.entries(includeKeys.suggested_keywords).map(([key, value], index) => (
                        <div key={index} className="m-2">
                          <Inputs 
                            value={value} 
                            checked={value} 
                            label={<span className="break-all">{key}</span>} 
                            type="checkbox" 
                            onChange={handleCheckboxChange(key, 'suggested_keywords')} 
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                    <div className="flex justify-end">
                      <Button  
                        onClick={() => (addPrompt('suggested_keywords'))}
                        className="bg-purple rounded-lg border-purple ring-0"  
                        label="Add New Prompt" 
                      />
                    </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
          <div className="mt-5">
            <Button 
              onClick={generateSuggestions} 
              label="Generate AI Output" 
              // icon="pi pi-plus" 
              className="bg-purple rounded-lg border-purple ring-0" 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminSandbox;