import React, { createContext, useContext, useReducer, ReactNode, useEffect } from 'react';

interface State {
  isLoggedIn: boolean;
}

type AuthAction =
  | { type: "login" }
  | { type: "logout" };

const initialState: State = { isLoggedIn: false };

function authReducer(state: State, action: AuthAction): State {
  switch (action.type) {
    case "login":
      return { ...state, isLoggedIn: true };
    case "logout":
      return { ...state, isLoggedIn: false };
    default:
      throw new Error("Unknown action");
  }
}

interface AuthContextType {
  state: State;
  dispatch: React.Dispatch<AuthAction>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const storedState = localStorage.getItem('authState');
    if (storedState) {
      dispatch({ type: JSON.parse(storedState).isLoggedIn ? 'login' : 'logout' });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('authState', JSON.stringify(state));
  }, [state]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
