import React, { ReactNode, useState, useEffect } from 'react';
import { useLocation,useNavigate } from "react-router-dom";
import Navbar from '../navigations/Navbar';
import { MenuItem } from 'primereact/menuitem';
import Sidebar from '../navigations/Sidebar';
import { BreadCrumb } from 'primereact/breadcrumb';

type LayoutProps = {
  children: ReactNode;
  axiosService?: any;
  latestNotifications:any;
  setNotifOffset?:any;
  handleShowOlderNotifs:any;
  loadedOlderNotifs:boolean;
  loadingOlderNotifs:boolean;
  handleNotifScroll:any;
  notificationDropdownRef:any;
  setLoadingState?: any;
  setRefreshAccount?: any;
  refreshAccount?: any;
  credInfo?: any;
};

const Layout: React.FC<LayoutProps> = ({ children, axiosService, latestNotifications, setNotifOffset,loadedOlderNotifs, handleShowOlderNotifs, 
  handleNotifScroll, loadingOlderNotifs,notificationDropdownRef, setLoadingState, refreshAccount, setRefreshAccount, credInfo }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [sidebarShow, setSidebarShow] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = new URLSearchParams(location.search).get("page") || "";

  const pageURLItems = (currentPage: any) => {
    const currentPageArray = currentPage.split('/');
    const labels = currentPageArray.map((item: any) => ({ label: item }));
    return labels;
  };

  const handleToggleSidebar = (collapsed: boolean) => {
    // console.log('sidebar collapsed clicked :: ', collapsed)
    setSidebarCollapsed(collapsed); 
  };
  
  const handleShowSidebar = (show: boolean) => {
    // console.log('sidebar show clicked :: ', show)
    setSidebarShow(show); 
    // setSidebarCollapsed(show); 
  };

  const navigateToPage = (page: string) => {
    navigate(`/main?page=${page}`);
  };

  const routeTemplate = (item:any, options:any) => {
    return (
      <>
        {item.icon && <span className={`p-menuitem-icon text-[#6c757d] cursor-pointer ${item.icon}`} onClick={()=>navigateToPage('dashboard')}></span>}

      </>
    );
  };

  useEffect(() => {
    const items = pageURLItems(currentPage).map((item:any, index:any) => ({
      label: item.label.charAt(0).toUpperCase() + item.label.slice(1).toLowerCase(),
    })).filter((item:any) => item.label !== 'dashboard');

    console.log("currentPage::", currentPage);

    setMenuItems(items);
  }, [currentPage]);

  const dashboard = { icon: 'pi pi-home', url: 'https://www.primereact.org',  template:routeTemplate};
  
  return (
    <div className="w-full h-screen">
      <Navbar 
        setLoadingState={setLoadingState} 
        axiosService={axiosService} 
        sidebarShow={handleShowSidebar} 
        setNotifOffset={setNotifOffset} 
        latestNotifications={latestNotifications} 
        loadedOlderNotifs={loadedOlderNotifs} 
        handleShowOlderNotifs={handleShowOlderNotifs} 
        handleNotifScroll={handleNotifScroll} 
        loadingOlderNotifs={loadingOlderNotifs} 
        notificationDropdownRef={notificationDropdownRef} 
        setRefreshAccount={setRefreshAccount}
        refreshAccount={refreshAccount}
      />
      <div className="flex flex-no-wrap overflow-x-hidden pt-[80px] h-full">
        <div className={`overflow-y-auto flex ${sidebarShow? 'flex sm:flex w-[48rem]':'hidden sm:flex'} ${sidebarCollapsed ? 'w-30' : 'w-30 md:w-64'} bg-[#0E1833] text-white`}>
          <Sidebar onToggleSidebar={handleToggleSidebar} sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} credInfo={credInfo}/> 
        </div>
        <div id="main-content" className={`overflow-y-auto w-full p-5 bg-[#eee] ${sidebarShow? 'hidden':null}`}>
          <div className="breadcrumbs flex items-center gap-1">
            <BreadCrumb 
              className='bg-transparent border-0 p-0' 
              model={[...menuItems]} 
              home={dashboard} 
              />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
