import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../contexts/AxiosContext';
import { useToast } from '../../contexts/ToastContext';
import { Button } from 'primereact/button';
import Inputs from '../../components/forms/Inputs';
import { LoginIMG, Logo } from '../../components/Icons/ImgPath';
import Cookies from 'js-cookie';
import { InputHandler } from '../../services/EventHandlers';

const SubscriptionCallbackV2: React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();
  const {showToast} = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  // const [serial] = useState<any>(queryParams.get('serial'));
  const type = queryParams.get('type');
  // const [couponSerial] = useState<any>(queryParams.get('couponSerial'));
  const [withCoupon] = useState<any>(queryParams.get('withCoupon'));
  const [userId] = useState<any>(queryParams.get('userId'));
  const [token, setToken] = useState<any>(queryParams.get('token'));
  const [passwordDetails, setPasswordDetails] = useState({
    password: '',
    c_password: '',
    user_id: queryParams.get('userId')
  });

  const handleSubmit = () => {
    console.log("token::", token);

    // axiosService.api.post('/api/user/new-password', passwordDetails)
    //   .then((response:any) => {
    //     console.log("response::", response);
    //     showToast({
    //       severity: 'success',
    //       summary: 'New Password!',
    //       detail: response.data.message
    //     });

    //     setTimeout(() => {
    //       window.close();
    //     }, 1000);
    //   }).catch((error:any) => {
    //     setLoadingState(false);
    //     console.log("message::", error.response)
    //     showToast({
    //       severity: 'error',
    //       summary: 'Invalid Registration!',
    //       detail: error.response.data.message
    //     });
    //     console.error('Error fetching data:', error);
    //   });

    // setTimeout(() => {
    //   window.close();
    // }, 3000);
  };

  useEffect(() => {
    if(type == 'success'){
      const bearerToken = "Bearer " + token;
      Cookies.set('bearer_token', bearerToken);
      axiosService.setHeaders(bearerToken);
      setLoadingState(false); 
    }

    setTimeout(() => {
      window.close();
    }, 3000);
    // window.close();
  }, [])

  return (
    <div className="min-h-screen bg-white flex">
      {/* Left Side */}
      <div className="hidden md:flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-[#F0EFFF]">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-col items-center">
            {/* */}
            <img className="h-12 w-auto mb-5" src={Logo} alt="SectorSift Logo"/>
            <img className="mx-auto mb-4 ml-[-12rem] mt-[-1rem] h-auto w-[700px] max-w-[700px]" src={LoginIMG} alt="Login Logo"/>
            <div className="mt-[-5rem] flex flex-col justify-center">
              <h2 className="text-3xl font-medium text-gray-900 text-center">
                Try our new B2B Lead Generation Platform now.
              </h2>
              <p className="mt-2 text-[20px] font-light text-center text-gray-600">
                AI-powered platform streamlining B2B lead generation through precision targeting and market analysis.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*Right Side  */}
      <div className="w-full flex justify-center items-center p-[3rem]">
        <div className="w-full max-w-lg">
          <div className="w-full">
            {/*  Sign up form*/}
            <div className="mb-4 flex flex-col items-center">
              <img className="block md:hidden h-12 w-auto mb-4" src={Logo} alt="SectorSift Logo"/>
              {
                type == 'success' ?
                  <h3 className="mb-5 text-[28px] leading-6 font-medium text-gray-900 leading-[40px] text-center">Thank you for subscribing! Redirecting to create a new password for you account.</h3>
                :
                  <h3>Registration cancelled</h3>
              }
              {/* <GoogleButton loading={googleLoading} setLoading={setGoogleLoading} label={'Signup with Google'} onSuccess={handleOnSuccess} className="w-full border-purple border bg-white text-purple font-bold rounded-lg border-purple" scope={'https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email'} /> */}
              {/* <Button label="Signup with Google" icon={(<img width={20} src={GoogleIcon}/>)}/> */}
            </div>
            {/* <div className="relative mb-5 text-center text-xs font-normal leading-5 text-gray-500">
              <h4>Or</h4>
            </div> */}
            <div className="mt-6">
              {/* Input fields and buttons */}
              {/* <form className="mb-5"> */}
                {/* Password field */}
                  {/* <Inputs label={'Password'} type={'password'} value={passwordDetails.password} onChange={(e:any) => InputHandler(setPasswordDetails, 'password')(e)} className="md:col-span-2" required invalid withValidation />
                  <Inputs label={'Confirm Password'} type={'password'} value={passwordDetails.c_password} onChange={(e:any) => InputHandler(setPasswordDetails, 'c_password')(e)} className="md:col-span-2" required invalid  withValidation/> */}
              {/* </form> */}
            </div>
            {/* <div className="">
              <Button label="Change Password" onClick={handleSubmit} className="w-full bg-purple rounded-lg border-purple"/>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCallbackV2;
