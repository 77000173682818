export class FormValidations {
  static isEmpty(value: string): boolean {
      return !value.trim();
  }

  static isValidEmail(email: string): boolean {
    return isValidEmail(email);
  }

  static isConfirmedPass(value:string,password: string): boolean {
    return value === password;
  }
}

interface ValidationProps {
  value: any;
  type: string;
  required?: boolean;
  password?: string; // New prop to pass the original password value
}

export const ValidationMessage: React.FC<ValidationProps> = ({ value, type, required, password }) => {
  const validate = () => {
    switch (type) {
      case "text":
      case "text-area":
        if (required && !value) {
          return "This field is required.";
        }
        break;
      case "number":
        if (required && (isNaN(value) || ! value)) {
          return "Please enter a valid number.";
        }
        break;
      case "date":
        if (required && !value) {
          return "Please select a date.";
        }
        break;
      case "rangepicker":
        if (required && !value) {
          return "Please select a date.";
        }
        break;
      case "select":
      case "select-border":
        if (required && (!value || value == "" || value.length == 0)) {
          return "Please select an option.";
        }
        break;
      case "phone":
        if (required && !value) {
          return "Phone number is required.";
        }
        break;
      case "password":
        if (required && !value) {
          return "Password is required.";
        }
        break;
      case "email":
        if (required && !value) {
          return "Email is required.";
        } else if (!isValidEmail(value)) {
          return "Please enter a valid email address.";
        }
        break;
      case "password-confirmation":
        if (required && !value) {
          return "Password confirmation is required.";
        } else if (required && value !== password) {
          return "Passwords do not match.";
        }
        break;
      case "checkbox":
        if (required && !value) {
          return "This checkbox must be checked.";
        }
        break;
      default:
        return null;
    }
  };

  const validationMessage = validate();

  return (
    <div className="text-red-600 text-[12px] mt-1">
      <span>{validationMessage}</span>
    </div>
  );
};

const isValidEmail = (email:any) => {
  // Simple email validation pattern (you can use a more complex regex if needed)
  const emailPattern =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

