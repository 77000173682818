import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Inputs from "../../../forms/Inputs";
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputHandler } from "../../../../services/EventHandlers";
import { useAxios } from "../../../../contexts/AxiosContext"
import { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


interface Props {
  visible: boolean
  onHide?: any
  type: string
  data: any
  setData: any
  onSubmit: any
  errors: any
  setErrors: any
  refreshTables: any
  roles: any
}

const TeamMemberModal: React.FC<Props> = ({ 
  visible = false, onHide, type, data, setData, onSubmit, errors, setErrors, refreshTables, roles = []
}) => { 
  const axiosService = useAxios();
  const [sending, setSending] = useState(false);
  
  const validate = () => {
    let valid = true;
    const newErrors = { firstname: '', lastname: '', email: '' };

    if (!data.firstname) {
      newErrors.firstname = 'First Name is required';
      valid = false;
    }

    if (!data.lastname) {
      newErrors.lastname = 'Last Name is required';
      valid = false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!emailPattern.test(data.email)) {
      newErrors.email = 'Email is not valid';
      valid = false;
    }
    
    setErrors(newErrors);
    return valid;
  }; 
  
  
  const onSubmit2 = async () => {
    if (!validate() && type !== "edit") {
      return;
    }
    
    setSending( true );
    

    let data_in: any = {};
    switch(type){
      case 'add':
      
        data_in = {
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          role: data.role.id
        };      
      
        const res: any = await axiosService.api.post('/api/colleagues/send_invite', data_in);
        
        const data_out: any = res.data;
        
        setSending( false );
        
        refreshTables();
        
        
        if( !data_out.is_valid ){
          withReactContent(Swal).fire({
            icon: "error",
            title: "Unable to Send the Invitation"
          });  
          onHide();
          return;        
        }
        
        withReactContent(Swal).fire({
          icon: "success",
          title: "Successfully Sent the Invitation"
        });       
        
        
           
        break;
      case 'edit':

        if (data.firstname) {
          data_in.firstname = data.firstname;
        }
        if (data.lastname) {
          data_in.lastname = data.lastname;
        }
        if (data.email) {
          data_in.email = data.email;
        }
        if (data.role && data.role.id) {
          data_in.role = data.role.id;
        }

        // const res2: any = await axiosService.api.post(`/api/colleagues/edit/${data.id}`, data_in);
    
        // const data_out2: any = res2.data;
        
        setSending(false);
        
        refreshTables();
        
        withReactContent(Swal).fire({
          icon: "success",
          title: "Successfully Updated the Info"
        });
      }
    
   
    
    onHide();
  }   
  

  const footerContent = () => {
  
    let label_mbfue =  (type == "add" ? "Add Team Member" : "Update Team Member");
    if( sending ) label_mbfue = '';
      
    return <div >
      <Button disabled={ sending } label="Cancel" onClick={onHide} className="p-button-text text-red-500" />
      <Button disabled={ sending }  className="bg-purple rounded-lg border-purple m-0" onClick={onSubmit2} autoFocus >{label_mbfue}{sending? <>Sending the Invite <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" /></> : <></>}</Button>
    </div>
  }

  useEffect(() => {
    if(type === "add"){
      setData((prevState:any) => ({
        ...prevState,
        role: roles.find((d: any) => d.name.toLowerCase() === "colleague")
      }));
    } else {
      setData((prevState:any) => ({
        ...prevState,
        role: roles.find((d: any) => d.id === data.role)
      })); 
    }
    
    setSending(false);
    // console.log(roles.find((d: any) => d.name.toLowerCase() === "colleague"))
  }, [visible]);

  return(
    <Dialog className="lg:w-1/4 w-[95%]" header={type == "add" ? 'Add Team Member' : 'Update Team Member'} draggable={false} resizable={false} visible={visible} onHide={onHide} footer={footerContent}>
      <form className="mb-5">

        <div className="grid grid-cols-2 gap-2">
          {
            type && type == "add" && <>
              <div>
                <Inputs disabled={ sending } label={'Firstname'} type="text" value={data.firstname} onChange={(e:any) => InputHandler(setData, 'firstname')(e)} />
                {errors.firstname && <small className="p-error">{errors.firstname}</small>}
              </div>
              <div>
                <Inputs disabled={ sending } label={'Lastname'} type="text" value={data.lastname} onChange={(e:any) => InputHandler(setData, 'lastname')(e)} />
                {errors.lastname && <small className="p-error">{errors.lastname}</small>}
              </div>
              <div className={"col-span-2"}>
                <Inputs disabled={ sending } label={'Email'} placeholder="example@email.com" keyfilter={'email'} type="text" value={data.email} onChange={(e:any) => InputHandler(setData, 'email')(e)} />
                {errors.email && <small className="p-error">{errors.email}</small>}
              </div>
            </>
          }
          
          <div className={`${type === "add" ? 'col-span-1' : 'col-span-2'}`}>
            <Inputs label={'Role'} type="dropdown" value={data.role} onChange={(e:any) => InputHandler(setData, 'role')(e)} 
              options={roles}
              optionLabel={'name'}
              placeholder="Select Role"
            />
          </div>
          
        </div>

      </form>
    </Dialog>
  );
}

export default TeamMemberModal;
