import { useLocation, useNavigate } from 'react-router-dom';
import IconMap from '../Icons/IconMaps';
import React, { useState } from 'react';


type SidebarProps = {
  onToggleSidebar: (collapsed: boolean) => void;
};

const SuperSidebar: React.FC<SidebarProps> = ({ onToggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = new URLSearchParams(location.search).get("page") || "";

  const modules: any[] = [
    { label: 'Accounts', page: 'superadmin-accounts', icon: '' },
    { label: 'Agents', page: 'superadmin-agents', icon: '' },
    { label: 'Sandbox', page: 'sandbox', icon: '' },
    { label: 'Promotions', page: 'superadmin-promotions', icon: '' },
    // { label: 'Mails', page: 'mails', icon: 'IoMailOutline',
    //   submenus: [ 
    //     { label: 'Emails', page: 'emails', icon: 'MdOutlineAlternateEmail' },
    //     { label: 'Mailbox', page: 'mailbox', icon: 'TbMailCog' },
    //   ]
    // },
  ];

  const [collapsed, setCollapsed] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState<{ [key: string]: boolean }>({});

  const navigateToPage = (page: string) => {
    navigate(`/admin-KNdApfxpXK-main?page=${page}`);
  };

  const toggleSidebar = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    onToggleSidebar(newCollapsedState); 
  };

  const renderMenuItems = (items: any[]) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
        <li
          onClick={() => {
            if (item.submenus) {
              // Toggle submenu visibility if it exists
              setSubMenuOpen({ ...subMenuOpen, [item.label]: !subMenuOpen[item.label] });
            } else {
              navigateToPage(item.page);
            }
          }}
          className={`${currentPage === item.page ? 'bg-purple border-purple-highlight text-purple-highlight border-l' : ''}
              p-4 cursor-pointer flex gap-2 items-center
              transition transition-duration-300 ease-in 
              hover:border-l hover:text-purple-highlight hover:border-purple-highlight hover:bg-purple`}
        >
          <div className='flex flex-row items-center justify-between w-full'>
            <div className='flex flex-row items-center'>{IconMap(item.icon,'mr-2')} <span className='hidden sm:block '>{collapsed ? null : item.label}</span></div>
            
            {item.submenus && !collapsed? 
              ( IconMap('FaChevronRight',` duration-700 ${ subMenuOpen[item.label]?'rotate-90':null}`,undefined, 14) )
              : null }
          </div>
        </li>
        {item.submenus && subMenuOpen[item.label]  && !collapsed && (
          <ul className='pl-4'>
            {renderMenuItems(item.submenus)}
          </ul>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div id="Sidebar">
      <ul>
        {renderMenuItems(modules)}
        <li
          className='p-4 cursor-pointer flex gap-2 items-center
            transition transition-duration-300 ease-in 
            border-t border-t-1 border-[rgba(255,255,255,0.1)] border-solid
            hover:border-l hover:text-purple-highlight 
            hover:border-purple-highlight hover:bg-purple'
          onClick={toggleSidebar}
        >
          {collapsed ? IconMap('RiIndentIncrease') : IconMap('RiIndentDecrease')} {collapsed ? null : ''}
        </li>
      </ul>
    </div>
  );
};

export default React.memo(SuperSidebar);
