import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputHandler } from '../../../../services/EventHandlers';
import { RadioButton } from 'primereact/radiobutton';
import { AmericanExpress, Mastercard, Paypal, UnionPay, Visa } from '../../../Icons/ImgPath';
import { Checkbox } from 'primereact/checkbox';
import { useAxios } from '../../../../contexts/AxiosContext';
import { useToast } from '../../../../contexts/ToastContext';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Tooltip } from 'primereact/tooltip';

interface BillingDetails {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    state?: string;
    name?: string;
    email?: string;
}

interface PaymentModalProps {
    visible: boolean;
    onHide: () => void;
    onSuccess: any;
}

const BillingPaymentModal: React.FC<PaymentModalProps> = ({ visible, onHide, onSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // const [loading, setLoading] = useState<boolean>(false);

    const axiosService = useAxios();
    const { showToast } = useToast();
    const [saving, setSaving] = useState(false);
    const [activePaymentMethod, setActivePaymentMethod] = useState();
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(false);
    const [setCardDetails] = useState<any>({
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
    })

    const [paypalDetails, setPaypalDetails] = useState<any>({
      email: ''
    });

    const [billingDetails, setBillingDetails] = useState<BillingDetails>({
        city: '',
        country: '',
        line1: '',
        line2: '',
        postalCode: '',
        state: '',
        name: '',
        email: ''
    });

    // const months = Array.from({ length: 12 }, (v, k) => ({ label: `${k + 1}`.padStart(2, '0'), value: k + 1 }));
    // const years = Array.from({ length: 10 }, (v, k) => ({ label: `${new Date().getFullYear() + k}`, value: new Date().getFullYear() + k }));

    const [activeIndex, setActiveIndex] = useState<any>();

    const handleSave = async () => {
      setSaving(true);
      // const paymentMethodData = {
      //   paymentMethod: activePaymentMethod,
      //   default: defaultPaymentMethod,
      //   ...(activePaymentMethod === 'card' && {
      //     cardDetails: {
      //       number: cardDetails.number,
      //       expiryMonth: cardDetails.expiryMonth,
      //       expiryYear: cardDetails.expiryYear,
      //       code: cardDetails.code,
      //     },
      //   }),
      //   ...(activePaymentMethod === 'paypal' && {
      //     paypalDetails: {
      //       email: paypalDetails.email,
      //     },
      //   }),
      //   billingDetails,
      // };

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        
        billing_details: {
          name: billingDetails.name,
          email: billingDetails.email,
          address: {
            line1: billingDetails.line1,
            line2: billingDetails.line2,
            city: billingDetails.city,
            state: billingDetails.state,
            postal_code: billingDetails.postalCode,
          },
        },
      });

      if (error) {
        console.log('errorMessage :: ', errorMessage)
        setErrorMessage(error.message || 'An error occurred');
        setSaving(false);
      } else { 
        axiosService.api.post('/api/payment-method/add', { paymentMethodId: paymentMethod?.id, makeDefault: defaultPaymentMethod }).then((response: any) => {
          showToast({
            severity: response.data.status ? 'success' : 'error',
            summary: response.data.status ? 'Success!' : 'Failed!',
            detail: response.data.message
          });
          onSuccess();
          setSaving(false);
          handleOnHide();
        }).catch((error: any) => {
          console.log(error);
          setSaving(false);
        });
      }
    }

    const handleOnHide = () => {
      setCardDetails({
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
      })
      setPaypalDetails({
        email: ''
      })
      setBillingDetails({
        city: '',
        country: '',
        line1: '',
        line2: '',
        postalCode: '',
        state: '',
        name: '',
        email: ''
      })
      setSaving(false);
      onHide();
    }

    return (
      <Dialog header="Add Payment Method" visible={visible} onHide={handleOnHide} draggable={false} resizable={false} className="lg:w-1/3 w-[95%]">
        <div className="flex flex-col gap-4 bg-[#F6F6F6] p-5 rounded-lg">
          <div className='border-b pb-5'>
            <div className='flex items-center justify-between'>
              <label className="text-label text-xl">Billing Information</label>
              <Button 
                label={billingDetails.name != "" ? 'Edit' : 'Add'}
                className='bg-transparent text-blue-500 ring-0 rounded-lg border-none'
                onClick={(e:any) => activeIndex == 0 ? setActiveIndex(undefined) : setActiveIndex(0)}
              />
            </div>
            {
              activeIndex != 0 && billingDetails.name != "" && <p className='pt-2'>{billingDetails.name}<br/>{billingDetails.line1} {billingDetails.line2} {billingDetails.city} {billingDetails.state} {billingDetails.postalCode}<br/>{billingDetails.email}</p> 
            }
            <Accordion activeIndex={activeIndex} onTabChange={(e:any) => setActiveIndex(e.index)}>
              <AccordionTab header="" pt={{
                header: { className: "hidden" },
                content: { className: 'border rounded-lg', style: { border: 'solid 1px #eee' }}
              }}>
                <div className='grid lg:grid-cols-4 gap-2'>
                  <div className='flex flex-col gap-1 lg:col-span-2'>
                    <label className='text-label'>Full Name</label>
                    <InputText className='ring-0 rounded-lg' value={billingDetails.name} onChange={(e: any) => InputHandler(setBillingDetails, "name")(e)}/>
                  </div>
                  <div className='flex flex-col gap-1 lg:col-span-2'>
                    <label className='text-label'>Email</label>
                    <InputText keyfilter={'email'} className='ring-0 rounded-lg' value={billingDetails.email} onChange={(e: any) => InputHandler(setBillingDetails, "email")(e)}/>
                  </div>
                  <div className='flex flex-col gap-1 lg:col-span-4'>
                    <label className='text-label'>Address Line 1</label>
                    <InputText className='ring-0 rounded-lg' value={billingDetails.line1} onChange={(e: any) => InputHandler(setBillingDetails, "line1")(e)}/>
                  </div>
                  <div className='flex flex-col gap-1 lg:col-span-4'>
                    <label className='text-label'>Address Line 2</label>
                    <InputText className='ring-0 rounded-lg' value={billingDetails.line2} onChange={(e: any) => InputHandler(setBillingDetails, "line2")(e)}/>
                  </div>
                  <div className='flex flex-col gap-1 lg:col-span-2'>
                    <label className='text-label'>City</label>
                    <InputText className='ring-0 rounded-lg' value={billingDetails.city} onChange={(e: any) => InputHandler(setBillingDetails, "city")(e)}/>
                  </div>
                  <div className='flex flex-col gap-1 lg:col-span-1'>
                    <label className='text-label'>State</label>
                    <InputText className='ring-0 rounded-lg' value={billingDetails.state} onChange={(e: any) => InputHandler(setBillingDetails, "state")(e)}/>
                  </div>
                  <div className='flex flex-col gap-1 lg:col-span-1'>
                    <label className='text-label'>Postal Code</label>
                    <InputText className='ring-0 rounded-lg' value={billingDetails.postalCode} onChange={(e: any) => InputHandler(setBillingDetails, "postalCode")(e)}/>
                  </div>
                  <div className='flex justify-end lg:col-span-4'>
                    <Button label='Save' className='bg-purple ring-0 rounded-lg' onClick={() => setActiveIndex(undefined)} />
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>

          <div className='flex flex-col gap-4'>
            <div className='flex w-full'>
              <RadioButton inputId="card" name="card" value="card" checked={activePaymentMethod === "card"} onChange={(e: any) => setActivePaymentMethod(e.value)} />
              <div className={`relative ${activePaymentMethod !== "card" ? 'h-0' : 'h-full'} transition-all duration-300 w-full`}>
                <div className={`flex gap-3 items-center`}>
                  <label htmlFor="card" className="ml-2">Card</label>
                  <div className="flex gap-1">
                    <img src={UnionPay} alt="Union Pay" className='object-contain'/>
                    <img src={Visa} alt="Visa" className='object-contain'/>
                    <img src={Mastercard} alt="Mastercard" className='object-contain'/>
                    <img src={AmericanExpress} alt="American Express" className='object-contain'/>
                  </div>
                </div>
                <div className={`grid lg:grid-cols-4 w-[100%] gap-4 p-4 ${activePaymentMethod !== "card" ? 'hidden' : ''}`}>
                  <div className='flex flex-col gap-1 lg:col-span-2'>
                    <label className='text-label text-sm font-bold'>Card Number*</label>
                    <div className='rounded-lg border border-gray-400 p-3'>
                      <CardNumberElement />
                    </div>
                    {/* <InputText placeholder="•••• •••• •••• ••••" className='ring-0 rounded-lg' value={cardDetails.number} onChange={(e: any) => InputHandler(setCardDetails, "number")(e)}/> */}
                  </div>
                  <div></div>
                  <div></div>
                  <div className="flex flex-col gap-1 lg:cols-span-2">
                    <label className="text-label text-sm font-bold">Expiry Date*</label>
                    <div className='rounded-lg border border-gray-400 p-3'>
                      <CardExpiryElement />
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className='flex flex-col gap-1'>
                    <label className='text-label text-sm font-bold'>Code*</label>
                    <div className='rounded-lg border border-gray-400 p-3'>
                      <CardCvcElement />
                    </div>
                    {/* <InputText placeholder="***" className='ring-0 rounded-lg' value={cardDetails.code} maxLength={3} onChange={(e: any) => InputHandler(setCardDetails, "code")(e)}/> */}
                  </div>
                  

                </div>
              </div>
              
            </div>
            <div className='flex w-full'>
              <RadioButton disabled inputId="paypal" name="paypal" className='cursor-not-allowed' value="paypal" checked={activePaymentMethod === "paypal"} onChange={(e: any) => setActivePaymentMethod(e.value)} />
              <div className={`relative ${activePaymentMethod !== "paypal" ? 'h-0' : 'h-full'} transition-all duration-300 w-full`}>
                <div className={`flex gap-3 items-center`}>
                  <Tooltip target={'.cursor-not-allowed'}/>
                  <label data-pr-tooltip='Under Development' data-pr-position='top' htmlFor="paypal" className="ml-2 cursor-not-allowed">Paypal</label>
                  <div className="flex gap-1">
                    <img src={Paypal} alt="Paypal" className='object-contain'/>
                  </div>
                </div>
                <div className={`grid lg:grid-cols-4 gap-4 p-4 ${activePaymentMethod !== "paypal" ? 'hidden' : ''}`}>
                  <div className='flex flex-col lg:col-span-4 gap-1'>
                    <InputText className='ring-0 rounded-lg w-full' value={paypalDetails.email} onChange={(e: any) => InputHandler(setPaypalDetails, "email")(e)}/>
                    <p className='text-xs text-center'>Sectorsift uses PayPal’s Merchant-Initiated Payment billing agreement.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-3 mt-3'>
              <div className="flex align-items-center text-sm">
                <Checkbox inputId="default_payment" name="default_payment" value="default_payment" onChange={(e: any) => setDefaultPaymentMethod(!defaultPaymentMethod)} checked={defaultPaymentMethod} />
                <label htmlFor="default_payment" className="ml-2 text-label">Set as Default Payment Method</label>
              </div>
              <Button loading={saving} label='Save' className='rounded-lg ring-0' onClick={handleSave}/>
              <Button label='Cancel' className='rounded-lg ring-0 bg-transparent text-blue-500' onClick={handleOnHide}/>
            </div>

          </div>

          
        </div>
      </Dialog>
    );
};

export default BillingPaymentModal;
