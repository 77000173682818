import { Button } from "primereact/button";
import Table from "../../components/tables/Table";
import { useEffect, useState } from "react";
// import { InputSwitch } from "primereact/inputswitch";
import MailboxModal from "../../components/modals/mailbox/MailboxModal";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../contexts/ToastContext";
import IconMap from "../../components/Icons/IconMaps";

const Mailbox: React.FC<any> = ({ axiosService, setLoadingState }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  // const switchTemplate = (value: any, rowData: any) => {
  //   return <InputSwitch checked={value} onClick={(e:any) => setSwitchStatus('is_active', e.target.checked, rowData)}/>
  // }

  const typeTemplate = (value: any, rowData: any) => {
    return (
      <div className="flex items-center justify-center">
        {value === "office365" ? IconMap('MicrosoftOffice') : (value === "google" ? IconMap('FcGoogle') : IconMap('IoMailOutline'))}
      </div>
    );
  }

  const [mailboxData, setMailboxData] = useState<any>([]);
  const [mailboxColumn] = useState<any>([
    { field: 'email', header: 'Email' },
    { field: 'type', headerClassname: 'text-center ', header: 'Type', template: typeTemplate, hasTemplate: true },
    { field: 'associated_agent', header: 'Associated Agent' },
    { field: 'sent_emails', header: 'Emails Sent' },
    { field: 'queued_emails', header: 'Emails Queued' },
    { field: 'status', header: 'Status' },
    { field: 'leads_per_month', header: 'Leads Per Month' },
    // { field: 'is_active', header: 'Is Active', template: switchTemplate, hasTemplate: true },
  ]);

  const setSwitchStatus = (element_name: string, value: boolean, data: any) => {
    setMailboxData((prevState:any) => {
      const updatedMailbox = prevState.map((item: any) => {
        if (item.id === data.id) {
          return { ...item, [element_name]: value }; // Update the item with the matching id
        }
        return item; // Return unchanged item for other ids
      });
      return updatedMailbox;
    });
  };
  

  const [modalVisible, setModalVisible] = useState(false);
  const handleModalOnHide = () => {
    setModalVisible(false);
    setSmtpModalData(null);
  }

  const handleModalCallback = (response: any) => {
    if(response.data.status){
      getData();
      showToast({
        severity: 'success',
        summary: 'Success!',
        detail: response.data.message,
      });
    } else {
      showToast({
        severity: 'warn',
        summary: 'Warning!',
        detail: response.data.message,
      });
    }
  }

  const getData = () => {
    setLoadingState(true);
    axiosService.api.get('/api/mailboxes/all')
    .then((response:any) => {
      setLoadingState(false);
      setMailboxData(response.data.map((item:any) => {
        const newItem = { ...item };
        newItem.is_active = item.is_active === 1;
        newItem.email_warmup = item.email_warmup === 1;
        return newItem;
      }))
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.log('Error fetching data:', error);
    });
  }

  const deleteMailbox = (id: number) => {
    axiosService.api.delete('/api/mailboxes/delete/' + id)
    .then((response:any) => {
      showToast({
        severity: 'success',
        summary: 'Success!',
        detail: response.data.message,
      });
      getData();
    })
    .catch((error:any) => {
      console.error('Error fetching data:', error);
    });
  }

  const confirmMailboxDelete = (id: number) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteMailbox(id),
      tagKey: 'mailbox'
    });
  };

  const [showSmtpModal, setShowSmtpModal] = useState(false);
  const [smtpModalData, setSmtpModalData] = useState<any>();
  const actionsClicked = (data: any, type: string) => {
    console.log(data, type);
    if(type==='trash'){
      confirmMailboxDelete(data);
    } else if(type==='view') {
      navigate(`/main?page=emails&mailbox_id=${data}`);
    } else if(type==='edit') {
      setLoadingState(true);
      axiosService.api.get('/api/mailboxes/'+data)
      .then((response:any) => {
        setLoadingState(false);

        if(response.data.type == "smtp"){
          setModalVisible(false);
          setShowSmtpModal(true);
          setSmtpModalData(response.data);
        } else {
          showToast({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'This type of mailbox is not available for edit, consider updating by adding your mailbox again.',
          }); 
        }
      })
      .catch((error:any) => {
        setLoadingState(false);
        console.log('Error fetching data:', error);
      });

    }
  }


  useEffect(() => {
    getData();
  }, []);

  return(
  <>
    <div id="mailbox" className="flex flex-col gap-3">
      <div className="title flex justify-between items-center">
        <h1 className="text-2xl">Mailbox</h1>
        <Button label="Add Email Adress" icon="pi pi-plus" onClick={() => setModalVisible(true)} className="bg-purple rounded-lg border-purple ring-0" />
      </div>

      <div className="panel bg-white rounded-lg p-5">
        <Table data={mailboxData} columns={mailboxColumn} 
          action_types={{delete: true, edit: true, view: true}} actions={true} actionsClicked={actionsClicked}
        />

      </div>

      <MailboxModal visible={modalVisible} showSmtpModal={showSmtpModal} setShowSmtpModal={setShowSmtpModal} smtpModalData={smtpModalData} onHide={handleModalOnHide} onCallback={handleModalCallback} />
    </div>
    <ConfirmDialog tagKey="mailbox" />
  </>
  )
}

export default Mailbox;