import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../contexts/AxiosContext';
import Cookies from 'js-cookie';

const Callback: React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const type = queryParams.get('type');

  const targetOrigin = window.location.origin;

  useEffect(() => {
    if (code && type) {
      setLoadingState(false);
        axiosService.api.get(`/api/linked-account/${type}/callback?code=${code}`)
        .then((response: any) => {
          // setActiveUrl(response.data);
          setLoadingState(false);
          if (window.opener) {
            window.opener.postMessage({
              type: type,
              response: response.data
            }, targetOrigin);

            window.close();
          }
        })
        .catch((error:any) => {
          console.log(error);
          window.close();
        });
    }
  }, [code, type]);

  useEffect(() => {
    if (code && !type) {
      setLoadingState(false);
        axiosService.api.get(`/api/auth/office365/callback?code=${code}&code_verifier=${Cookies.get('code_verifier')}`)
        .then((response: any) => {
          // setActiveUrl(response.data);
          setLoadingState(false); 
          if (window.opener) {
            window.opener.postMessage({
              type: type,
              response: response.data
            }, targetOrigin);

            window.close();
          }
        });
    }
  }, [code]);

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default Callback;
