import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useAxios } from "../../../contexts/AxiosContext"
import { useNavigate } from "react-router-dom"

interface Props {
  visible: boolean
  onHide?: any
  type?: string
  data?: any
  setData?: any
  onSubmit?: any
  onCallback?: any
  resendLink?: any
  isResending?: any
}

const MainpageModal: React.FC<Props> = ({
  visible = false, onHide, type, data, setData, onSubmit, onCallback, resendLink, isResending
}) => {
  const axiosService = useAxios();
  const navigate = useNavigate();

  const checkAction = () => {
    axiosService.api.get('/api/payment-method/billing-history').then((response1:any) => {
      // console.log("response1::", response1);
      if(response1.data.length == 0){
        navigate('/subscription');
      }else{
        axiosService.api.get('/api/agents/count')
        .then((response:any) => {
          if(response.data.count > 0){
            onHide();
          }else{
            navigate('/onboarding');
          }
        });
      }
    });
  };

  return (
  <>
    <Dialog className="lg:w-1/3 w-[95%] text-center" header="" closable={false} draggable={false} resizable={false} visible={visible} onHide={() => onHide()}>
      <div className="text-center flex flex-col gap-3 justify-center">
        <div className="flex justify-center">
          <img src="/images/logo-small.png" alt="Logo Small Colored" width={60}/>
        </div>
        <p className="text-3xl font-bold">Please verify your email</p>
        <p className="text-md px-5">Please click the verification link sent to your email to verify your email address and access our services.</p>
        <h5>Verification Link was sent to this email:</h5>
        <b>{data.email}</b>
        <div className="flex flex-row gap-3 justify-center">
          <div>
            <Button
              onClick={resendLink}
              label="Resend Verification Link"
              iconPos="right"
              icon={"pi " + (isResending ? "pi-spin pi-spinner" : "pi-send")}
              className="bg-purple rounded-lg border-purple"
              disabled={isResending ? true : false}
            />
          </div>
          <div>
            {onHide ? (
              <Button
                onClick={checkAction}
                label="Continue"
                iconPos="right"
                icon="pi pi-arrow-right"
                className="bg-purple rounded-lg border-purple"
              />
            ) : (
              null
            )}
          </div>
        </div>
      </div>
    </Dialog>
  </>
  );
}

export default MainpageModal;