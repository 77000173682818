import { Button } from "primereact/button";
import Inputs from "../../components/forms/Inputs";
import { useEffect, useState } from "react";
import { useAxios } from "../../contexts/AxiosContext";
import { InputHandler } from "../../services/EventHandlers";
import IconMap from "../../components/Icons/IconMaps";
import { Logo } from "../../components/Icons/ImgPath";
import { useToast } from "../../contexts/ToastContext";

// BsIncognito

const SuperAdminRegistration:React.FC<any> = ({ setLoadingState }) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [finished, setFinished] = useState(false);
  const [message, setMessage] = useState("");
  const [application, setApplication] = useState<any>({
    firstname: '',
    lastname: '',
    email: '',
    phone: ''
  });
  const applyRegistration = () => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    for (const key in application) {
      if (application.hasOwnProperty(key)) {
        if (application[key].length === 0) {
          showToast({
            severity: 'error',
            summary: 'Missing Fields!',
            detail: `${key} is empty`
          });
          return;
        }
  
        if (key === 'email' && !emailRegex.test(application[key])) {
          showToast({
            severity: 'error',
            summary: 'Invalid Email!',
            detail: 'Email format is incorrect'
          });
          return;
        }
      }
    }
  
    axiosService.api.post('/api/superadmin/application', application)
      .then((appData: any) => {
        console.log("appData::", appData);
        if (appData.data.status) {
          setFinished(true);
          setMessage(appData.data.message);
        }
      }).catch((error:any) => {
        setLoadingState(false);
        const errorMessage = error.response.data.message;
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: errorMessage
        });
      });
  };
  const handlePhoneChange = (newValue: any) => {
    setApplication((prevValues:any) => ({
      ...prevValues,
      phone: newValue
    }));
  };
  useEffect(() => {
    setLoadingState(false);
  }, []);
  return (
    <>
      <div className="flex flex-col h-[100vh] justify-center items-center bg-[#dadafc]">
        <img alt="Logo" src={Logo} className="mb-5" width={300}/>
        <div className="flex flex-col w-[30%] py-7 px-4 md:px-7 z-1 bg-[#fff] border-1 border-[#fff] rounded-md">
          <div className="header mb-4">
            <h3 className="text-xl font-bold mb-2 flex">{IconMap('BsIncognito','mr-2',undefined,32)}Super Admin Registration</h3>
          </div>
          <div className="flex flex-col">
            {!finished ?
            <>
              <div>
                <Inputs className={'w-full'} value={application.firstname} label="Firstname" type={"text"} onChange={InputHandler(setApplication, 'firstname')} required invalid  withValidation />
              </div>
              <div>
                <Inputs className={'w-full'} value={application.lastname} label="Lastname" type={"text"} onChange={InputHandler(setApplication, 'lastname')} required invalid  withValidation />
              </div>
              <div>
                <Inputs className={'w-full'} value={application.email} label="Email" type={"email"} onChange={InputHandler(setApplication, 'email')} required invalid  withValidation />
              </div>
              <div>
                <Inputs className={'w-full'} label={'Phone Number'} type={'phone'} value={application.phone} onChange={(e:any)=>handlePhoneChange(e)} required invalid  withValidation />
              </div>
              <div className="mt-5">
                <Button className="w-full bg-purple border-purple rounded-[6px]" label="Apply For Registration" onClick={applyRegistration} />
              </div>
            </> : 
            <>
              <div>
                <p>{message}</p>
              </div>
            </>}
          </div>
          
        </div>
      </div>
    </>
  );
};

export default SuperAdminRegistration;