import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ResetPassword:React.FC<any> = ({setLoadingState}) => {
  const location = useLocation();

  const { headerText = "Email Sent", bodyText = "Please check your email and click the reset link to reset your password. Thank you" } = location.state || {};

  useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 300);
  }, []);

  return (
    <>
      <div className="flex  justify-center h-[100vh] overflow-hidden">
        <div className="flex flex-col justify-center items-center ">
          <h1 className="text-[50px] text-[#888] inline-block pr-[12px] animate-type">{headerText}</h1>
          <p>{bodyText}</p>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;