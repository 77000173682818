import React, { useState,useEffect } from "react";
import Inputs from "../../../components/forms/Inputs";
// import { LogoSmall, LogoWhite } from "../../../components/Icons/ImgPath";
import IconMap from "../../../components/Icons/IconMaps";
// import AxiosService from "../../../services/AxiosService";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import { InputHandler } from "../../../services/EventHandlers";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from "primereact/tooltip";
import { useAxios } from "../../../contexts/AxiosContext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
// import { Chips } from "primereact/chips";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { AgentProps } from "../../../interface/datatypes";

// interface BusinessProfileData {
//   company_name: string;
//   business_description: string;
//   business_targets: string;
//   website: string;
// }
// interface SuggestionResponse {
//   suggestion: string;
// }      

const BusinessStep: React.FC<any> = ({checkKeys, setEntInfo, entInfo, setLoadingState, setIsGenerated, defaultPrompt,closeOnboarding,countryOptions,agentOptions,checkValue}) => {
  const axiosService = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues]=useState(entInfo.suggested_sales_profile);
  const [miscLinks, setMiscLinks] = useState<any[]>([{
    description:"",
    link:""
  }]);

  const [languages, setLanguages] = useState([
    {name: "English", value: "English"},
    {name: "Spanish", value: "Spanish"},
    {name: "French", value: "French"},
    {name: "German", value: "German"},
    {name: "Chinese (Simplified)", value: "Chinese (Simplified)"},
    {name: "Chinese (Traditional)", value: "Chinese (Traditional)"},
    {name: "Japanese", value: "Japanese"},
    {name: "Korean", value: "Korean"},
    {name: "Russian", value: "Russian"},
    {name: "Portuguese", value: "Portuguese"},
    {name: "Italian", value: "Italian"},
    {name: "Dutch", value: "Dutch"},
    {name: "Arabic", value: "Arabic"},
    {name: "Hindi", value: "Hindi"},
    {name: "Bengali", value: "Bengali"},
    {name: "Punjabi", value: "Punjabi"},
    {name: "Turkish", value: "Turkish"},
    {name: "Vietnamese", value: "Vietnamese"},
    {name: "Thai", value: "Thai"},
  ]);


  const handleGenerating = (event:any)=>{
    // const data_to_send: BusinessProfileData = {
    //   company_name: entInfo.company_name,
    //   business_description: entInfo.description,
    //   business_targets: entInfo.target_description, 
    //   website: "https://" + entInfo.website
    // };
    const data_to_send: any = {
      company_name: entInfo.company_name,
      description: entInfo.description,
      target_description: entInfo.target_description, 
      target_language: entInfo.target_language,
      website: "https://" + entInfo.website,
      fullname: entInfo.fullname,
      email: entInfo.email,
      phone: entInfo.phone,
      role: entInfo.role,
      calendly_link: "https://" + entInfo.calendly_link,
      linkedin_url: "https://" + entInfo.linkedin_url,
      miscellaneous_links: entInfo.miscellaneous_links,
    };

    let sampleCopy = JSON.parse(JSON.stringify(data_to_send));

    Object.keys(sampleCopy).forEach(key => {
      if(sampleCopy[key] == null || sampleCopy[key] == ""){
        delete(sampleCopy[key]);
      }
    });

    // console.log("sampleCopy::", sampleCopy);

    let suggestionDetails = {
      details: sampleCopy,
      prompts: {
        suggested_sales_profile: defaultPrompt.suggested_sales_profile.map((k:any) => (k.value)),
        suggested_pitch: defaultPrompt.suggested_pitch.map((k:any) => (k.value)),
        suggested_keywords: defaultPrompt.suggested_keywords.map((k:any) => (k.value))
      },
      selected_keys: defaultPrompt.include_keys
    };

    // console.log("suggestionDetails::", suggestionDetails);

    setIsLoading(true)
    axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_sales_profile', suggestionDetails)
      .then((profileResponse:any) => {
        // console.log("profileResponse::", profileResponse);
        setValues(profileResponse.data.suggestion);
        setEntInfo((prevValues: AgentProps) => ({
          ...prevValues,
          suggested_sales_profile:profileResponse.data.suggestion
        }));
        setIsLoading(false);
        setIsGenerated(true);
      });

    return 0;
  
    // axiosService.api.post('/api/ai_suggest/business_profile', data_to_send)
    //   .then((response:any) => {
        // setValues(response.data.suggestion);
        // setEntInfo((prevValues: any) => ({
        //   ...prevValues,
        //   suggested_sales_profile:response.data.suggestion
        // }));
        // setIsLoading(false);
        // setIsGenerated(true);
    //   }).catch((error:any) => {
    //     setIsLoading(false);
    //     setIsGenerated(false);
    //   });
  }
  const findCountry = (name: any) => {
    const country = countryOptions.find((country: any) => country.name === name);
    return country ? [country.value] : [];
  };
  const addMiscLink = () => {
    const newLink = { description: "", link: "" };
    setMiscLinks([...miscLinks, newLink]);
  };

  const removeMiscLink = (index: number) => {
    const newMiscLinks = miscLinks.filter((_, i) => i !== index);
    setMiscLinks(newMiscLinks);
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      miscellaneous_links: prevValues.miscellaneous_links ? prevValues.miscellaneous_links.filter((_: any, i: any) => i !== index) : [],
    }));
  };

  const handleInputMiscLinks = (index:any, field:any) => (e:any) => {
    const newMiscLinks = miscLinks.slice();
    newMiscLinks[index][field] = e.target.value;
    setMiscLinks(newMiscLinks);
  };

  useEffect(() => {
    const validLinks = miscLinks.filter(link => link.description && link.link);

    setEntInfo((prev:AgentProps) => ({
      ...prev,
      miscellaneous_links: validLinks,
    }));
  }, [miscLinks, setEntInfo]);

  const handlePhoneChange = (newValue: any) => {
    setEntInfo((prevValues:AgentProps) => ({
      ...prevValues,
      phone: newValue
    }));
  };

  // const handleHttps = (e: any, set: any, key: any) => {
  //   e.target.value = e.target.value.replace(/^https?:\/\//, '');
  //   InputHandler(set, key)(e);
  // };

  const handleHttps = (e: any, set: any, key: any) => {
    let value = e.target.value;
    if (value.startsWith('https://')) {
      value = `https://${value.replace(/https?:\/\//g, '')}`;
    } else {
      value = value.replace(/https?:\/\//g, '');
    }
  
    e.target.value = value;
    InputHandler(set, key)(e);
  };

  const handlDefaultAgent = async (agentValue:any)=>{
    setLoadingState(true);

    const [userDataResponse, agentResponse,userCompanyResponse] = await Promise.all([
      axiosService.api.get('/api/user/current'),
      axiosService.api.get('/api/agents/get/' + agentValue.value),
      axiosService.api.get('/api/company/get'),
    ]);

    const userData = userDataResponse.data || {};
    const agentData = agentResponse.data || {};
    const userCompanyData = userCompanyResponse.data.company || {};

    console.log('agentData.target_language::: ', agentData.target_language)
    
    setEntInfo((prevState:AgentProps) => ({
      ...prevState,
      website: checkValue(agentData.website) || checkValue(userCompanyData.website) || '',
      company_name: checkValue(agentData.company_name) || checkValue(userCompanyData.company_name) || '',
      description: checkValue(agentData.description) || checkValue(userCompanyData.business_description) || '',
      agent_name: checkValue(agentData.name) || '',
      target_description: checkValue(agentData.target_description) || checkValue(userCompanyData.target_description) || '',
      target_keywords: checkValue(agentData.target_keywords) || [],
      target_language: checkValue(agentData.target_language) || 'English',
      target_countries: checkValue(findCountry(agentData.target_countries[0])) || [],
      offers: checkValue(agentData.offers) || '',
      email: checkValue(agentData.email) || checkValue(userData.email) || '',
      phone: checkValue(agentData.phone) || checkValue(userData.phone) || '',
      full_name: checkValue(agentData.name) || checkValue(userData.name) || '',
      calendly_link:checkValue(agentData.calendly_link) || checkValue(userCompanyData.calendly_link) || '',
      role: checkValue(agentData.role) || checkValue(userCompanyData.role) ||  '',
      linkedin_url: checkValue(agentData.linkedin_url) || checkValue(userCompanyData.linkedin_url) || '',
      credits: checkValue(agentData.credits) || 0,
      ai_personality_id: checkValue(agentData.ai_personality_id) || 0,
      miscellaneous_links: checkValue(agentData.miscellaneous_links) || [],
      existing_agent:agentValue.value
    }));
    setLoadingState(false);
  }

  useEffect(() => {
    document.getElementById('top-business')?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // useEffect(() => {
  //   console.log('entInfo :: ', entInfo)

  // },[entInfo]);

  return (
    <div className="flex flex-col" id="top-business">
      <div className="w-full bg-[#fff] p-[20px] border-r border-[#ddd]" id="form-section">
        <div className="flex flex-row justify-between w-full">
          <h2 className="text-[24px] font-[700] mb-[.5rem]">Your Company Information</h2>
          <div className="flex flex-row  items-center hover:cursor-pointer icon-container group" onClick={()=>{closeOnboarding()}}>
            <span className="text-[16px] text-red-600 font-[600]">Exit Onboarding </span>
            {IconMap('FaAnglesRight', 'ml-2 transition transform duration-300 ease group-hover:translate-x-[5px] text-red-600',undefined,16)} 
          </div>
        </div>
        <p className="text-[14px]">Please fill in the details of your company below. Providing comprehensive and accurate information helps in generating a precise AI profile. </p>
        <div className="mt-[2rem] grid lg:grid-cols-3 gap-5">
          <Inputs missingField={checkKeys.includes('agent_name')} label={<>Campaign Name? {IconMap('FaQuestionCircle','agent-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".agent-name" content={"Your agent name helps you identify what the business information is for"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.agent_name} placeholder={'Example Campaign Name'} onChange={(e:any) => InputHandler(setEntInfo, 'agent_name')(e)} className=""/>
          <div></div>
          <Inputs label={<>Exisitng Campaigns {IconMap('FaQuestionCircle','existing-agents ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".existing-agents" content={"Please select an existing agents you want to copy the business profile from"} /></>} labelClass={'text-[16px] font-[500] mb-2'} type={'dropdown'} value={entInfo.existing_agent} options={agentOptions} optionLabel={'label'} onChange={(e:any)=>handlDefaultAgent(e)} className="" />
        </div>
          
        <div className="mt-[2rem] grid lg:grid-cols-3 gap-5">
          <Inputs label={<>What is your website address? {IconMap('FaQuestionCircle','business-site ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-site" content={"Your company's website link"}/></>} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.website} placeholder={'example.com'} onChange={(e:any) => handleHttps(e, setEntInfo, 'website')} className="" />
          <Inputs missingField={checkKeys.includes('company_name')} label={<>What is your Company Name? {IconMap('FaQuestionCircle','business-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-name" content={"The company name you represent as part of the signature/footer of the outgoing emails"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.company_name} placeholder={'Example Company Name'} onChange={(e:any) => InputHandler(setEntInfo, 'company_name')(e)} className=""/>
          <Inputs label={<>Your Full Name {IconMap('FaQuestionCircle','business-fullname ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-fullname" content={"The name you want to use for signing off the e-mail you will send to your leads"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.full_name} placeholder={'Full Name'} onChange={(e:any) => InputHandler(setEntInfo, 'full_name')(e)} className=""/>
          <Inputs label={<>Email Address {IconMap('FaQuestionCircle','business-emailaddress ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-emailaddress" content={"The Email address as part of the signature/footer of the outgoing emails"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'email'} value={entInfo.email} placeholder={'example@email.com'} onChange={(e:any) => InputHandler(setEntInfo, 'email')(e)} className=""/>
          <Inputs label={<>Meeting Calendar Link (Calendly, Hubspot, etc.) (Optional){IconMap('FaQuestionCircle','business-calendlylink ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-calendlylink" content={"A meeting link using Calendly to add as part of the e-mail generation"}/></>} grouped={true} prefix={'https://'}  labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.calendly_link} placeholder={'calendly.com/username'} onChange={(e:any) => handleHttps(e, setEntInfo, 'calendly_link')} className=""/>
          <Inputs label={<>Phone Number (Optional){IconMap('FaQuestionCircle','business-phone ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-phone" content={"Your Phone Number your leads can contact you on"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'phone'} value={entInfo.phone} onChange={(e:any)=>handlePhoneChange(e)} invalid={true} withValidation={false} />
          <Inputs label={<>Role (Optional){IconMap('FaQuestionCircle','business-role ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-role" content={"Your role in the organization reaching out to the leads"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.role} placeholder={'Your Role'} onChange={(e:any) => InputHandler(setEntInfo, 'role')(e)} className=""/>
          <Inputs label={<>LinkedIn URL (Optional){IconMap('FaQuestionCircle','business-linkedin ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-linkedin" content={"Your LinkedIn Profile to be added as part of the outgoing email and/or signature"}/></>} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.linkedin_url} placeholder={'www.linkedin.com/in/username'} onChange={(e:any) => handleHttps(e, setEntInfo, 'linkedin_url')} className=""/>
          <Inputs label={<>What language should we use? {IconMap('FaQuestionCircle','client-language ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-language" content={"Specify the language you need to use"} /></>} labelClass={'text-[16px] font-[500] mb-2'} type={'dropdown'} value={entInfo.target_language} options={languages} optionLabel={'value'} onChange={(e:any) => InputHandler(setEntInfo, 'target_language')(e)} className="" />

        </div>
        <Inputs label={<>Please provide a description of your business {IconMap('FaQuestionCircle','business-desc ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-desc" content={"What is your business for?"}/> </>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={entInfo.description} onChange={(e:any) => InputHandler(setEntInfo, 'description')(e)} className={"rounded-[8px] md:col-span-2 mt-4 mb-8"} />
        <Inputs label={<>Please provide a description of your target client, market and product {IconMap('FaQuestionCircle','business-target ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-target" content={"Define your ideal customer, target market, and product offering"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={entInfo.target_description} onChange={(e:any) => InputHandler(setEntInfo, 'target_description')(e)} className={"rounded-[8px] md:col-span-2 mb-8"} />
        
        <label className="text-label flex flex-row items-center">
          Miscellaneous Links (Optional)
          {IconMap('FaQuestionCircle', 'client-keywords ms-1 items-center text-[#585858]', undefined, 16)}
          <Tooltip target=".client-keywords" content={"Add and manage additional links here."} />
        </label>
        <div className="flex flex-col lg:col-span-4 pr-2">
          {miscLinks.map((link, index) => (
            <div key={index} className="w-full flex flex-row justify-center border-gray border rounded-lg pr-4 mt-3 relative">
              <div className={`absolute flex flex-col h-full top-0 right-[-18px] justify-center items-center `}>
                {miscLinks.length > 1 && (
                  <Button className="bg-[#fff] flex z-1" icon="pi pi-minus" rounded outlined severity="danger" aria-label="minus" onClick={() => removeMiscLink(index)}/>
                )}
                {index === miscLinks.length - 1 && (
                  <Button className={`bg-[#fff] z-1 ${(index === miscLinks.length - 1) && miscLinks.length > 1 ? 'mt-1': ''}`} icon="pi pi-plus" rounded outlined severity="secondary" aria-label="plus" onClick={addMiscLink}/>
                )}
              </div>
              <div className="flex grid grid-cols-1 md:grid-cols-2 gap-3 my-2 w-[98%]">
                <div className="mt-2 w-full">
                  <FloatLabel>
                    <InputText id={`link-desc-${index}`} value={link.description} onChange={handleInputMiscLinks(index, 'description')} className="w-full text-[16px]" placeholder="Link Description" />
                    <label htmlFor={`link-desc-${index}`}>Link Description</label>
                  </FloatLabel>
                </div>
                <Inputs label={undefined} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={link.link} className="w-full text-[16px]" placeholder={'example.com'} onChange={handleInputMiscLinks(index, 'link')} />
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-5">
          <Button tooltip={`${isLoading ? 'Please wait':!entInfo.website || !entInfo.company_name || !entInfo.description || !entInfo.target_description || !entInfo.full_name || !entInfo.email || !entInfo.target_language?'All inputs are required to proceed':''}`} tooltipOptions={{ showOnDisabled: true }} disabled={isLoading || !entInfo.website || !entInfo.company_name || !entInfo.description || !entInfo.target_description || !entInfo.full_name || !entInfo.email || !entInfo.target_language} onClick={(e:any)=>handleGenerating(e)} 
            className={`flex items-center mr-2 md:mr-0 mb-2 md:mb-0 py-2 px-3 text-[16px] text-white font-bold leading-6 md:leading-7 rounded-md disabled:opacity-50 ${isLoading? 'bg-[#8B88BC]':'bg-[#3C35A7]'}`}>
            {isLoading? <>Generating AI Profile <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" /></> : <>Generate AI Profile</>}
          </Button>
        </div>

        <div className="">
          <h4 className="">Please see below your A.I. Suggested sales profile</h4>
          <div className={` h-[350px] bg-white border-gray border rounded-lg ${values ? 'flex items-center justify-center' : 'flex flex-col items-center justify-center'}`}>
            {isLoading ? (
              <div className="text-[16px] text-[#3490dc] flex justify-center">
                Generating information <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" />
              </div>
            ) : (
              values !== null && values !== undefined && values !== "" ? (
                <InputTextarea className="w-full h-full rounded-lg border-none overflow-auto" value={entInfo.suggested_sales_profile} onChange={(e:any) => InputHandler(setEntInfo, 'suggested_sales_profile')(e)} />
              ) : (
                <p className="text-[16px] text-[#8D93A1]">Pending information</p>
              )
            )}
          </div>
          <p className="text-[16px] text-[#8D93A1">Feel free to update and adjust and press <strong>Next</strong> to continue</p>
        </div>
      </div>
      {/* <div className="w-full md:w-2/5 bg-[#F9F9F9] p-[20px]" id="preview-section">
       
      </div> */}
    </div>
  );
};

export default BusinessStep;
