import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../contexts/AxiosContext";
import { Logo } from "../../components/Icons/ImgPath";
import { useToast } from "../../contexts/ToastContext";

const VerifyAccount:React.FC<any> = ({setLoadingState}) => {
  const { showToast } = useToast();
  const axiosService = useAxios();
  const navigator = useNavigate();
  const [contentText, setContentText] = useState({
    header: "VERIFYING ACCOUNT",
    body: "You have verfied your account, you will be redirected to the dashboard shortly. Thank you!"
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const uid = params.get("uid");

      setTimeout(() => {
          setLoadingState(false);
        // navigator('/main');
      }, 3000);

    axiosService.api.get(`/api/verify-account/${token}/${uid}`).then((verifyData:any) => {
      setContentText({
        header: 'ACCOUNT VERIFIED',
        body: verifyData.data.message
      });

      setTimeout(() => {
        showToast({ severity: 'success', summary: 'Success!', detail: 'Successfully verified account.', });
      },500);

      setTimeout(() => {
        if(verifyData.data.status){
          navigator('/onboarding');
        }else{
          navigator('/main');
        }
      }, 3000);
    });
    // setTimeout(() => {
    //   setLoadingState(false);
    // }, 300);
  }, []);

  return (
    <>
      <div className="flex flex-col items-center h-[100vh] justify-between overflow-hidden">
        <div className="my-8"> <img className="object-contain" src={Logo} width={175} alt="SectorSift Logo"/></div>
        <div className="flex flex-col justify-center items-center p-5 ">
          <h1 className="text-[50px] text-[#888] inline-block pr-[12px] text-center leading-[1] animate-type">{contentText.header}</h1>
          <p className="text-center mt-2">{contentText.body}</p>
        </div>
        <div className="my-[2rem]">
          <p className="text-[13px]">Make a suggestion or contact us at <a className="text-[#8D2CFE] hover:underline" href="mailto:support@sectorsift.com"> support@sectorsift.com</a></p>
        </div>
      </div>
    </>
  );
}

export default VerifyAccount;