import { useEffect, useState } from "react";
// import { Button } from "primereact/button";
import { useAxios } from "../../contexts/AxiosContext";
import { useParams } from "react-router-dom";
import { Logo } from "../../components/Icons/ImgPath";


const AutoLogin:React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();

  const { id } = useParams();

  const [error, setError] = useState(false);
  
  useEffect(() => {    
    axiosService.api.post('/api/autologinyvpjzepdwy', { id } )    
    .then((res: any) => {
      sessionStorage.autologinr09nalqj4p = id;
      
      setLoadingState(false);
      if( !res.data.is_valid ){
        setError( true );
        return;
      }
      
      window.location.href='/main?page=dashboard';  
    }).catch((error:any) => {
      setLoadingState(false);
      setError( true );
    });
  }, []);
  

  
  return (
    <>
      <div className="flex flex-col gap-5 justify-center items-center min-h-screen w-screen text-sm p-4 bg-[#F0EFFF]">
        <img alt="Logo" src={Logo} className="mb-5" width={300}/>
        <div className="w-full xl:w-1/3 lg:w-1/2 rounded-[56px] min-h-[400px] p-1" style={{background: 'linear-gradient(180deg, rgb(42, 34, 152) 10%, rgba(33, 150, 243, 0) 30%)'}}>
          <div className="rounded-[53px] bg-white border-5 border-transparent w-full h-full lg:p-[5rem] py-[5rem] px-[2rem]">
            <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
              <p className="text-3xl text-center font-bold">Getting In</p>
            </div>
            
            {
              !error && <>
              
              <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
                <p className="text-1xl text-center font-bold">Please Wait ...</p>
              </div>          
              
              </>
            }
            
            {
              error && <>
              
              <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
                <p className="text-1xl text-center font-bold p-error">Sorry you are not allowed to get in.</p>
                <p className="text-1xl text-center font-bold p-error"><a href="/">To go back click here.</a></p>
              </div>        
              
              </>
            }
            
          </div>
        </div>
      </div>
    </>
  );
}

export default AutoLogin;
