import React, { createContext, useContext, useCallback } from 'react';
import { useAxios } from './AxiosContext';
import { useToast } from './ToastContext';

interface SubscriptionRestrictionsContextProps {
  fetchRestrictions: (type: string, callback: () => void, errorCallback: () => void) => void;
}

const SubscriptionRestrictionsContext = createContext<SubscriptionRestrictionsContextProps | undefined>(undefined);

export const SubscriptionRestrictionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const axiosService = useAxios();
  const { showToast } = useToast();

  const fetchRestrictions = useCallback((type: string, callback: () => void, errorCallback: () => void ) => {
    axiosService.api.get(`/api/subscription/restrictions?type=${type}`)
    .then((response: any) => {
      console.log(response.data);
      if(response.data.restricted){
        showToast({
          severity: 'error',
          summary: 'Failed!',
          detail: response.data.message
        });
        errorCallback();
      } else {
        callback();
      }
    })
    .catch((error: any) => {
      errorCallback();
    });
  }, [axiosService]);


  return (
    <SubscriptionRestrictionsContext.Provider value={{ fetchRestrictions }}>
      {children}
    </SubscriptionRestrictionsContext.Provider>
  );
};

export const useSubscriptionRestrictions = (): SubscriptionRestrictionsContextProps => {
  const context = useContext(SubscriptionRestrictionsContext);
  if (!context) {
    throw new Error('useSubscriptionRestrictions must be used within a SubscriptionRestrictionsProvider');
  }
  return context;
};