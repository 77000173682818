import { useLocation, useNavigate } from 'react-router-dom';
import IconMap from '../Icons/IconMaps';
import React, { useState,useEffect } from 'react';
import { Skeleton } from 'primereact/skeleton';


// type SidebarProps = {
//   onToggleSidebar: (collapsed: boolean) => void;
// };

const Sidebar: React.FC<any> = ({ onToggleSidebar, sidebarShow, setSidebarShow, credInfo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const currentPage = new URLSearchParams(location.search).get("page") || "";

  // const modules: any[] = [
  //   { label: 'Dashboard', page: 'dashboard', icon: 'VscDashboard' },
  //   { label: 'Leads', page: 'leads', icon: 'CiFilter' },
  //   { label: 'Campaigns', page: 'campaigns', icon: 'PiUsersThreeLight', class:'agents-nav' },
  //   { label: 'Reporting', page: 'reporting', icon: 'GrSend'},
  //   { label: 'Mails', page: 'mails', icon: 'IoMailOutline',
  //     submenus: [ 
  //       { label: 'Emails', page: 'emails', icon: 'MdOutlineAlternateEmail' },
  //       { label: 'Mailbox', page: 'mailbox', icon: 'TbMailCog' },
  //     ]
  //   },
  //   { label: 'Settings', page: 'settings', icon: 'HiOutlineCog6Tooth',
  //     submenus: [ 
  //       { label: 'Personal Profile', page: 'settings/profile', icon: 'FaUserEdit' },
  //       { label: 'Company Profile', page: 'settings/company', icon: 'FaUserCog' },
  //       { label: 'Team Members', page: 'settings/team-members', icon: 'FaUsersGear' },
  //       { label: 'Notifications', page: 'settings/notifications', icon: 'TbMailCog' },
  //       { label: 'Email Blueprints', page: 'settings/email-blueprints', icon: 'FaUserEdit' },
  //       { label: 'CRM', page: 'settings/crm', icon: 'MdOutlineAccountTree' },
  //       // { label: 'AI Settings', page: 'view-profile', icon: 'MdAutoAwesome' },
  //       // { label: 'Billing', page: 'view-profile', icon: 'FaMoneyBillWave' },
  //     ]
  //   },
  // ];

  const [pages, setPages] = useState<any>([]);

  const [collapsed, setCollapsed] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState<{ [key: string]: boolean }>({});

  const navigateToPage = (page: string) => {
    navigate(`/main?page=${page}`);
  };

  const toggleSidebar = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    onToggleSidebar(newCollapsedState); 
    if(!collapsed){
      setSidebarShow(false);
    }
    // setSidebarShow(newCollapsedState);
  };

  const renderMenuItems = (items: any[]) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
        <li
          onClick={() => {
            if (item.submenus && item.submenus.length > 0) {
              // Toggle submenu visibility if it exists
              setSubMenuOpen({ ...subMenuOpen, [item.label]: !subMenuOpen[item.label] });
            } else {
              navigateToPage(item.page);
            }
          }}
          className={`${currentPage === item.page ? 'bg-[#397BFF] border-[#4FCCEA] text-white border-l-[2px]' : ''}
              p-4 cursor-pointer flex gap-2 items-center
              transition transition-duration-300 ease-in 
              hover:border-l-[2px] hover:text-white hover:border-[#4FCCEA] hover:bg-[#397BFF] ${item.class ? item.class :null}
              ${item.label === 'Campaigns' ? 'campaign-nav' : ''}`}
        >
          <div className='flex flex-row items-center justify-between w-full'>
            <div className='flex flex-row items-center'>{IconMap(item.icon,'mr-2')} <span className=''>{collapsed ? null : item.label}</span></div>
            
            {item.submenus && item.submenus.length > 0 && !collapsed? 
              ( IconMap('FaChevronRight',` duration-700 ${ subMenuOpen[item.label]?'rotate-90':null}`,undefined, 14) )
              : null }
          </div>
        </li>
        {item.submenus && item.submenus.length > 0 && subMenuOpen[item.label]  && !collapsed && (
          <ul className='pl-4'>
            {renderMenuItems(item.submenus)}
          </ul>
        )}
      </React.Fragment>
    ));
  };

  const renderIcons = (iconName: string) => {
    let icon = "";
    switch(iconName) {
      case "dashboard":                     icon = "VscDashboard"; break;
      case "leads":                         icon = "CiFilter"; break;
      case "campaigns":                     icon = "PiUsersThreeLight"; break;
      case "reporting":                     icon = "GrSend"; break;
      case "mails":                         icon = "IoMailOutline"; break;
      case "emails":                        icon = "MdOutlineAlternateEmail"; break;
      case "mailbox":                       icon = "TbMailCog"; break;
      case "settings":                      icon = "HiOutlineCog6Tooth"; break;
      case "settings/profile":              icon = "FaUserEdit"; break;
      case "settings/company":              icon = "FaUserCog"; break;
      case "settings/team-members":         icon = "FaUsersGear"; break;
      case "settings/notifications":        icon = "TbMailCog"; break;
      case "settings/crm":                  icon = "MdOutlineAccountTree"; break;
      case "settings/billing":              icon = "LiaMoneyBillWaveSolid"; break;
      case "settings/roles":                icon = "FaUserLock"; break;
      case "settings/email-blueprints":     icon = "GrTemplate"; break;
      default:
      break;
    }
    return icon;
  }

  const constructPages = (user: any) => {
    let userInfo = user.accessing_user || user;
    const roleAccess = (userInfo.accessed_pages || []).map((r:any) => ({
      label: r.name,
      page: r.extension,
      icon: renderIcons(r.extension || r.name.toLowerCase()),
      submenus: (r.childrens || []).map((c:any) => ({
        label: c.name,
        page: c.extension,
        icon: renderIcons(c.extension || c.name.toLowerCase()),
      }))
    }));
    setPages(roleAccess);
  }

  useEffect(() => {
    constructPages(credInfo);
    console.log('pages ::: ', pages)
  }, [credInfo])

  return (
    <div id="Sidebar" className='w-full'>
      <ul>
        {
          (!pages || pages.length < 1) && (
          <>
            <li className='px-4 py-3 cursor-pointer hidden sm:flex gap-2 items-center'>
              <Skeleton height="2rem"></Skeleton>
            </li>
            <li className='px-4 py-3 cursor-pointer hidden sm:flex gap-2 items-center'>
              <Skeleton height="2rem"></Skeleton>
            </li>
            <li className='px-4 py-3 cursor-pointer hidden sm:flex gap-2 items-center'>
              <Skeleton height="2rem"></Skeleton>
            </li>
            <li className='px-4 py-3 cursor-pointer hidden sm:flex gap-2 items-center'>
              <Skeleton height="2rem"></Skeleton>
            </li>
            <li className='px-4 py-3 cursor-pointer hidden sm:flex gap-2 items-center'>
              <Skeleton height="2rem"></Skeleton>
            </li>
            <li className='px-4 py-3 cursor-pointer hidden sm:flex gap-2 items-center'>
              <Skeleton height="2rem"></Skeleton>
            </li>
          </>
          )
        }
        {renderMenuItems(pages)}
        <li
          className='p-4 cursor-pointer hidden sm:flex gap-2 items-center
            transition transition-duration-300 ease-in 
            border-t border-t-1 border-[rgba(255,255,255,0.1)] border-solid
            hover:border-[#4FCCEA] hover:text-white 
            hover:border-[#4FCCEA] hover:bg-[#397BFF]'
          onClick={toggleSidebar}
        >
          {collapsed ? IconMap('RiIndentIncrease') : IconMap('RiIndentDecrease')} {collapsed ? null : ''}
        </li>
      </ul>
    </div>
  );
};

export default React.memo(Sidebar);
