import React, { useState,useEffect } from "react";
import IconMap from "../components/Icons/IconMaps";
import { useAxios } from "../contexts/AxiosContext";
import Navbar from "../components/navigations/Navbar";
import Inputs from "../components/forms/Inputs";
import { useToast } from "../contexts/ToastContext";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

const Subscription: React.FC<any> = ({setLoadingState}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [subId] = useState<any>(queryParams.get('subId'));
  const [visible, setVisible] = useState(false);
  const {showToast} = useToast();
  const axiosService = useAxios();
  const [activeSubs, setActiveSubs] = useState('monthly');
  const [couponCode, setCouponCode] = useState<any>('');
  // const [pageData, setPageData] = useState<any>({
  //   prices: [],
  //   products: []
  // });
  const [productCodes, setProductCodes] = useState<any>([]);

  let [subscriptionData, setSubscriptionData] = useState<any>([
    // {
    //   title: "Free",
    //   price: 0,
    //   description:"Ideal to try out SectorSift sales pack.",
    //   features: [
    //     "25 credits",
    //     "1 Search Agent",
    //     "No Automatic Sending",
    //   ],
    //   api: 'prod_Qd12mrjizSfHzo',
    //   btn_text:"Join Free",
    //   type: 'once'
    // },
    {
      title: "Start-up",
      price: 112.50,
      description:"All the core tools needed to set up steady sales.",
      features: [
        "250 Credits",
        "3 Search Agents",
        "Auto Send",
      ],
      api: 'prod_Qczlo6ScF7iimx',
      btn_text:"Get Started",
      type: 'month'
    },
    {
      title: "Business",
      price: 199.00,
      description:"Business choice for incredible sales and business opportunities.",
      features: [
        "500 credits",
        "10 Search Campaigns",
        "Auto Send and Follow-up",
        "1 Custom Campaign",
        "LinkedIn Profile Deepdive",
      ],
      api: 'prod_QczmZ79wiYj4uF',
      btn_text:"Get Started",
      type: 'month'
    },
    {
      title: "Business Plus",
      price: 750.00,
      description:"Email & LinkedIn outreach set and managed for you.",
      features: [
        "2500 credits",
        "50 Campaigns",
        "Auto Send and Follow-up",
        "Lead Monitoring",
        "5 Custom Campaigns",
        "LinkedIn Profile Deepdive",
        "Smart Team Management"
      ],
      api: 'prod_QczorBo2JtRH6P',
      btn_text:"Get Started",
      type: 'month'
    },
    {
      title: "Enterprise",
      price: null,
      description:"We can customize your package to suit your needs.",
      features: [
        "More customized campaigns?",
        "Very specific needs?",
        "B-Spoke Integrations?",
        "More Credits?",
        "Dedicated Support Agent?",
      ],
      api: null,
      btn_text:"Contact Us!",
      type: null,
    },
  ]);

  const annualSubscriptionData:any = [
    {
      title: "Start-up Annual",
      price: 1080.00,
      description:"All the core tools needed to set up steady sales.",
      features: [
        "3000 Credits",
        "3 Search Campaigns",
        "Auto Send",
      ],
      api: 'prod_Qczp9FwinxGEax',
      btn_text:"Get Started",
      type: 'year',
    },
    {
      title: "Business Annual",
      price: 1920.00,
      description:"Business choice for incredible sales and business opportunities.",
      features: [
        "6000 credits",
        "10 Search Campaigns",
        "Auto Send and Follow-up",
        "1 Custom Campaigns",
      ],
      api: 'prod_Qczq52Yi8jo9Sm',
      btn_text:"Get Started",
      type: 'year',
    },
    {
      title: "Business Plus Annual",
      price: 7200.00,
      description:"Email & LinkedIn outreach set and managed for you.",
      features: [
        "30000 credits",
        "50 Campaigns",
        "Auto Send and Follow-up",
        "Lead Monitoring",
        "5 Custom Campaigns",
      ],
      api: 'prod_QczrY0H8oQkq4A',
      btn_text:"Get Started",
      type: 'year',
    },
    {
      title: "Enterprise",
      price: null,
      description:"We can customize your package to suit your needs.",
      features: [
        "More customized campaigns?",
        "Very specific needs?",
        "B-Spoke Integrations?",
        "More Credits?",
        "Dedicated Support Agent?",
      ],
      api: null,
      btn_text:"Contact Us!",
      type: null,
    },
  ];

  // const subscriptionData = [
  //   {
  //     title: "Free",
  //     price: 0,
  //     description:"Ideal to try out SectorSift sales pack.",
  //     features: [
  //       "25 credits",
  //       "1 Search Agent",
  //       "No Automatic Sending",
  //     ],
  //     api: 'price_1Ovt47A3fbpnwooi0287bAaC',
  //     btn_text:"Join Free"
  //   },
  //   {
  //     title: "Start-up",
  //     price: 89.00,
  //     description:"All the core tools needed to set up steady sales.",
  //     features: [
  //       "250 Credits",
  //       "3 Search Agents",
  //       "Auto Send",
  //     ],
  //     api: 'price_1Ovt4rA3fbpnwooidmat2WFt',
  //     btn_text:"Get Started"
      
  //   },
  //   {
  //     title: "Business",
  //     price: 199.00,
  //     description:"Business choice for incredible sales and business opportunities.",
  //     features: [
  //       "1000 credits",
  //       "10 Search Agents",
  //       "Auto Send and Follow-up",
  //       "1 Custom Agent",
  //     ],
  //     api: 'price_1Ovt6CA3fbpnwooixppczP1e',
  //     btn_text:"Get Started"
  //   },
  //   {
  //     title: "Business Plus",
  //     price: 499.00,
  //     description:"Email & LinkedIn outreach set and managed for you.",
  //     features: [
  //       "5000 credits",
  //       "50 Agents",
  //       "Auto Send and Follow-up",
  //       "Lead Monitoring",
  //       "5 Custom Agents",
  //     ],
  //     api: 'price_1Ovt76A3fbpnwooi3iQgkCRN',
  //     btn_text:"Get Started"
  //   },
  // ];

  // const annualSubscriptionData = [
  //   {
  //     title: "Start-up Annual",
  //     price: 801.00,
  //     description:"All the core tools needed to set up steady sales.",
  //     features: [
  //       "3000 Credits",
  //       "3 Search Agents",
  //       "Auto Send",
  //     ],
  //     api: 'price_1P78meA3fbpnwooiv7Yy6Yao',
  //     btn_text:"Get Started"
      
  //   },
  //   {
  //     title: "Business Annual",
  //     price: 1791.00,
  //     description:"Business choice for incredible sales and business opportunities.",
  //     features: [
  //       "12000 credits",
  //       "10 Search Agents",
  //       "Auto Send and Follow-up",
  //       "1 Custom Agent",
  //     ],
  //     api: 'price_1P78oHA3fbpnwooiAkm8PvEm',
  //     btn_text:"Get Started"
  //   },
  //   {
  //     title: "Business Plus Annual",
  //     price: 4491.00,
  //     description:"Email & LinkedIn outreach set and managed for you.",
  //     features: [
  //       "60000 credits",
  //       "50 Agents",
  //       "Auto Send and Follow-up",
  //       "Lead Monitoring",
  //       "5 Custom Agents",
  //     ],
  //     api: 'price_1P78psA3fbpnwooiTFapFv3h',
  //     btn_text:"Get Started"
  //   },
  // ];

  const [useData, setUseData] = useState(subscriptionData);
  const [selectedPID] = useState<any>("")
  const [selectedIndex, setSelectedIndex] = useState<any>();

  const verifyPurchase = (productId:any, index:any) => {
    if(productId == null){
      window.open("https://www.sectorsift.com/contact/", "_blank");
    }else{
      // setSelectedPID(productId);
      // setVisible(true);
      setSelectedIndex(index);
      checkoutSubscription(productId, index);
    }
  };

  const checkoutSubscription = (productId:any, index:any) => {
    if(subId != undefined && subId != null && subId != ""){
      const selectedProduct = useData.find((i:any) => (i.api == productId));

      // console.log("selectedProduct::",selectedProduct);

      confirmDialog({
        message: `Are you sure you want to proceed in upgrading your current plan to ${selectedProduct?.title}?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: () => {
          setLoadingState(true);
          axiosService.api.get(`/api/stripe/upgrade-subscription/${subId}/${productId}?coupon_code=${productCodes[index]}`)
          .then((response:any) => {
            showToast({
              severity: 'success',
              summary: 'Subscription Upgraded!',
              detail: response.data.message
            });

            setTimeout(() => {
              navigate('/main');
            }, 2000);
            setLoadingState(false);
          });
        },
        reject: undefined
      });
    }else{
      const couponValue = (productCodes[index] != '') ? `?coupon=${productCodes[index]}` : '';
      setLoadingState(true);
      axiosService.api.get(`/api/subscription/subscribe/${productId + couponValue}`)
      .then((promiseData:any) => {
        // setLoadingState(false);
        window.location.href = promiseData.data.session.url;
      })
      .catch((error: any) => {
        setLoadingState(false);
        console.error("Subscription Error:", error);
        showToast({
          severity: 'error',
          summary: 'Invalid!',
          detail: error.response.data.message
        });
      });
    }
  };

  const toggleSubs = (type:any) => {
    setActiveSubs(type);
    setUseData((type == 'annual') ? annualSubscriptionData : subscriptionData);
  };

  const footerContent = (
    <div>
      <Button label={(couponCode == "") ? "Checkout without code" : "Checkout"} icon="pi pi-caret-right" iconPos="right" onClick={() => checkoutSubscription(selectedPID, selectedIndex)} autoFocus />
    </div>
  );

  // const checkSubscription = () =>{
  //   axiosService.api.get(`/api/subscription/check/subscribed`)
  //     .then((response:any) => {
  //       if(response.data === 1){
  //         navigate(`/main?page=settings/billing`);
  //       }else{
  //         setTimeout(() => {
  //           setLoadingState(false);
  //         }, 300);
  //       }
  //     })
  // }

  const getSubscriptions = () => {
    setLoadingState(false);
    if(subId != undefined && subId != null && subId != ""){
      showToast({
        severity: 'warn',
        detail: 'Please select a new plan.',
        summary: "Upgrade Plan!"
      });

      // return null;
    }

    const promises = [
      axiosService.api.get('/api/payment-method/subscriptions'),
      axiosService.api.get('/api/payment-method/billing-history')
    ];

    Promise.all(promises).then((response:any) => {
      const items = (response[0].data != null) ? response[0].data.items : [];
      const billingHistory = response[1].data || [];
      console.log("response[0]::", response[0].data.items);

      if(subId != undefined && subId != null && subId != ""){
        if (items.length != 0) {
          // showToast({
          //   severity: 'error',
          //   detail: 'User has a current active subscription!',
          //   summary: "Invalid!"
          // });
      
          // setTimeout(() => {
          //   navigate(`/main?page=settings/billing`);
          // }, 3000);
        } else {
          setLoadingState(false);
        }
      }

      // if(billingHistory != null && billingHistory.length != 0){
      //   let useDataCopy = JSON.parse(JSON.stringify(useData));
      //   useDataCopy = useDataCopy.filter((i:any) => (i.price != 0));
      //   setUseData(useDataCopy);
      //   subscriptionData.shift();
      // }
    });
    
    // axiosService.api.get('/api/payment-method/subscriptions').then((response: any) => {
    //   const items = response.data?.items || [];

    //   if (items.length !== 0) {
    //     showToast({
    //       severity: 'error',
    //       detail: 'User has a current active subscription!',
    //       summary: "Invalid!"
    //     });
    
    //     setTimeout(() => {
    //       navigate(`/main?page=settings/billing`);
    //     }, 3000);
    //   } else {
    //     setLoadingState(false);
    //   }
    // }).catch((error: any) => {
    // });
  };

  // const initData = () => {
  //   const promises = [
  //     axiosService.api.get(`/api/stripe/list-prices`),
  //     axiosService.api.get(`/api/stripe/list-products`),
  //   ];

  //   Promise.all(promises).then((response:any) => {
  //     setPageData({
  //       prices: response[0].data.prices.data,
  //       products: response[1].data.products.data,
  //     });
  //   })
  // };

  const applyCode = (index:any) => {
    // console.log("index::", index);
    // console.log("useData::", useData[index]);
    axiosService.api.get(`/api/promotion/search-code/${productCodes[index]}`)
      .then((response:any) => {
        let useDataCopy = JSON.parse(JSON.stringify(useData));
        if(response.data.type == 'credits'){
          useDataCopy[index]['additional_info'] = {
            text: ` (free ${response.data.amount} credit/s for 14 days)`,
            key: 'feature',
            index: 0
          }
        }else {
          let newPrice = (response.data.type == 'fixed') ? (useDataCopy[index].price - response.data.amount) : (useDataCopy[index].price - (useDataCopy[index].price*(response.data.amount/100)));
          useDataCopy[index]['additional_info'] = {
            text: `€${newPrice}`,
            key: 'price',
            index: 0
          }
        }

        setUseData(useDataCopy);
      }).catch((e:any) =>{
        let useDataCopy = JSON.parse(JSON.stringify(useData));
        if(useDataCopy[index].additional_info != undefined){
          delete(useDataCopy[index].additional_info);
          setUseData(useDataCopy);
        }

        showToast({
          severity: 'error',
          detail: 'No promocode exists!',
          summary: "Invalid!"
        })
      });
  };

  useEffect(() => {
    setProductCodes(Array((activeSubs == 'monthly') ? subscriptionData.length : annualSubscriptionData.length ).fill(''));
  }, [activeSubs]);

  useEffect(() => {
    // checkSubscription(); 
    // initData();
    getSubscriptions();
  }, []);

  return(
    <> 
      <Navbar axiosService={axiosService}/>
      <ConfirmDialog />

      <Dialog className="lg:w-1/4 w-[95%]" 
        header="You may enter your promo code to avail big discounts and freebies!" 
        draggable={false} resizable={false} 
        visible={visible} 
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <div>
          <Inputs value={couponCode} onChange={(e:any) => setCouponCode(e.target.value)} label={""} type={"text"} centerText={true} />
        </div>
      </Dialog>

      <div className="flex flex-col items-center justify-center min-h-screen px-10">
        {/* <div className="my-3">  <img className="object-contain" src={Logo} width={175}/></div> */}
        <div className="mt-[80px] ">
          <h2 className="text-3xl font-bold tracki text-center mt-2 text-5xl ">Our pricing plans</h2>
          <p className="max-w-3xl mx-auto mt-4 font-[200] text-xl text-center ">Find out what package works best for you</p>
        </div>
        <div className="flex justify-center items-center">
          {/* bg-white bg-[#E7E7E7] */}
          <div className="mt-[2rem] inline-flex border border-[#E7E7E7] bg-[#E7E7E7] shadow-[0px 1px 2px #E7E7E7] divide-[#E7E7E7] divide-x rounded-[8px]">
            <button
              onClick={() => toggleSubs('annual')}
              id="annual-subs"
              className={`bg-${activeSubs == 'annual' ? 'white' : '[#E7E7E7]'} hover:text-[#717F87] text-[#0E1823] leading-[16px] text-[13px] font-semibold font-bold p-[10px] border border-[3px] border-[#E7E7E7] rounded-[8px]`}
            >
              Annual <span className="inline-block px-2 py-1 text-white bg-[#FD4880] rounded-full">Less 20%</span>
            </button>
            <button
              onClick={() => toggleSubs('monthly')}
              id="monthly-subs"
              className={`bg-${activeSubs == 'monthly' ? 'white' : '[#E7E7E7]'} hover:text-[#717F87] text-[#0E1823] text-[13px] leading-[16px] font-semibold font-bold p-[10px] rounded-r-[8px]`}
            >
              Monthly
            </button>
          </div>
        </div>
        <div className={`flex justify-center mt-10 max-w-none container grid md:grid-cols-4 lg:grid-cols-4 gap-4`}>
          {useData.map((subscription:any, index:any) => (
            <div key={index} className={`w-full w-[300px] relative p-4 rounded-2xl shadow-sm flex flex-col drop-shadow-xl ${index === useData.length ? "bg-gradient-to-b from-[#E7E5FF] to-[#FFF]" : "bg-white"}`}>
              <div className="relative w-10 h-10">
                <div className="absolute inset-0 flex justify-center items-center">
                  <div className="w-10 h-10 rounded-full bg-[#E7E7E7]"></div>
                </div>
                <div className="absolute inset-0 flex justify-center items-center">
                  <div className={`doughnut-${index}`}></div>
                </div>
                {index === useData.length ? null :
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className={`w-4 h-4 rounded-full bg-white`}></div>
                  </div>
                }
              </div>
              <div className="flex-1">
                <h3 className="text-3xl sm:text-[32px] leading-auto font-bold ">{subscription.title}</h3>
                <p className="text-xl sm:text-[16px] leading-[24px] font-regular">{subscription.description}</p>
                <p className="mt-4 flex items-baseline mb-4">
                  {
                    subscription.price != null ?
                    <>
                      <span className={`text-5xl sm:text-[32px] font-extrabold tracking-tight ${(subscription.additional_info != undefined && subscription.additional_info != null && subscription.additional_info.key == "price") ? 'line-through' : ''}`}>€{subscription.price}</span>
                      <span className={`ml-1 text-3xl sm:text-[32px] font-regular text-[#999999]  ${(subscription.additional_info != undefined && subscription.additional_info != null && subscription.additional_info.key == "price") ? 'line-through' : ''}`}>/{subscription.type}</span>
                      {
                        subscription.additional_info != undefined && subscription.additional_info != null && subscription.additional_info.key == "price" ?
                          <>
                            <span className={`text-5xl sm:text-[32px] font-extrabold tracking-tight ml-2`}>{subscription.additional_info.text}</span>
                            <span className={`ml-1 text-3xl sm:text-[32px] font-regular text-[#999999]`}>/{subscription.type}</span>
                          </>
                        : 
                        ""
                      }
                    </>
                    :
                    <><h3 className="text-3xl sm:text-[32px] leading-auto">Talk to us!</h3></>
                  }
                </p>
                <ul className="pt-6 space-y-2 border-t border-[#E7E7E7]">
                  {subscription.features.map((feature:any, featureIndex:any) => (
                    <li key={featureIndex} className="flex mt-0">
                      {IconMap("CiCircleCheck")}
                      <span className="ml-3 ">
                        {feature} {(subscription.additional_info != undefined && subscription.additional_info != null && subscription.additional_info.index == featureIndex && subscription.additional_info.key == "feature") ? subscription.additional_info.text : ''}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3">
                    <Inputs 
                      value={productCodes[index]} 
                      onChange={(e: any) => {
                        const newProductCodes = [...productCodes];  // Create a copy of the current array
                        newProductCodes[index] = e.target.value;    // Update the specific index
                        setProductCodes(newProductCodes);           // Set the new array
                      }} 
                      label={"Promo Code"} 
                      type={"text"} 
                      centerText={true} 
                    />
                  </div>
                  <div className="col-span-1 pt-7">
                    <Button label="Apply" onClick={() => applyCode(index)} className="bg-[#2A2298] text-white border border-[2px] border-[#2A2298]" />
                  </div>
              </div>
              {/* {
                subscription.api != null ?
                  <div className="grid grid-cols-4 gap-4">
                    {
                      (subId != undefined && subId != null && subId != "") ?
                        <></>
                      : 
                      <>
                        <div className="col-span-3">
                          <Inputs 
                            value={productCodes[index]} 
                            onChange={(e: any) => {
                              const newProductCodes = [...productCodes];  // Create a copy of the current array
                              newProductCodes[index] = e.target.value;    // Update the specific index
                              setProductCodes(newProductCodes);           // Set the new array
                            }} 
                            label={"Promo Code"} 
                            type={"text"} 
                            centerText={true} 
                          />
                        </div>
                        <div className="col-span-1 pt-7">
                          <Button label="Apply" onClick={() => applyCode(index)} className="bg-[#2A2298] text-white border border-[2px] border-[#2A2298]" />
                        </div>
                      </>
                    }
                    
                  </div>
                : <></>
              } */}
              <button onClick={() => (verifyPurchase(subscription.api, index))} className={`mt-4 text-emerald-700 w-full py-3 px-6 border border-[2px] border-[#2A2298] rounded-[8px] text-center font-medium ${index === (useData.length -1) ? "bg-[#2A2298] text-white" : "bg-white text-[#2A2298]"}`}>
                {subscription.btn_text}
              </button>
            </div>
          ))}
        </div>
        <div className="my-[2rem]">
          <p className="text-[13px]">Make a suggestion or contact us at <a className="text-[#8D2CFE] hover:underline" href="mailto:support@sectorsift.com"> support@sectorsift.com</a></p>
        </div>
      </div>
    </>
  )
};

export default Subscription;