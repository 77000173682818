import { useEffect, useRef, useState } from "react";
import { Logo } from "../Icons/ImgPath";
import { TieredMenu } from 'primereact/tieredmenu';
import { Button } from "primereact/button";
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Skeleton } from 'primereact/skeleton';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from "../../contexts/ToastContext";
import Cookies from "js-cookie";
import IconMap from "../Icons/IconMaps";
import { classNames } from "primereact/utils";

const Navbar: React.FC<any> = ({ 
  axiosService, setLoadingState = null, showCredits=true, sidebarShow, latestNotifications, loadedOlderNotifs = false, 
  handleShowOlderNotifs, loadingOlderNotifs, handleNotifScroll, notificationDropdownRef, setRefreshAccount, refreshAccount 
}) => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const menu:any = useRef(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [show, setShow] = useState(true);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [notifCount, setNotifCount] = useState(0);
  const [loadingNotifCount] = useState(3);
  const [items, setItems] = useState<any>([]);
  
  const [greeting2, setGreeting2] = useState<any>({ complete_greeting:'', name:'', logged_in_user_role_id:'', user_role_id:'' });

  const handleLogout = () => {

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Logging out user...'
    });
    if(setLoadingState){
      setLoadingState(true);
    }
    axiosService.api.get('/api/user/logout')
      .then((userInfo:any) => {
        if(setLoadingState){
          setLoadingState(false);
        }
        console.log("userInfo::", userInfo);
        sessionStorage.clear();
        Cookies.remove('bearer_token');
        sessionStorage.clear();
        navigate('/');
        
        showToast({
          severity: 'success',
          summary: 'Success!',
          detail: 'Successfully logged out!'
        });
      });
  };

  const initUserInformation = () => {
    
    Promise.all([
      axiosService.api.get('/api/user/current'),
      axiosService.api.get('/api/greeting')
    ]).then( (v: any)=>{
      console.log("first_login::", v[0].data.first_login);

      if(v[0].data.first_login == 1){
        setItems([
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: handleLogout,
            key: 'logout'
          },
        ]);
      }

      const currentUserData: any = v[0];
      const res: any = v[1];      
      setUserInfo(currentUserData.data);
      setGreeting2( res.data );
    }).catch( (e: any)=>{
      sessionStorage.clear();
      Cookies.remove('bearer_token');
      sessionStorage.clear();
      navigate('/');
    });
    
  }

  const generateNotificationsFromData = (data:any) => {
    setNotifications([]);

    if (!data || !Array.isArray(data.list)) {
      // console.error('Invalid data format:', data);
      setNotifications([]);
      return;
    }

    const fromOptions:any = {
      mail: "email",
      agent: "agent",
      leads: "leads",
      etc: "etc"
    };

    const badgeColors:any = {
      Mail: '#9c27b0',
      Agents: '#4BADE8',
      Leads: '#F87315',
      Etc: '#1BA750'
    };

    const notifications = data.list?.map((item:any, index:any) => {
      // console.log('notifications ::: ', item);
      
      const notificationDate = new Date(item.created_at);
      const timeDifference = Math.floor((new Date().getTime() - notificationDate.getTime()) / 1000); 
      const category = timeDifference < 86400 ? "New" : "Old"; 
      const from = fromOptions[item.type] || "Etc";
      const fromLabel = from.charAt(0).toUpperCase();
      const badgeColor = badgeColors[from] || '#1BA750';
      const isRead = item.is_read === 1;
    
      let timeAgo = '';
      if (timeDifference < 60) {
        timeAgo = `${timeDifference} second${timeDifference !== 1 ? 's' : ''} ago`;
      } else if (timeDifference < 3600) {
        const minutes = Math.floor(timeDifference / 60);
        timeAgo = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      } else if (timeDifference < 86400) {
        const hours = Math.floor(timeDifference / 3600);
        timeAgo = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      } else {
        const days = Math.floor(timeDifference / 86400);
        timeAgo = `${days} day${days !== 1 ? 's' : ''} ago`;
      }
    
      const hasName = item.name && item.name.trim();
      const title = hasName 
        ? item.name.charAt(0).toUpperCase() + item.name.slice(1)
        : item.type.charAt(0).toUpperCase() + item.type.slice(1);
    
      return {
        id: item.id,
        title: title,
        message: item.message,
        time: timeAgo,
        from: `From ${from}`,
        fromLabel: fromLabel,
        avatarColor: '#CDCECF',
        badgeColor: badgeColor,
        category: category,
        isRead: isRead,
        ...(hasName && { type: item.type.charAt(0).toUpperCase() + item.type.slice(1) }) 
      };
      
    });

    // console.log('notifications ::: ', notifications);
    setNotifications(notifications);
  }

  const printUnreadCount = (data: any) => {
    // console.log('data ::: ', data)
    if (!data || data.unread_count === 'undefined' || data.unread_count === 0) {
      // console.error('Invalid data format:', data);
      setNotifCount(0);
      return;
    }
    const unreadCount = data.unread_count;
    const displayCount = unreadCount > 99 ? '99+' : unreadCount;
    setNotifCount(displayCount);
    // console.log('Unread Count:', displayCount);
  };
  
  const navigateToPage = () => {
    navigate(`/main?page=view-profile`);
  };

  const toggleSidebar = () => {
    const newCollapsedState = !show;
    setShow(newCollapsedState);
    sidebarShow(newCollapsedState); 
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
      setShowNotifications(false);
    }
  };

  const redirectToBilling = () => {
    if(userInfo != null && userInfo != undefined){
      navigate('/main?page=settings/billing');
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    initUserInformation();
    setItems([
      {
        template: () => (
          showCredits ? (
            <div className="flex m-2 ">
              <Badge
                className="bg-purple flex sm:hidden"
                value={"Credits: " + ((userInfo != null) ? userInfo.available_credit : 0)}
              />
            </div>
          ) : null
        ),
        classNames:"flex sm:hidden",
        key: 'credits'
      },
      {
        label: 'Profile',
        icon: 'pi pi-user',
        command: navigateToPage,
        key: 'profile'
      },
      { separator: true },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: handleLogout,
        key: 'logout'
      },
    ]);
  }, []);
  
  useEffect(() => {
    if(refreshAccount){
      setRefreshAccount(false);
      initUserInformation();
    }
  }, [refreshAccount]);

  useEffect(() => {
    // console.log("navbar::notificaitons::", latestNotifications);
    // generateNotificationsFromData(latestNotifications);
    // printUnreadCount(latestNotifications)
    if (!location.pathname.includes('admin-KNdApfxpXK')) {
      // console.log("navbar::notificaitons::", latestNotifications);
      generateNotificationsFromData(latestNotifications);
      printUnreadCount(latestNotifications);
    }
  }, [latestNotifications,location.pathname]);
  

  return (
    <div id="Navbar" className="fixed p-4 border-b-[1px] border-gray shadow-sm h-[80px] w-full bg-white z-[400]">
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="flex items-center" onClick={toggleSidebar}>{
            show?
            IconMap('IoCloseSharp','mr-2 flex sm:hidden',undefined,30)
            :
            IconMap('GiHamburgerMenu','mr-2 flex sm:hidden',undefined,30)
          }</div>
          <img onClick={redirectToBilling} className="object-contain w-[150px] lg:w-[175px] cursor-pointer" src={Logo} alt="Billing Logo"/>
        </div>
        <div className="flex gap-4 items-center">
          {showCredits ? <Badge className="bg-purple hidden sm:flex" value={"Credits: " + ((userInfo != null) ? userInfo.available_credit : 0) }></Badge> : null}
          {!location.pathname.includes('admin-KNdApfxpXK') &&
            <div className="relative" ref={notificationRef}>
              <i className="pi pi-bell cursor-pointer p-overlay-badge mr-2" style={{ fontSize: '1.75rem' }}  onClick={() => setShowNotifications(prevState => !prevState)} >
                { notifCount !== 0 &&
                  <Badge value={notifCount} severity="danger" 
                    pt={{root:{className:'flex items-center justify-center text-[.55rem] p-[5px]'}}}
                  ></Badge>
                }
              </i>
              {showNotifications? 
                <div className="absolute top-[3.5rem] left-[-15.3rem] sm:left-[-23rem] block w-[22rem] sm:w-[25rem] h-[20rem] max-h-[30rem] inset-0  box-border z-[400] flex-1 bg-white rounded-[3px] shadow-[0_4px_8px_-2px_rgba(9,30,66,0.25),_0_0_1px_rgba(9,30,66,0.31)] overflow-auto"
                onScroll={handleNotifScroll}
                ref={notificationDropdownRef}
                >
                  <div className="h-full flex flex-col m-[24px_18px_18px_24px]">
                    <header className="w-full text-[14px] flex items-center ">
                      <h2 className="text-[1.71429em] leading-[1.16667] text-[#172B4D] font-medium tracking-[-0.01em] flex-grow"> Notifications</h2>
                      {/* {IconMap('FaArrowUpRightFromSquare','hover:cursor-pointer')} */}
                    </header>
                    <div className="my-2">
                      <div className="divider w-full h-[2px] bg-[#EBECF0]"></div>
                    </div>
                    <div className="my-2">
                      {notifications.some(notification => notification.category === 'New') && (
                        <section className="mb-2">
                          <div className="flex justify-between items-center mb-[6px] min-h-[17px]">
                            <h3 className="mt-0 uppercase text-[12px] text-[#6B778C] font-[600] tracking-tighter">New</h3>
                          </div>
                          <div className="bg-transparent transition duration-2000 ease-in-out ml-[calc(-8px)] mr-[calc(-8px)] mb-[4px] rounded-[3px]">
                            {notifications
                            .filter(notification => notification.category === 'New')
                            .map(notification => (
                              <article className="p-3 hover:bg-[#091E420F]">
                                <div className="flex w-full">
                                  <Avatar key={notification.id} className="mr-2" color="white" icon="pi pi-user" shape="circle" style={{ backgroundColor: notification.avatarColor, width: '56px', height: '56px' }} />
                                  <div className="w-full pointer-events-none">
                                    <div className="flex pointer-events-none">
                                      <div className="flex pointer-events-none flex-col flex-grow">
                                        <h3 className="flex flex-row inline text-[#172B4D] text-[14px] font-[600] leading-[20px]">
                                          {notification.title}
                                          {notification.type &&(<Badge className="flex items-center text-[10px] font-[300] ml-1" style={{ backgroundColor: notification.badgeColor}} value={notification.type}></Badge>)}
                                          <span className="text-[14px] text-[#6B778C] font-[400] ml-2 whitespace-no-wrap">{notification.time}</span>
                                        </h3>
                                        <div className="flex items-center">
                                          {/* <Avatar className="text-[8px] mr-1 " label={notification.fromLabel} style={{ backgroundColor: notification.badgeColor, color: '#ffffff', width: '12px', height: '12px' }} /> */}
                                          {/* <a className="m-0 leading-inherit tracking-[0.005em] text-[#172B4D] text-[13px] break-words">{notification.message}</a> */}
                                          <button  type="button" className="m-0 leading-inherit tracking-[0.005em] text-[#172B4D] text-[13px] break-words bg-transparent border-none cursor-pointer">
                                            {notification.message}
                                          </button>
                                        </div>
                                      </div>
                                      {notification.isRead? null :
                                      <div className="pointer-events-none">
                                        <button className="border-transparent border-1 p-0 bg-transparent pointer-events-auto z-10 rounded-full relative flex justify-center items-center outline-none w-[24px] h-[24px]">
                                          {IconMap('FaCircle', undefined, { color: 'rgb(60 53 167)' }, 12)}
                                        </button>
                                      </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </article>
                            ))}
                          </div>
                        </section>
                      )}
                      {notifications.some(notification => notification.category === 'Old') && (
                        <section>
                          <div className="flex justify-between items-center mb-[6px] min-h-[17px]">
                            <h3 className="mt-0 uppercase text-[12px] text-[#6B778C] font-[600] tracking-tighter">Old</h3>
                          </div>
                          <div className="bg-transparent transition duration-2000 ease-in-out ml-[calc(-8px)] mr-[calc(-8px)] mb-[4px] rounded-[3px]">
                            {notifications
                            .filter(notification => notification.category === 'Old')
                            .map(notification => (
                              <article key={notification.id} className="p-3 hover:bg-[#091E420F]">
                                <div className="flex">
                                  <Avatar className="mr-2" color="white" icon="pi pi-user" shape="circle" style={{ backgroundColor: notification.avatarColor, width:'56px', height:'56px' }} />
                                  <div className="w-full pointer-events-none">
                                    <div className="flex pointer-events-none">
                                      <div className="flex pointer-events-none flex-col flex-grow">
                                        <h3 className="flex flex-row inline text-[#172B4D] text-[14px] font-[600] leading-[20px]">
                                          {notification.title}
                                          {notification.type &&(<Badge className="flex items-center text-[10px] font-[300] ml-1" style={{ backgroundColor: notification.badgeColor}} value={notification.type}></Badge>)}
                                          <span className="text-[14px] text-[#6B778C] font-[400] ml-2 whitespace-no-wrap">{notification.time}</span>
                                        </h3>
                                        <div className="flex items-center">
                                          {/* <Avatar className="text-[8px] mr-1" label={notification.fromLabel} style={{ backgroundColor: notification.badgeColor, color: '#ffffff', width: '12px', height: '12px' }} /> */}
                                          {/* <a className="m-0 leading-inherit tracking-[0.005em] text-[#172B4D] text-[13px] break-words truncate-message">{notification.message}</a> */}
                                          <button  type="button" className="m-0 leading-inherit tracking-[0.005em] text-[#172B4D] text-[13px] break-words bg-transparent border-none cursor-pointer">
                                            {notification.message}
                                          </button>
                                        </div>
                                      </div>
                                      {notification.isRead ? null : (
                                        <div className="pointer-events-none">
                                          <button className="border-transparent border-1 p-0 bg-transparent pointer-events-auto z-10 rounded-full relative flex justify-center items-center outline-none w-[24px] h-[24px]">
                                            {IconMap('FaCircle', undefined, { color: 'rgb(60 53 167)' }, 12)}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </article>
                            ))}
                          </div>
                        </section>
                      )}
                      {!notifications.some(notification => notification.category === 'New') && !notifications.some(notification => notification.category === 'Old') && (
                        <section className="mb-2">
                          <div className="bg-transparent transition duration-2000 ease-in-out ml-[calc(-8px)] mr-[calc(-8px)] mb-[4px] rounded-[3px]">
                            <article className="p-3">
                              <div className="flex w-full justify-center">
                                <span className="text-[#6B778C] text-[14px] font-[600]">Notification is empty</span>
                              </div>
                            </article>
                          </div>
                        </section>
                      )}

                      {notifCount > 10 && !loadedOlderNotifs?
                        !loadingOlderNotifs? 
                        (
                          <section className="">
                            <div className="w-full mt-1 mb-3 border-b-0 border-l-0 justify-center pl-0 bg-transparent flex-col border-r-0 box-border user-select-none pr-0 inline-flex cursor-pointer relative ml-0 no-underline outline-none">
                              <Button className="relative z-0 flex flex-nowrap pr-3 pl-3 border-b-0 border-l-0 border-r-0 border-t-0 box-border items-center justify-center flex-row shrink-0 h-[36px] bg-[#E4E6EB] rounded-[6px] select-none cursor-pointer focus:outline-none text-inherit list-none hover:bg-[rgba(0,0,0,0.05)]"
                                 onClick={(e) => {
                                  e.stopPropagation();
                                  handleShowOlderNotifs();
                                }}>
                                <span className="block text-[.9375rem] font-[600] leading-[1.3333] overflow-x-hidden overflow-y-hidden whitespace-nowrap overflow-ellipsis overflow-wrap-break-word relative">Load more</span>
                              </Button>
                            </div>
                          </section>
                          
                        ):(
                          <section className="mb-2">
                            <div className="bg-transparent transition duration-2000 ease-in-out ml-[calc(-8px)] mr-[calc(-8px)] mb-[4px] rounded-[3px]">
                              {Array.from({ length: loadingNotifCount }).map((_, index) => (
                                <article key={index} className="p-3 hover:bg-[#091E420F]">
                                  <div className="flex">
                                    <Skeleton shape="circle" size="56px" className="mr-2"></Skeleton>
                                    <div className="w-full pointer-events-none">
                                      <div className="flex pointer-events-none">
                                        <div className="flex pointer-events-none flex-col flex-grow">
                                          <Skeleton width="100%" className="mb-2"></Skeleton>
                                          <Skeleton width="50%" className="mb-2"></Skeleton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              ))}
                            </div>
                          </section>
                        ) 
                        : (
                          loadingOlderNotifs? 
                           (<section className="mb-2">
                            <div className="bg-transparent transition duration-2000 ease-in-out ml-[calc(-8px)] mr-[calc(-8px)] mb-[4px] rounded-[3px]">
                              {Array.from({ length: loadingNotifCount }).map((_, index) => (
                                <article key={index} className="p-3 hover:bg-[#091E420F]">
                                  <div className="flex">
                                    <Skeleton shape="circle" size="56px" className="mr-2"></Skeleton>
                                    <div className="w-full pointer-events-none">
                                      <div className="flex pointer-events-none">
                                        <div className="flex pointer-events-none flex-col flex-grow">
                                          <Skeleton width="100%" className="mb-2"></Skeleton>
                                          <Skeleton width="50%" className="mb-2"></Skeleton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              ))}
                            </div>
                          </section>) : null
                        )
                      }

                    </div>
                  </div>
                </div>
              : null}
            </div>
          }
          <div className="hover:cursor-pointer gap-2 flex flex-column items-center" onClick={(e) => menu.current.toggle(e)}>
            <Avatar color="white" image={userInfo?.accessing_user?.profile_picture || userInfo?.profile_picture || ''} icon="pi pi-user" size="large" shape="circle" style={{ backgroundColor: '#CDCECF' }} />
            <TieredMenu model={items} popup ref={menu}/>
            <span className="hidden lg:block leading-[1.5rem]">
              <span className="text-[#999999]">Howdy!</span><br/>
              {
                greeting2.logged_in_user_role_id !== 1 ?
                (
                  userInfo && userInfo.accessing_user ?
                    `${userInfo.accessing_user.firstname} (${userInfo.company ? userInfo.company.company_name : ''})`
                    : (userInfo ? userInfo.firstname : '')
                ) : (
                  greeting2.name
                )
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
