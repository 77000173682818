import { useEffect, useState } from "react";
import Inputs from "../../components/forms/Inputs";
import { Button } from "primereact/button";
import { useAxios } from "../../contexts/AxiosContext";
import { useParams } from "react-router-dom";
import { Logo } from "../../components/Icons/ImgPath";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _ from 'lodash';

const InvitationAcceptance:React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();

  const { serial } = useParams();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  
  const [loadingState2, setLoadingState2] = useState(true);
  const [isValid, setIsValid] = useState(true);
  
  const [isFinished, setIsFinished] = useState(false);

  const [invited_user_name, set_invited_user_name] = useState('');
  const [team_name, set_team_name] = useState('');
  
  const [errors, setErrors] = useState({ password: '', passwordConfirmation: '' });
  
  useEffect(() => {    
    
    setPassword('');
    setPasswordConfirmation('');
    
    axiosService.api.post('/api/colleagues/check_invite_serial', { invitation_serial: serial } )    
    .then((res: any) => {
    
      setLoadingState(false);
      setLoadingState2(false);
      if( !res.data.is_valid ){
        setIsValid( false );
        return;
      }
      
      set_invited_user_name( res.data.invited_user_name );
      set_team_name( res.data.team_name );
      
    });    
    
  }, []);
  

  const acceptInvitation = () =>{
    let valid = true;
    
    const newErrors = { password: '', passwordConfirmation: '' };
  
    if( _.isEmpty( password ) ){
      newErrors.password = 'New Password is required.';
      valid = false;
    }
  
    if( _.isEmpty( passwordConfirmation ) ){
      newErrors.passwordConfirmation = 'New Confirmation Password is required.';
      valid = false;  
    }
    
    if( password != passwordConfirmation ){
      newErrors.passwordConfirmation = 'Confirm new password does not match.';
      valid = false;
    }
    setErrors(newErrors);
    if( !valid ){
      return;
    }
      
    setLoadingState(true);
    setLoadingState2(true);
    
    axiosService.api.post('/api/colleagues/accept_invitaion', { invitation_serial: serial, new_password: password } )
    .then((res: any) => {
      setLoadingState(false);
      setLoadingState2(false);
      
      if( !res.data.is_valid ){
        setLoadingState(false);
        setLoadingState2(false);        
        withReactContent(Swal).fire({
          title: "Unable to Submit",
          icon: 'error'
        });      
  
        return;
      }
      
      setLoadingState(false);
      setLoadingState2(false);
      setIsFinished( true );          
      
    });
  
  };
  
  const goLogin = () =>{
    window.location.href='/';
  };
  
  return (
    <>
      <div className="flex flex-col gap-5 justify-center items-center min-h-screen w-screen text-sm p-4 bg-[#F0EFFF]">
        <img alt="Logo" src={Logo} className="mb-5" width={300}/>
        <div className="w-full xl:w-1/3 lg:w-1/2 rounded-[56px] min-h-[400px] p-1" style={{background: 'linear-gradient(180deg, rgb(42, 34, 152) 10%, rgba(33, 150, 243, 0) 30%)'}}>
          <div className="rounded-[53px] bg-white border-5 border-transparent w-full h-full lg:p-[5rem] py-[5rem] px-[2rem]">
            <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
              <p className="text-3xl text-center font-bold">Invitation to Join</p>
            </div>
            
            {
              loadingState2 && <>
              
              <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
                <p className="text-1xl text-center font-bold">Please Wait ...</p>
              </div>          
              
              </>
            }   
            
            {
              !isValid && <>
              
              <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
                <p className="text-1xl text-center font-bold">Sorry the link is invalid.</p>
              </div>          
              
              </>
            }    
            
            {
              !loadingState2 && isValid && !isFinished && <>
              
              <div className="flex flex-col text-left gap-1 mb-[2rem]">
                <p className="text-left">Hi {invited_user_name} you are invited to join {team_name} Team.</p>
                <p>&nbsp;</p>
                <p className="text-left">Please provide a new password for this account.</p>
              </div>            
              <div className="grid lg:grid-cols-1 gap-4">
                <div className="flex flex-col gap-1">
                  <Inputs label={'New Password'} type={'password'} value={password} onChange={(event: any) => setPassword(event.target.value)} />
                  {errors.password && <small className="p-error">{errors.password}</small>}
                </div>
                <div className="flex flex-col gap-1">
                  <Inputs label={'Confirm New Password'} type={'password'} value={passwordConfirmation} onChange={(event: any) => setPasswordConfirmation(event.target.value)} />
                  {errors.passwordConfirmation && <small className="p-error">{errors.passwordConfirmation}</small>}
                </div>
                <div>
                  <Button label="Accept Invitation" onClick={acceptInvitation} className="w-full bg-purple rounded-lg border-purple"/>
                </div>
              </div>
              
              </>
            }
            
            {
              !loadingState2 && isValid && isFinished && <>
              
              <div className="flex flex-col text-left gap-1 mb-[2rem]">
                <p className="text-left">Successfully accepted the invitation of {team_name} Team.</p>
              </div>            
              <div className="grid lg:grid-cols-1 gap-4">
                <div>
                  <Button label="Go to Login Page" onClick={goLogin} className="w-full bg-purple rounded-lg border-purple"/>
                </div>
              </div>
              
              </>
            }
            
          </div>
        </div>
      </div>
    </>
  );
}

export default InvitationAcceptance;
