import { Button } from "primereact/button";
import Inputs from "../../components/forms/Inputs";
import { useAxios } from "../../contexts/AxiosContext";
import { useEffect, useState } from "react";
import { InputHandler } from "../../services/EventHandlers";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Icons/ImgPath";
import { useToast } from "../../contexts/ToastContext";

const ForgotPassword: React.FC<any> = ({setLoadingState}) => {
  const { showToast } = useToast();
  const axiosService = useAxios();
  const navigate = useNavigate();
  const [resetDetails, setResetDetails] = useState<any>({
    email: ''
  });
  const [disable, setDisable] = useState<any>(false);

  const sendLink = () => {

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });
    
    setDisable(true);
    axiosService.api.post('/api/password/email', resetDetails).then((resetData:any) => {
      // navigate('/reset-password');
      navigate('/');
      showToast({
        severity: 'success',
        summary: 'Password Reset Successful',
        detail: 'Please check your inbox for your password reset link.'
      });
      setDisable(false);
    }).catch((e:any) => {
      setDisable(false);
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: e.response?.data?.error || e.response?.data?.message
      });
      // console.log(e);
    });
  };

  useEffect(() => {
    setLoadingState(false);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-5 justify-center items-center min-h-screen w-screen text-sm p-4 bg-[#F0EFFF]">
        <img alt="Logo" src={Logo} className="mb-5" width={300}/>
        <div className="w-full xl:w-1/3 lg:w-1/2 rounded-[56px] min-h-[400px] p-1" style={{background: 'linear-gradient(180deg, rgb(42, 34, 152) 10%, rgba(33, 150, 243, 0) 30%)'}}>
          <div className="rounded-[53px] bg-white border-5 border-transparent w-full h-full lg:p-[5rem] py-[5rem] px-[2rem]">
            <div className="flex flex-col justify-center items-center gap-1 mb-[2rem]">
              <p className="text-3xl text-center font-bold">Forgot Password</p>
              <p className="text-center">Enter your email address.</p>
            </div>
            <div className="grid lg:grid-cols-1 gap-4">
              <div className="flex flex-col gap-1">
                <Inputs disabled={disable} label={'Email'} keyfilter={'email'} type={'text'} value={resetDetails.email} onChange={InputHandler(setResetDetails, 'email')} />
              </div>
              <div>
                <Button loading={disable} label="Send Link" onClick={sendLink} className="w-full bg-purple rounded-lg border-purple"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;