import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _ from 'lodash';


const AgentDeleteModal = async( axiosService: any, setRefreshTable: any, agent_id: any ) => {
  const v = await withReactContent(Swal).fire({
    title: "Delete this campaign?",
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel',
    showCancelButton: true
  });
  if(v.isConfirmed){
    const res_r5tng3r3tf: any = await axiosService.api.delete(`/api/agents/delete2/${ agent_id }`);
    if( res_r5tng3r3tf.data.status ){
      setRefreshTable( true ); 
      return true;   
    }else{
      withReactContent(Swal).fire({
        icon: "error",
        text: `Unable to delete.`,
      });
      return false;   
    }
    setRefreshTable( true );
    return true;      
  }  
  return false;
}

export default AgentDeleteModal;
