import React, { useState,useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import IconMap from "../../../components/Icons/IconMaps";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { useAxios } from "../../../contexts/AxiosContext";
import { useToast } from "../../../contexts/ToastContext";

const NotificationsSettings: React.FC<any> = ({currentPage, setLoadingState, userDetails}) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [activeView, setActiveView] = useState<string | null>(null);
  // const [checked, setChecked] = useState<boolean>(true);

  const frequencies:any = [
    { name: 'Instant', key: '0' },
    { name: 'Daily Summary', key: '1' },
    { name: 'Monthly Summary', key: '2' },
  ];
  // const [selectedFrequency, setSelectedFrequency]  = useState<any>(frequencies[1]);
  const [notificationDetails, setNoticationDetails] = useState<any>({
    email: {
      email_notif: true,
      lead_updates: true,
      team_activity: true,
      monthly_reports: true
    },
    in_app: {
      inapp_notif: true,
      lead_updates: true,
      team_activity: true,
      system_updates: true
    },
    frequency: {
      frequency: frequencies[0].name
    }
  });

  const initialNotificationSettings:any = [
    {
      title: "Email Notifications",
      description: "Stay informed with timely updates sent directly to your inbox.",
      setting: 'email_notif',
    },
    {
      title: "In-App Notifications",
      description: "Stay informed with timely updates sent directly to your inbox.",
      setting: 'inapp_notif',
    },
    {
      title: "Notification Frequency",
      description: "Customize how often you receive updates.",
      setting: 'notif_freq',
    },
  ];

  const emailNotifSettings:any = [
    {
      title: "Email Notifications",
      description: "Stay informed with timely updates sent directly to your inbox.",
      handleId:'email_notif'
    },
    {
      title: "Lead Updates",
      description: "Instant alerts for changes in your leads' status.",
      handleId:'lead_updates'
    },
    {
      title: "Team Activity",
      description: "Stay in the loop with real-time updates on team actions and progress.",
      handleId:'team_activity'
    },
    {
      title: "Monthly Reports",
      description: "Get your monthly summary with key insights and metrics delivered to your inbox.",
      handleId:'monthly_reports'
    },
  ];

  const inAppSettings:any = [
    {
      title: "In-App Notifications",
      description: "Stay informed with timely updates sent directly to your inbox.",
      handleId:'inapp_notif'
    },
    {
      title: "Lead Updates",
      description: "Instant alerts for changes in your leads' status.",
      handleId:'lead_updates'
    },
    {
      title: "Team Activity",
      description: "Stay in the loop with real-time updates on team actions and progress.",
      handleId:'team_activity'
    },
    {
      title: "System Updates",
      description: "Alert users to new improvements and security fixes available.",
      handleId:'system_updates'
    },
  ];

  const notifFrequencySettings:any = [
    {
      title: "Frequency",
      description: "How often notifications are sent to users.",
      handleId:'frequency'
    },
  ];

  const notifDefaultSettingsTemplate = (data: any, index: number) => {
    return (
      <div
        key={index}
        className="w-full flex flex-row justify-between p-6 border border-1 border-[#CDCECF] rounded-lg mb-5 cursor-pointer"
        onClick={() => handleClick(data.setting)}
      >
        <div className="flex flex-col">
          <span className="text-[18px] font-[300]">{data.title}</span>
          <span className="text-[12px] font-[300]">{data.description}</span>
        </div>
        <div className="flex justify-center items-center ml-[3rem] px-2">
          {IconMap('FaChevronRight','text-[#2A2298]',undefined,24)}
        </div>
      </div>
    );
  };
  
  const notifSettingsTemplate = (data: any, index: number, key:string) => {
    return (
      <div
        key={index}
        className="w-full flex flex-row justify-between p-6 border border-1 border-[#CDCECF] rounded-lg mb-5 cursor-pointer"
      >
        <div className="flex flex-col">
          <span className="text-[18px] font-[300]">{data.title}</span>
          <span className="text-[12px] font-[300]">{data.description}</span>
        </div>
        <div className="flex justify-center items-center ml-[3rem] px-2">
          <InputSwitch 
            checked={notificationDetails[key][data.handleId]} 
            onChange={(e: InputSwitchChangeEvent) => {
              // setChecked(e.value)
              setNoticationDetails((prevValues:any) => (
                {
                  ...prevValues,
                  [key]: {
                    ...prevValues[key],
                    [data.handleId]: e.value
                  }
                }
              ));
            }} />
        </div>
      </div>
    );
  };
  
  const notifFrequencyTemplate = (data: any, index: number) => {
    return (
      <div
        key={index}
        className="w-full flex flex-col justify-between p-6 border border-1 border-[#CDCECF] rounded-lg mb-5 cursor-pointer"
      >
        <div className="flex flex-col mb-3">
          <span className="text-[18px] font-[300]">{data.title}</span>
          <span className="text-[12px] font-[300]">{data.description}</span>
        </div>
        <div className="flex items-center px-2">
          {frequencies.map((frequency: any) => {
            return (
              <div key={frequency.key} className="flex align-items-center mr-3">
                <RadioButton
                  inputId={frequency.key}
                  name="frequency"
                  value={frequency.name}
                  onChange={(e) => {
                    setNoticationDetails((prevValues:any) => (
                      {
                        ...prevValues,
                        frequency: {
                          frequency: e.value
                        }
                      }
                    ));
                  }}
                  checked={notificationDetails.frequency.frequency === frequency.name}
                />
                <label htmlFor={frequency.key} className="ml-2 text-[16px] font-[300] hover:cursor-pointer">{frequency.name}</label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };  

  const handleClick = (setting: string) => {
    setActiveView( setting);
  };

  const saveSettings = () => {
    const dataToSend = {
      notification_settings: notificationDetails
    };

    setLoadingState(true);

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });

    axiosService.api.patch('/api/user/notifications/update', dataToSend)
      .then((response:any) => {
        setLoadingState(false);
        setActiveView(null);
        
        if(response.data.status){
          showToast({
            severity: 'success',
            summary: 'Update Success!',
            detail: response.data.message
          });
        }
      });
  };

  useEffect(() => {
    if(userDetails.notification_settings != null){
      setNoticationDetails(userDetails.notification_settings);
    }
  }, [userDetails]);

  return (
    <>
      <div id="profile-settings" className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Notifications</h1>
        </div>
      </div>

      <div className="panel relative bg-white rounded-lg m-5 p-5 min-h-[50rem]">
        <div className="flex flex-col items-center mt-[3rem] py-[3rem] min-h-[40rem] z-0 w-full relative">
          <div className="absolute top-0 left-0 sm:left-[17rem] mb-6 hover:cursor-pointer">
            {activeView !== null ? (
              <div className="flex items-center text-[#2A2298] hover:cursor-pointer" onClick={() => setActiveView(null)}>
                {IconMap('FaChevronLeft', 'mr-2', undefined, 24)}
                <span className="font-[700]">GO BACK</span>
              </div>
            ) : null}
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:w-[65%] xl:w-[50%] ">
            {activeView == null && initialNotificationSettings.map((setting:any, index:any) => (
              notifDefaultSettingsTemplate(setting, index)
            ))}
            {activeView == 'email_notif' && emailNotifSettings.map((setting:any, index:any) => (
              notifSettingsTemplate(setting, index, 'email')
            ))}
            {activeView == 'inapp_notif' && inAppSettings.map((setting:any, index:any) => (
              notifSettingsTemplate(setting, index, 'in_app')
            ))}
            {activeView == 'notif_freq' && notifFrequencySettings.map((setting:any, index:any) => (
              notifFrequencyTemplate(setting, index)
            ))}
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:w-[65%] xl:w-[50%] ">
            <Button className="bg-purple rounded-lg border-purple ring-0" label="Save" onClick={saveSettings} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsSettings;
